import { DEFAULT_ACTIVE_CHAIN_ID } from "config/constants";
import { getAtlanticTokenABI, getPresaleABI, getPresaleInfo, getSmartPresaleABI } from "utils/presale";
import multicall from 'utils/multicall';
import { getBalanceInEther } from "utils";
import { PresaleInfo } from "types/presale";
import { ethers } from "ethers";
import { getRouters } from "utils/deployTokens";
import { getDeployedTokensInfo } from "config/constants/backend";

export const fetchPresaleInfo = async (chainId: string): Promise<any> => {
    const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
    const presaleInfo = getPresaleInfo(selectedChainid);

    try {
        if( presaleInfo.length > 0 ){
            const info = presaleInfo[0]
            const calls1 = [
                {
                  address: info.contractAddress,
                  name: 'deployedCount',
                },
              ];
              const [deployedCount] = await multicall(getPresaleABI(selectedChainid), calls1);
              return{
                data: {

                }
              }
        } 

    } catch (error: any) {
        console.log(error)
        return {
            data: {

            }
        };
    }
    return {
        data: {

        }
    };
};

export const fetchUserInfo = async (chainId: string, account: string): Promise<any> => {
    const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
    const presaleInfo = getPresaleInfo(selectedChainid);
    return {
        data: {
            balance: 0
        }
    };
};


export const fetchDeployedTokensSize = async (): Promise<any> => {
    const presaleInfo = getPresaleInfo(250);

    try {
        const info = presaleInfo[0]
        const [deployedCount] = await multicall(
            getPresaleABI(250),
            [
              {
                address: info.contractAddress,
                name: 'deployedCount',
                params: [],
              },
            ]
          );
          return Number(deployedCount[0]);
    } catch (error: any) {
       console.log(error)
      return 0;
    }
  };


export const fetchDeployedPresaleSizeInfo = async (): Promise<any> => {
    const size = await fetchDeployedTokensSize();
    return {
      data: size,
    };
  };

  export const fetchDeployedPresales = async (chainId: number, account: string, indexes: [number], ftmPrice: number): Promise<any> => {
    const presaleInfo = getPresaleInfo(chainId);
    const availabelRouters =  getRouters(chainId);
    const offchainDataDeployed = await getDeployedTokensInfo({chain: chainId});

    try {
          const tokensDeployed = await Promise.all(
              indexes.map(async (row: any) => { 
                try{
                    const contractAddress =  presaleInfo[0].contractAddress

                 const [deployedTokenAddress] = await multicall(
                      getPresaleABI(chainId),
                      [
                        {
                          address: contractAddress,
                          name: 'deployedPresales',
                          params: [row],
                        },
                      ]
                  );
                  const [info] = await multicall(
                      getPresaleABI(chainId),
                      [
                        {
                          address: contractAddress,
                          name: 'presalesInfo',
                          params: [deployedTokenAddress[0]],
                        },
                      ]
                  );

                  const [totalEarned] = await multicall(
                    getSmartPresaleABI(),
                    [
                      {
                        address: info?.presaleContract,
                        name: 'totalEarned',
                        params: [],
                      },
                    ]
                );
                const [totalBuyers] = await multicall(
                    getSmartPresaleABI(),
                    [
                      {
                        address: info?.presaleContract,
                        name: 'totalBuyers',
                        params: [],
                      },
                    ]
                );

                  let currentRouter = availabelRouters[0];
                  let currentRouterLogo = ""
                  let routerBuyURL = ""
                  let isElock = false

                  for( let i = 0; i < availabelRouters.length; i+=1){
                    if( availabelRouters[i].address === info.tokenInfo.router ){
                      currentRouter = availabelRouters[i];
                    }
                  }
                  if( info.tokenInfo.lockPeriod.gt(0) )
                    isElock = currentRouter.isElock
                  currentRouterLogo = currentRouter.logo;
                  routerBuyURL = currentRouter.buyUrl;
                  let isUserToken = false;
                  let tokenLaunched = false;

                  for( let k = 0; k < offchainDataDeployed.length; k+=1){
                    const token = offchainDataDeployed[k]
                    const tokenName = info.tokenInfo.tokenName
                    const tokenSymbol = info.tokenInfo.tokenSymbol
                    const tokenDeployer = info.presaleMaster
                    if( token.deployerAddress.toLowerCase() === tokenDeployer.toLowerCase() && token.tokenName === tokenName && token.tokenSymbol === tokenSymbol){
                      tokenLaunched = true;
                      break;
                    }
                    // if( info.tokenInfo )
                  }

                  if( account ){
                      if( info.presaleMaster === account ){
                          isUserToken = true
                      }
                  }
                  return {index: row, info: info, currentRouterLogo,routerBuyURL, raised: ethers.utils.formatEther(totalEarned[0]), deployerAddress: contractAddress,  presaleAddress: deployedTokenAddress[0], participants: Number(totalBuyers), isUserToken, isElock, tokenLaunched };
                }
                catch(err){
                  return { index: -1 }
                }
              } 
              ) 
          )
          return { tokens: tokensDeployed };
    } catch (error: any) {
       console.log(error)
      return {tokens: []};
    }
  };


  export const fetchDeployedPresalesGlobalInfo = async (chainId: number, account: string, indexes: [number], ftmPrice: number): Promise<any> => {
    const data = await fetchDeployedPresales(chainId, account, indexes, ftmPrice);
    return {
      data: data,
    };
  };