import { FC,  useState } from 'react';
import { Box,  Typography, styled } from '@mui/material';
import {ContainedTextField} from "../../common/Input/ContainedTextField";
	
const PoolName2 = styled(Typography)(({theme}) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '14px',
    textAlign: 'left',

    [theme.breakpoints.up('xs')]: {
        display: 'block',
        textAlign: 'left',
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
        textAlign: 'left'
    },
    [theme.breakpoints.up('md')]: {
        textAlign: 'left'
    },
}));


interface Props {
    isCheckErrors: boolean
    rewardTokenInfo?: any,
    stakingTokenInfo?: any
    rewardTokenBalance?: string,
    tokenSectionChanges: (rewardTokenAddress: string,
                          rewardTokenAmount: string,
                          stakingTokenAddress: string,
                          stakingPoolDuration:string,
                          stakingName: string) => void,
}

const TokenSection: FC<Props> = ({ tokenSectionChanges, isCheckErrors , rewardTokenInfo, stakingTokenInfo, rewardTokenBalance = ""}) => {
    const [rewardTokenAddress, setRewardTokenAddress] = useState<string>('');
    const [rewardTokenAmount, setRewardTokenAmount] = useState<string>('');
    const [stakingTokenAddress, setStakingTokenAddress] = useState<string>('');
    const [stakingPoolDuration, setStakingPoolDuration] = useState<string>('');
    const [stakingName, setStakingName] = useState<string>('');
    const [modalOpen, setModalOpen] = useState<boolean>(true);

    function isNumeric(n: any) {
        return !Number.isNaN(Number(n)) && Number.isFinite(Number(n));
      }
    const onChangeRewardTokenAddress = (e: any) => {
        const newValue = e.target.value;
        setRewardTokenAddress(newValue);
        tokenSectionChanges(newValue, rewardTokenAmount, stakingTokenAddress, stakingPoolDuration, stakingName)
    };
    const onChangeStakingName = (e: any) => {
        const newValue = e.target.value;
        setStakingName(newValue);
        tokenSectionChanges(rewardTokenAddress, rewardTokenAmount, stakingTokenAddress, stakingPoolDuration, newValue)
    };
    const onChangeRewardTokenAmount = (e: any) => {
        if (!isNumeric(e.target.value)) return;
        if( rewardTokenBalance.length > 0 ){
            if( Number(rewardTokenBalance) < e.target.value)
                return
        }
        const newValue = e.target.value;
        setRewardTokenAmount(newValue);
        tokenSectionChanges(rewardTokenAddress, newValue, stakingTokenAddress, stakingPoolDuration, stakingName)
    };
    const onChangeStakingTokenAddress = (e: any) => {
        const newValue = e.target.value;
        setStakingTokenAddress(newValue);
        tokenSectionChanges(rewardTokenAddress, rewardTokenAmount, newValue, stakingPoolDuration, stakingName)

    };
    const onChangeStakingPoolDuration = (e: any) => {
        if (!isNumeric(e.target.value)) return;
        const newValue = e.target.value;
        setStakingPoolDuration(newValue);
        tokenSectionChanges(rewardTokenAddress, rewardTokenAmount, stakingTokenAddress, newValue, stakingName)
    };

  return (
    <>
         <Box display='flex' gap = {4} sx={{flexDirection: {xs: 'column', md: 'column'}, padding: {xs: '0px', md: '0px 30px 0px 30px'}, width: '100%'}} justifyContent='center'>
            <Box display='flex' gap = {2} flexDirection='column' justifyContent='center' alignItems='center' sx={{border: '0px solid blue', width: '100%'}}>
            <Box gap={'5px'} display='flex' alignItems={'center'} flexDirection={'column'} sx={{
                        border: '0px solid grey',
                        width: '100%',
                        }}>
                    <ContainedTextField
                        variant={'outlined'}
                        placeholder="Enter Staking Pool Name"
                        fullWidth
                        label = 'Staking Pool Name'
                        value={stakingName}
                        onChange={onChangeStakingName}
                        autoComplete = 'off'
                        error={isCheckErrors ? stakingName.length === 0 : false}
                        InputLabelProps={{
                            sx: {
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '100%',
                                textAlign: 'center',
                                transition: 'all 0.3s ease',
                            },
                        }}
                        sx={{
                            '& .MuiInputBase-root': {
                                height: '30px',
                                textAlign: 'center',
                            },
                            '& .MuiInputLabel-shrink': {
                                top: 0,
                                left: 0,
                                transform: 'translate(14px, -50%) scale(0.75)',
                                textAlign: 'left',
                                width: 'auto',
                            },
                            '& input::placeholder': {
                                textAlign: 'center',
                            },
                        }}
                    />
                </Box>
                <Box gap={'5px'} display='flex' alignItems={'center'} flexDirection={'column'} sx={{
                        border: '0px solid grey',
                        width: '100%',
                        }}>
                    <ContainedTextField
                        variant={'outlined'}
                        placeholder="Enter Reward Token Address"
                        fullWidth
                        label = 'Reward Token Address'
                        onChange={onChangeRewardTokenAddress}
                        autoComplete = 'off'
                        error={isCheckErrors ? rewardTokenAddress.length === 0 : false}
                        InputLabelProps={{
                            sx: {
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '100%',
                                textAlign: 'center',
                                transition: 'all 0.3s ease',
                            },
                        }}
                        sx={{
                            '& .MuiInputBase-root': {
                                height: '30px',
                                textAlign: 'center',
                            },
                            '& .MuiInputLabel-shrink': {
                                top: 0,
                                left: 0,
                                transform: 'translate(14px, -50%) scale(0.75)',
                                textAlign: 'left',
                                width: 'auto',
                            },
                            '& input::placeholder': {
                                textAlign: 'center',
                            },
                        }}
                    />
                    {rewardTokenInfo  && rewardTokenInfo?.address === rewardTokenAddress && rewardTokenAddress.length > 0 && 
                        <PoolName2 mt={2}>
                            Reward Token Name: {rewardTokenInfo?.name}
                        </PoolName2>
                    }
                    { rewardTokenInfo  && rewardTokenInfo?.address === rewardTokenAddress && rewardTokenAddress?.length > 0 && rewardTokenBalance.length > 0 &&
                        <PoolName2>
                        Your Balance: {rewardTokenBalance}
                        </PoolName2>
                    }   


                </Box>
                <Box display='flex' sx={{
                        border: '0px solid grey',
                        width: '100%'
                        }}>
                    <ContainedTextField
                        variant="outlined"
                        placeholder="Enter Reward Token Amount"
                        fullWidth
                        label="Reward Token Amount"
                        value={rewardTokenAmount}
                        onChange={onChangeRewardTokenAmount}
                        autoComplete="off"
                        error={isCheckErrors ? rewardTokenAmount.length === 0 : false}
                        InputLabelProps={{
                            sx: {
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '100%',
                                textAlign: 'center',
                                transition: 'all 0.3s ease',
                            },
                        }}
                        sx={{
                            '& .MuiInputBase-root': {
                                height: '30px',
                                textAlign: 'center',
                            },
                            '& .MuiInputLabel-shrink': {
                                top: 0,
                                left: 0,
                                transform: 'translate(14px, -50%) scale(0.75)',
                                textAlign: 'left',
                                width: 'auto',
                            },
                            '& input::placeholder': {
                                textAlign: 'center',
                            },
                        }}
                    />

                </Box>
            </Box>


             <Box display='flex' gap = {2} flexDirection='column' justifyContent='center' alignItems='center' sx={{border: '0px solid blue', width: '100%'}}>
                 <Box display='flex'  alignItems={'center'} flexDirection={'column'} gap={'5px'} sx={{
                     border: '0px solid grey',
                     width: '100%',
                     
                 }}>
                     <ContainedTextField
                         variant={'outlined'}
                         placeholder="Enter Staking Token Address"
                         fullWidth
                         value={stakingTokenAddress}
                         label = 'Staking Token Address'
                         onChange={onChangeStakingTokenAddress}
                         autoComplete = 'off'
                         InputLabelProps={{
                             sx: {
                                 top: '50%',
                                 left: '50%',
                                 transform: 'translate(-50%, -50%)',
                                 width: '100%',
                                 textAlign: 'center',
                                 transition: 'all 0.3s ease',
                             },
                         }}
                         sx={{
                             '& .MuiInputBase-root': {
                                 height: '30px',
                                 textAlign: 'center',
                             },
                             '& .MuiInputLabel-shrink': {
                                 top: 0,
                                 left: 0,
                                 transform: 'translate(14px, -50%) scale(0.75)',
                                 textAlign: 'left',
                                 width: 'auto',
                             },
                             '& input::placeholder': {
                                 textAlign: 'center',
                             },
                         }}
                     />
                    {stakingTokenInfo && stakingTokenInfo?.address === stakingTokenAddress  && stakingTokenAddress.length > 0 && 
                        <PoolName2 mt={2}>
                            Staking Token Name: {stakingTokenInfo?.name}
                        </PoolName2>
                    }
                 </Box>
                 <Box display='flex' sx={{
                     border: '0px solid grey',
                     width: '100%'
                 }}>
                     <ContainedTextField
                         variant='outlined'
                         placeholder="Enter Staking Pool Duration (days)"
                         fullWidth
                         value={stakingPoolDuration}
                         label = 'Staking Pool Duration (days)'
                         onChange={onChangeStakingPoolDuration}
                         autoComplete = 'off'
                         InputLabelProps={{
                             sx: {
                                 top: '50%',
                                 left: '50%',
                                 transform: 'translate(-50%, -50%)',
                                 width: '100%',
                                 textAlign: 'center',
                                 transition: 'all 0.3s ease',
                             },
                         }}
                         sx={{
                             '& .MuiInputBase-root': {
                                 height: '30px',
                                 textAlign: 'center',
                             },
                             '& .MuiInputLabel-shrink': {
                                 top: 0,
                                 left: 0,
                                 transform: 'translate(14px, -50%) scale(0.75)',
                                 textAlign: 'left',
                                 width: 'auto',
                             },
                             '& input::placeholder': {
                                 textAlign: 'center',
                             },
                         }}
                     />
                 </Box>
             </Box>
          </Box>
    </>
  );
};

export default TokenSection;

