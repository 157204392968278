import { useEffect, useState } from "react";

export const useGetTokensLogoPiniata2 = (reloadCounter: number) => {
  const [stat, setStat] = useState<any>();

  useEffect(() => {
      async function getStakeInfo() {
        try {
            
          const depl = []
          let response2: any
          const jwt = process.env.REACT_APP_PINATA_API_JWT
          try {
            const options = {method: 'GET', headers: {Authorization: `Bearer ${jwt}`}};
            // https://aquamarine-deliberate-harrier-731.mypinata.cloud/ipfs/bafybeifl4bwgrvtolsbcdhuni5zxlc4tssuentmznauzhbcv4u4mkc7jba
            const res = await fetch('https://api.pinata.cloud/data/pinList?status=pinned&pageLimit=100', options)
                .then((response) => {
                  return response.json();
                })
                .then((data) => {
                  response2 = data
                })
                .catch(err => {
                  console.error(err)
                 return
                });
            } catch (error) {
              console.log(error)
              return
            }

          if( response2 ){
              for( let k = 0; k < response2.rows.length; k++ ) {
                if( response2.rows[k].metadata.keyvalues ){
                  depl[response2.rows[k].metadata.name.toLowerCase()] = {img: `https://aquamarine-deliberate-harrier-731.mypinata.cloud/ipfs/${response2.rows[k].ipfs_pin_hash}`,
                   desc: response2.rows[k].metadata.keyvalues['desc'], tg: response2.rows[k].metadata.keyvalues['tg'],
                   twitter: response2.rows[k].metadata.keyvalues['twitter'], web: response2.rows[k].metadata.keyvalues['web']}
                } else {
                  depl[response2.rows[k].metadata.name.toLowerCase()] = {img: `https://aquamarine-deliberate-harrier-731.mypinata.cloud/ipfs/${response2.rows[k].ipfs_pin_hash}`, desc: '', tg: '', twitter: '', web: ''}

                }
              }

              setStat(depl)
          }

          // setStat({value: res});
        } catch (err) {
          console.log(err);
        }
      }
   
      getStakeInfo().then();
    }, [reloadCounter]);
    return stat;
}
