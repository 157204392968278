import { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, styled, Tab, Tabs, Typography } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import XIcon from '@mui/icons-material/X';
import TelegramIcon from '@mui/icons-material/Telegram';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { icons } from 'config/constants/assets';
import { ContainedButton } from 'components/common/Button';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { getBalanceInEther, } from 'utils';
import { useWeb3React } from '@web3-react/core';
import { useFtmPrice } from 'hooks/useFTMPrice';
import { getLockerAddress, getMemDaoAddress, getRouters, getTokenDeployerInfo } from 'utils/deployTokens';
import useCountDown from 'hooks/useCountDown';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { fetchDeployedTokensDataByCAAsync, fetchStakerInfoAsync,} from 'state/actions';
import { useGetTokensLogoPiniata2 } from 'hooks/useGetDeployedTokenImage';
import StakerPool from './StakerPool';
import useCountDownUp from 'hooks/useCountDownUp';
import { setSelectedChainId } from 'state/chain/chainSlice';
import {InfoLabelBegin, InfoLabelEnd} from "../common/Typography/InfoLabel";
import ContainedInfoBox from "../common/InfoBox/ContainedInfoBox";
import LogoBox from "../common/LogoContainer/LogoBox";
import RouterLogo from "../common/LogoContainer/RouterLogo";
import { usePinata } from 'hooks/usePinata';
import BlueLinkIcon from "../common/icons/CustomLink";
import {useTokenInfo} from "../../hooks/useTokenInfo";


const Container = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    marginTop: '30px',
    background: "rgba(42,49,57, 0.4)",
    display: 'flex',
    maxWidth: '900px',
    borderRadius: '10px',
    flexDirection: 'column',
    minHeight: '700px',
    minWidth: '300px',
    [theme.breakpoints.up('xs')]: {
      padding: '0px 16px',
      minWidth: '280px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '20px 32px',
    },
  }));

  const PoolInfoLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '16px',
    maxWidth: '300px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',
    width: '100%',
    marginLeft: '10px',
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      textAlign: 'left',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'left',
      whiteSpace: 'break-spaces',
    },
  }));


const PoolName = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '700',
  cursor: 'pointer',
  fontSize: '25px',
  lineHeight: '24px',
  maxWidth: '200px',
  whiteSpace: 'break-spaces',
  textAlign: 'left',

  [theme.breakpoints.up('xs')]: {
    display: 'block',
    whiteSpace: 'nowrap',
  },
  [theme.breakpoints.up('sm')]: {
    whiteSpace: 'break-spaces',
  },
  [theme.breakpoints.up('md')]: {
    display: 'block',
    whiteSpace: 'break-spaces',
  },
}));

const PoolSymbol = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '500',
    cursor: 'pointer',
    fontSize: '20px',
    lineHeight: '24px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      whiteSpace: 'break-spaces',
    },
  }));


const PoolName2 = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      textAlign: 'left',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left'
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'left'
    },
  }));



const ActionButton = styled(ContainedButton)(() => ({
    maxWidth: '102px',
    height: '30px',
  }));

// modal body
const ModalBody = styled(Box)(() => ({
  marginTop: '23px',
  marginBottom: '21px',
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  gap: '20px'
}));

function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        style={{width: '100%'}}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box display={'flex'} justifyContent='center' sx={{width: '100%', minHeight: '700px'}}>
            {children}
          </Box>
        )}
      </div>
    );
  }

interface Props {
}

const TokenPage: FC<Props> = ({ }) => {
  const { selectedChainId, isWalletChecked } = useAppSelector((state) => state.chain);
  const { pendingTxHash } = useAppSelector((state) => state.modal);
  const pendingTxHashRef: { current: string | undefined } = useRef();
  pendingTxHashRef.current = pendingTxHash;
  const ftmPrice = useFtmPrice(Number(selectedChainId));
  const [tokenPrice, setTokenPrice] = useState<string>("--");
  const [amm, setAmm] = useState<string>("--");
  const [tokenImage, setTokenImage] = useState<string>("");
  const [lockedLps, setLockedLps] = useState<Number>(0);
  const [info, setInfo] = useState<any>({});
  const [stakers, setStakers] = useState<any>([]);
  const [stakerPools, setStakerPools] = useState<any>([]);
  const [value, setValue] = useState(0);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [days, hours, minutes, seconds] = useCountDown(info?.info ? Number(info.info.timeStamp) * 1000 : new Date().getUTCSeconds() * 1000);
  const dispatch = useAppDispatch();
  const { account } = useWeb3React();
  const {  stakersPools, tokenInfoPage } = useAppSelector((deployedTokens) => deployedTokens.deployedTokens);

  const tokenDeployerInfo = getTokenDeployerInfo(Number(selectedChainId));
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tokenDescription, setTokenDescription] = useState<string>("");
  const [tokenTg, setTokenTg] = useState<string>("");
  const [tokenX, setTokenX] = useState<string>("");
  const [tokenWeb, setTokenWeb] = useState<string>("");
  const [customLinks, setCustomLinks] = useState<any>([]);
  const {combinedData} = usePinata(0)
  const {lockerInfo, beethovenInfo} = useTokenInfo(Number(selectedChainId), info);
  const [daysUntilUnlock, hoursUntilUnlock, minutesUntilUnclock, secsUntilUnlock] = useCountDownUp(beethovenInfo?.isBeethoven ? (  Number(beethovenInfo.beethovenLockPeriod) * 1000) : info?.info ? Number(info.unlockDate) * 1000 : new Date().getUTCSeconds() * 1000);

  useEffect(() => {
    const a = document.createElement('a');
    const url = new URL(window.location.href);
    const res = url.pathname.split('/');

    if( res.length > 3 ){
        dispatch(setSelectedChainId(Number(res[3])));
        if( ftmPrice )
            dispatch(fetchDeployedTokensDataByCAAsync(Number(selectedChainId), account ? account : "", res[2], ftmPrice));

    } else  if( res.length > 2 ){
      if( ftmPrice )
          dispatch(fetchDeployedTokensDataByCAAsync(Number(selectedChainId), account ? account : "", res[2], ftmPrice));
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href, ftmPrice , account]);

  useEffect(() => {
    if(  tokenInfoPage ){
            setInfo(tokenInfoPage)
            setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenInfoPage]);

  const onCloseModal = () => {
    setIsExpanded(false)
  };

  useEffect(() => {
    if( combinedData && info ){
        setTokenImage(combinedData[info?.info?.tokenAddress.toLowerCase()]?.imageUrl)
        setTokenDescription(combinedData[info?.info?.tokenAddress.toLowerCase()]?.description)
        setTokenTg(combinedData[info?.info?.tokenAddress.toLowerCase()]?.tg)
        setTokenX(combinedData[info?.info?.tokenAddress.toLowerCase()]?.twitter)
        setTokenWeb(combinedData[info?.info?.tokenAddress.toLowerCase()]?.web)
        setCustomLinks(combinedData[info?.info?.tokenAddress.toLowerCase()]?.customLinks)
    }
}, [combinedData, info]);

useEffect(() => {
    const pools = []
    if( stakersPools && info ){
        try{
            if( stakersPools[info.info.tokenAddress]) {
                for (const pool  of Object.values(stakersPools[info.info.tokenAddress])) {
                    pools.push(pool)
                    // break
                }
                setStakerPools(pools)
            }
        }
        catch(err){
            console.log(err)
        }
    }
}, [stakersPools]);

useEffect(() => {
if( ftmPrice && info ){
    setTokenPrice((info?.tokenPriceFTM * ftmPrice).toFixed(info?.tokenPriceFTM < 0.001 ? 6 : 3))
}
}, [ftmPrice, info]);


useEffect(() => {
    if( info ) {
        dispatch(fetchStakerInfoAsync(Number(selectedChainId), account ? account : "", ftmPrice, info));
    }
}, [info, selectedChainId]);

useEffect(() => {
if( info ){ 
  const routers = getRouters(Number(selectedChainId))
  setLockedLps(Number(info?.lockLps))
  for( let i = 0; i < routers.length; i+=1 )
  {
    if( routers[i].address === info?.info?.router ){
        setAmm(routers[i].name)
        return
    }
  }
}
}, [info]);


const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

const truncate = (str: string, n: number) => {
    return str?.length > n ? `${str.substr(0, n ) } ... ${str.substr(str.length - n, n) }`: str;
    };


    const buy = async () => {
        if( info ){
          if( info.routerBuyURL ){
              checkProtocolAndOpen(info.routerBuyURL)
          }
            if (info.isBeethoven)
            {
                checkProtocolAndOpen('https://beets.fi/swap')
            }
        }
      };

    function checkProtocolAndOpen(url: any) {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            const httpsUrl = 'https://' + url;
            window.open(httpsUrl);
        } else {
            window.open(url);
        }
    }

  return (
    <> <Container>
        {/* body */}
        <ModalBody sx={{position: 'relative'}}>
              
        
            { info && info?.currentRouterLogo?.length > 0 &&
                <RouterLogo imageUrl={info?.currentRouterLogo} sx={{right: {xs:0, md: 0}, top: 120 }}/>
            }
            { beethovenInfo && beethovenInfo?.isBeethoven &&
                 <>
                    <Box sx={{display: { xs: 'none', md: 'flex', position: 'absolute', right: 0, top: 120}}}>
                      <img src={beethovenInfo ? beethovenInfo?.beethovenImg : ""}  style={{width: '40px', height: '40px', objectFit: 'cover'}}/>
                    </Box>
                    <Box sx={{display: { xs: '', md: 'none',  position: 'absolute', right: 0, top: '40%'}}}>
                          <img src={beethovenInfo ? beethovenInfo?.beethovenImg : ""}  style={{width: '40px', height: '40px', objectFit: 'cover'}}/>
                    </Box>
                    </>
            }

            <Box gap='30px' display='flex' sx={{width: '100%', flexDirection: {xs: 'column', md: 'row'}}}>
                <Box display='flex' flexDirection='row'  alignItems='center' gap='10px' sx={{height: '100%', maxWidth: '200px', border: '0px solid red', position: 'relative'}}>
                      <LogoBox imageUrl={tokenImage} sx={{width: '150px', height: '180px',}}/>
                </Box>
              <Box display='flex' gap={'10px'} flexDirection='column' justifyContent='start' sx={{border: '0px solid blue', width: '50%', height: '100%'}}>
                  <PoolName >
                      {info?.info?.name}
                  </PoolName>
                  <PoolSymbol>
                      {info?.symbol}
                  </PoolSymbol>
                  <Box display='flex' flexDirection={'row'} gap='8px'   sx={{border: '0px solid red'}}>
                      <PoolName2>
                          Total Supply: {info?.info?.supply ? Number(getBalanceInEther(info?.info.supply)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
                      </PoolName2>
                  </Box>
                  <Box display='flex' justifyContent='center' alignItems='center' gap='5px' sx={{background: "rgba(6,1,1,0.3)", display: {xs: 'flex', md: 'flex'}, padding: '2px', maxWidth: '100px', paddingRight: '8px', paddingLeft: '8px', borderRadius: '10px'}}>
                      <img src={icons.timeIcon}  style={{width: '22px', height: '22px', minWidth: '20px', objectFit: 'fill'}}/>  
                      <PoolName2 sx={{border: '0px solid red'}}>
                          {days > 0 ? days : hours > 0 ? hours : minutes > 0 ? minutes : seconds } {days > 0 ? "d" : hours > 0 ?  "h" :  minutes > 0 ? "m" : "s"}
                      </PoolName2>
                  </Box>
                  <Box display='flex' justifyContent='center' alignItems='center' gap='5px' sx={{background: "rgba(6,1,1,0.0)", display: (info?.routerBuyURL || info?.isBeethoven) ? 'flex' : 'none', padding: '2px', maxWidth: '100px', paddingRight: '8px', paddingLeft: '8px', borderRadius: '10px'}}>
                      <ActionButton variant='outlined' onClick={buy} sx={{ maxWidth: '202px', marginLeft: '0px' }}>
                              BUY
                      </ActionButton>
                  </Box>
                </Box>
                <Box display={'flex'} flexDirection={'column'} sx={{width: '100%'}} gap={'5px'}>
                  <Box sx={{
                      border: '0px solid green',
                      height: '100px',
                      width: '100%',
                      background: 'rgba(6,1,1,0.33)',
                      borderRadius: '10px',
                      }}>
                          <Typography
                              variant="h6"
                              align="center"
                              gutterBottom
                              sx={{ border: '0px solid red', fontSize: '1rem', padding: '5px', fontWeight: 'lighter', wordWrap: 'wrap', whiteSpace: 'normal', wordBreak: "break-word", maxHeight: '75px', overflow: 'auto' }}
                          >
                              {tokenDescription}
                          </Typography>
                    </Box>
                    <Box display='flex' justifyContent='center'>
                      { tokenTg?.length > 0 &&
                                  <Button onClick={() => {checkProtocolAndOpen(tokenTg)}} sx={{zIndex: 1}}>
                                      <TelegramIcon sx={{width: '25px'}} />
                                  </Button>
                        }
                        { tokenX?.length > 0 &&
                            <Button onClick={() => {checkProtocolAndOpen(tokenX)}} sx={{zIndex: 1}}>
                                <XIcon sx={{width: '18px'}} />
                            </Button>
                        }
                        { tokenWeb?.length  > 0 &&
                            <Button onClick={() => {checkProtocolAndOpen(tokenWeb)}} sx={{zIndex: 1}}>
                                <LanguageIcon sx={{width: '25px', height: '25px'}} />
                            </Button>
                        }

                    {customLinks && customLinks?.length > 0 && customLinks[0]?.[1]?.length > 0 && (
                        <BlueLinkIcon tooltipTitle={customLinks[0]?.[0]}
                                      onClick={(e) => {
                                          e.stopPropagation();
                                          //@ts-ignore
                                          checkProtocolAndOpen(customLinks[0]?.[1]);
                                      }}/>
                    )}
                    {customLinks && customLinks?.length > 0 && customLinks[1]?.[1]?.length > 0 && (
                        <BlueLinkIcon tooltipTitle={customLinks[1]?.[0]}
                                      onClick={(e) => {
                                          e.stopPropagation();
                                          //@ts-ignore
                                          checkProtocolAndOpen(customLinks[1]?.[1]);
                                      }}/>
                    )}
                    </Box>
                </Box>
            </Box>

        </ModalBody>
        <Grid container display='flex' spacing={1} justifyContent='center'>
        <Grid item xs={12} md={4}>
                <Box display='flex' flexDirection='column' gap={1}>
                    <ContainedInfoBox  sx={{minHeight: '34px', minWidth: '200px'}}>
                        <InfoLabelBegin>
                                Initial MCap:  
                        </InfoLabelBegin>
                        <InfoLabelEnd>
                           {info?.info?.lockedLiquidity?.gt(0) && info?.initialPrice > 0 ? `${(info?.initialPrice * Number(getBalanceInEther(info?.info?.supply))).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${tokenDeployerInfo ? tokenDeployerInfo.nativeTokenName : ""}`:`--.--` } 
                        </InfoLabelEnd>
                    </ContainedInfoBox>
                    <ContainedInfoBox  sx={{minHeight: '34px', minWidth: '200px'}}>
                        <InfoLabelBegin sx={{minWidth: '120px'}}>
                                Initial Liquidity:  
                        </InfoLabelBegin>
                        <InfoLabelEnd>
                            {info?.info?.lockedLiquidity?.gt(0) ? `${Number(lockedLps).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') } ${tokenDeployerInfo ? tokenDeployerInfo.nativeTokenName : ""}` : `--.--`}
                        </InfoLabelEnd>
                    </ContainedInfoBox>
                    <ContainedInfoBox sx={{ minHeight: '34px', minWidth: '200px'}}>
                        <InfoLabelBegin>
                                Liquidity Lock:  
                        </InfoLabelBegin>
                        {beethovenInfo?.isBeethoven &&
                          <>
                            { beethovenInfo?.beethovenScc &&
                                <InfoLabelEnd>
                                    SCC
                                </InfoLabelEnd>
                            }
                            { beethovenInfo?.beethovenLocked &&
                                <InfoLabelEnd>
                                  {beethovenInfo?.beethovenLockPeriod > 0  ? `Expires in `: ''}{beethovenInfo?.beethovenLockPeriod > 0  ? daysUntilUnlock > 0 ? daysUntilUnlock : hoursUntilUnlock > 0 ? hoursUntilUnlock : minutesUntilUnclock > 0 ? minutesUntilUnclock : secsUntilUnlock : "" }{ beethovenInfo?.beethovenLockPeriod > 0 ? daysUntilUnlock > 0 ? "d " : hoursUntilUnlock > 0 ?  "h " :  minutesUntilUnclock > 0 ? "m " : "s " : ''}
                                  {/* {info?.info?.lockedLiquidity.gt(0) ? info?.unlockDate > 0  ? "" : "" : 'None'} */}
                                </InfoLabelEnd>
                            }
                            { !beethovenInfo?.beethovenScc &&  !beethovenInfo?.beethovenLocked &&
                                <InfoLabelEnd>
                                    Unlocked
                                </InfoLabelEnd>
                            }
                          </>
                        }
                        {!beethovenInfo?.isBeethoven &&
                          <>
                        { info?.elockId > 0 && 
                          <InfoLabelEnd>
                          {`eLOCK`}
                          </InfoLabelEnd>
                        }
                        {info?.info?.dao &&
                          <InfoLabelEnd>
                            SCC
                        </InfoLabelEnd>
                        }
                        {!info?.info?.dao && info?.elockId <= 0 &&
                          <>
                        { info?.info?.lockedLiquidity.gt(0) && lockerInfo?.unlockDate > 0 &&
                          <InfoLabelEnd>
                          {lockerInfo?.unlockDate > 0  ? `Expires in `: ''}{lockerInfo?.unlockDate > 0  ? daysUntilUnlock > 0 ? daysUntilUnlock : hoursUntilUnlock > 0 ? hoursUntilUnlock : minutesUntilUnclock > 0 ? minutesUntilUnclock : secsUntilUnlock : "" }{ lockerInfo?.unlockDate > 0 ? daysUntilUnlock > 0 ? "d " : hoursUntilUnlock > 0 ?  "h " :  minutesUntilUnclock > 0 ? "m " : "s " : ''}
                          {info?.info?.lockedLiquidity.gt(0) ? lockerInfo?.unlockDate > 0  ? "" : "" : 'None'}
                          </InfoLabelEnd>
                        }
                        { info?.info?.lockedLiquidity.gt(0) && lockerInfo?.unlockDate === 0 && info?.info.deployerAddress === account && lockerInfo?.amount === 0 &&
                          <InfoLabelEnd>
                          {'Unlocked'}
                          </InfoLabelEnd>
                        }
                        { info?.info?.lockedLiquidity.gt(0) && lockerInfo?.unlockDate === 0 && info?.info.deployerAddress !== account &&
                          <InfoLabelEnd>
                          {'Unlocked'}
                          </InfoLabelEnd>
                        }
                        { info?.info?.lockedLiquidity.eq(0) &&
                          <InfoLabelEnd>
                          {'None'}
                          </InfoLabelEnd>
                        }
                          </>
                        }
                          </>
                        }
                    </ContainedInfoBox>
                </Box>
            </Grid>
        <Grid item xs={12} md={4}>
                <Box display='flex' flexDirection='column' gap={1}>
                    <ContainedInfoBox sx={{minHeight: '34px', minWidth: '200px'}}>
                        <InfoLabelBegin>
                        MCap:  
                        </InfoLabelBegin>
                        <InfoLabelEnd>
                            {info?.info?.lockedLiquidity.gt(0) ? `${(Number(getBalanceInEther(info?.info.supply)) * Number(info?.tokenPriceFTM)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${tokenDeployerInfo ? tokenDeployerInfo.nativeTokenName : ""}` : "--.--"}

                        </InfoLabelEnd>
                    </ContainedInfoBox>
                    <ContainedInfoBox sx={{ minHeight: '34px', minWidth: '200px'}}>
                        <InfoLabelBegin>
                                Liquidity:  
                        </InfoLabelBegin>
                        <InfoLabelEnd>
                            {info?.info?.lockedLiquidity.gt(0) ?  Number(info?.lpPrice) < 1 ? `${ Number(info?.lpPrice).toFixed(4)} ${tokenDeployerInfo ? tokenDeployerInfo.nativeTokenName : ""}`  : `${Number(info?.lpPrice).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${tokenDeployerInfo ? tokenDeployerInfo.nativeTokenName : ""}` : `--.--`} 
                        </InfoLabelEnd>
                    </ContainedInfoBox>
                    <ContainedInfoBox sx={{ minHeight: '34px', minWidth: '200px'}}>
                        <InfoLabelBegin>
                                Price:  
                        </InfoLabelBegin>
                        <InfoLabelEnd>
                            {info?.info?.lockedLiquidity.gt(0) ? `${Number(info?.tokenPriceFTM).toFixed(4)} ${tokenDeployerInfo ? tokenDeployerInfo.nativeTokenName : ""}` : '--.--' } 
                        </InfoLabelEnd>
                    </ContainedInfoBox>
                </Box>
            </Grid>
        <Grid item xs={12} md={4}>
                <Box display='flex' flexDirection='column' gap={1}>
                    <ContainedInfoBox sx={{ minWidth: '170px'}}>
                        <InfoLabelBegin sx={{maxWidth: '80px'}}>
                                Token C/A:  
                        </InfoLabelBegin>
                        <Button 
                              color="primary" 
                              variant="outlined"                          
                              onClick={()=>{    navigator.clipboard.writeText(info?.info?.tokenAddress);}}
                              style={{maxHeight: '24px',borderRadius: '0px',   display: info?.info?.tokenAddress ? "" : "none", width: '140px', maxWidth: '140px'}}
                              >
                              <Typography style={{fontSize: '11px', color: "lightgray"}} color='primary' variant={'h5'} >{truncate(info?.info?.tokenAddress, 4)}
                              </Typography>
                              <FileCopyIcon style={{marginLeft: '2px', maxHeight:"18px", width: 'auto'}}/>
                      </Button>
                    </ContainedInfoBox>
                    <ContainedInfoBox sx={{minWidth: '170px'}}>
                        <InfoLabelBegin sx={{maxWidth: '60px'}}>
                                Deployer:  
                        </InfoLabelBegin>
                        <Button 
                              color="primary" 
                              variant="outlined"                          
                              onClick={()=>{    navigator.clipboard.writeText(info?.info?.deployerAddress);}}
                              style={{maxHeight: '24px', borderRadius: '0px', display: info?.info?.deployerAddress ? "" : "none", width: '140px', maxWidth: '140px'}}
                              >
                              <Typography style={{fontSize: '11px', color: "lightgray"}} color='primary' variant={'h5'} >{truncate(info?.info?.deployerAddress, 4)}
                              </Typography>
                              <FileCopyIcon style={{marginLeft: '2px',maxHeight:"18px", width: 'auto'}}/>
                      </Button>
                    </ContainedInfoBox>
                      
                    <ContainedInfoBox sx={{ minHeight: '34px', minWidth: '170px'}}>
                        {!info?.info?.dao && info.elockId <= 0 && !info?.isBeethoven &&
                          <>
                            <InfoLabelBegin sx={{maxWidth: '80px'}}>
                                Lock C/A:  
                            </InfoLabelBegin>
                            {info?.lockerAddress.length > 0 && 
                                <Button 
                                      color="primary" 
                                      variant="outlined"                          
                                      onClick={()=>{    navigator.clipboard.writeText(info?.lockerAddress)}}
                                      style={{maxHeight: '24px', borderRadius: '0px', display: info?.lpAddress ? "" : "none", width: '140px', maxWidth: '140px'}}
                                      >
                                      <Typography style={{fontSize: '11px', color: "lightgray"}} color='primary' variant={'h5'} > { info?.info?.lockedLiquidity.gt(0) ? truncate(info?.lockerAddress, 4) : '--.--'}
                                      </Typography>
                                      <FileCopyIcon style={{marginLeft: '2px',maxHeight:"18px", width: 'auto'}}/>
                              </Button>
                          }
                          { info?.lockerAddress.length === 0 &&
                            <InfoLabelBegin sx={{maxWidth: '60px'}}>
                                No Lock  
                            </InfoLabelBegin>
                          }
                          {info?.info.lockedLiquidity.eq(0) && 
                            <InfoLabelBegin sx={{maxWidth: '60px'}}>
                                No LP  
                            </InfoLabelBegin>
                          }
                          </>
                        }
                          {info?.isBeethoven &&
                            <>
                            <Box display='flex' alignItems='center' sx={{position: 'relative', width: '100%'}}>

                            <PoolInfoLabel sx={{maxWidth: '80px'}}>
                                Pool ID:  
                            </PoolInfoLabel>
                            <Button  color="primary"  variant="outlined"  sx={{position: 'absolute', top: -4, right: 0, width: '140px', maxWidth: '140px'  , maxHeight: '24px', borderRadius: '0px',}} onClick={() => {window.open(`https://beets.fi/pool/${info?.beethovenPoolIdStr}`)}}>
                                  <Typography style={{fontSize: '11px', color: "lightgray"}} color='primary' variant={'h5'} > 
                                    {`${truncate(info?.beethovenPoolIdStr, 4)}`}
                                  </Typography>
                                  <OpenInNewIcon sx={{width: '25px', height: '25px', position: 'relative', marginLeft: '5px'}}/>
                                </Button>
                            </Box>
                            </>
                          }
                        { info?.elockId > 0 && //https://eliteness.network/elocks/?=31#spotlight
                            <>
                              <Box display='flex' alignItems='center' sx={{position: 'relative', width: '100%'}}>
                                 <InfoLabelBegin>
                                  {`eLOCK:` }
                                </InfoLabelBegin>
                                <Button  color="primary"  variant="outlined"  sx={{position: 'absolute', top: -4, right: 0, width: '140px', maxWidth: '140px'  , maxHeight: '24px', borderRadius: '0px',}} onClick={() => {window.open(`https://eliteness.network/elocks/?=${info?.elockId}#spotlight`)}}>
                                  <Typography style={{fontSize: '11px', color: "lightgray"}} color='primary' variant={'h5'} > 
                                    {`NFT ID ${info?.elockId}` }
                                  </Typography>
                                  <OpenInNewIcon sx={{width: '25px', height: '25px', position: 'relative', marginLeft: '5px'}}/>
                                </Button>
                              </Box>
                            </>
                        }
                        {info?.info?.dao &&
                            <>
                            <InfoLabelBegin sx={{maxWidth: '80px'}}>
                            SCC:  
                            </InfoLabelBegin>
                            <Button 
                                  color="primary" 
                                  variant="outlined"                          
                                  onClick={()=>{    navigator.clipboard.writeText(getMemDaoAddress(Number(selectedChainId)))}}
                                  style={{maxHeight: '24px', borderRadius: '0px', display: info?.lpAddress ? "" : "none", width: '140px', maxWidth: '140px'}}
                                  >
                                  <Typography style={{fontSize: '11px', color: "lightgray"}} color='primary' variant={'h5'} > { info?.info?.lockedLiquidity.gt(0) ? truncate(getMemDaoAddress(Number(selectedChainId)), 4) : '--.--'}
                                  </Typography>
                                  <FileCopyIcon style={{marginLeft: '2px',maxHeight:"18px", width: 'auto'}}/>
                          </Button>
                          </>
                        }
                    </ContainedInfoBox>
                </Box>
            </Grid>

        </Grid>
            <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} sx={{width: '100%'}} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Stats" {...a11yProps(0)} />
                            <Tab label="Airdrop" {...a11yProps(1)} />
                            </Tabs>
            </Box>
            <>
            <Box mt={2} display='flex' sx={{width: '100%'}}>
                <CustomTabPanel value={value} index={0}>
                    <Box display='flex' sx={{width: '100%', minHeight: '700px'}}>
                        <iframe frameBorder="0" width={'100%'} src={`https://dexscreener.com/${tokenDeployerInfo ? tokenDeployerInfo?.dexscreenerChain : ""}/${info?.info?.tokenAddress}?embed=1&theme=dark&info=0`}></iframe>
                    </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <Box mt={2} gap='10px' display='flex' alignItems='center' justifyContent='center' flexDirection='column'>
                        {stakerPools && stakerPools
                        .map((row: any) => <StakerPool tokenInfo={info} poolInfo={row} />)}
                    </Box>
                </CustomTabPanel>
            </Box>
            </>
        </Container>
    </>
  );
};

export default TokenPage;
