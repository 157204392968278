import { useEffect, useState } from 'react';
import  multicall  from 'utils/multicall';
import {getLockerAddress} from "../utils/deployTokens";
import Locker from 'config/abi/LockerABI.json'
import BeethovenAbi from "config/abi/BeethovenABI.json"
import {getTokensBeethovenByAddress} from "../config/constants/backend";
import {icons} from "../config/constants/assets";


export const useTokenInfo = ( chainId:number, info:any,) => {
    const lockerAddress = info?.lockerAddress;
    const tokenAddress = info?.info?.tokenAddress;
    const deployerAddress = info?.info?.deployerAddress;
    const lpAddress = info?.lpAddress;
    const router = info?.info?.router;
    const elockId = info?.elockId;

    const [lockerInfo, setLockerInfo] = useState<any>();
    const [beethovenInfo, setBeethovenInfo] = useState<any>();

    useEffect(() => {
        getTokenInfo().then()
    }, [info, chainId]);

    const getTokenInfo= async () => {

        setLockerInfo( await getLockerInfo(chainId, deployerAddress, lpAddress).then())
        setBeethovenInfo(await getBeethovenInfo(tokenAddress).then())
    }


    const getLockerInfo = async (chainId: number, deployerAddress: string, lpAddress: string) => {
        let lockerInfo = {id: 0, unlockDate: 0, amount: 0, lockDate: 0, owner: "", index: 0};
        let unlockDate = 0;


        try {
            if( lpAddress.length > 0 ) {
                    if (elockId > 0) {

                    } else if (elockId <= 0) {

                        const [numLocks] = await multicall(
                            Locker,
                            [
                                {
                                    address: lockerAddress,
                                    name: 'getUserNumLocksForToken',
                                    params: [deployerAddress, lpAddress],
                                },
                            ]
                        );



                        if (numLocks > 0) {
                            const [lockInfo] = await multicall(
                                Locker,
                                [
                                    {
                                        address: lockerAddress,
                                        name: 'getUserLockForTokenAtIndex',
                                        params: [deployerAddress, lpAddress, 0],
                                    },
                                ]
                            );


                            if (lockInfo.length > 4) {
                                unlockDate = Number(lockInfo[2])
                                if (new Date().getTime() / 1000 > unlockDate) {
                                    unlockDate = 0;
                                }
                                lockerInfo = {
                                    id: lockInfo[3],
                                    unlockDate: lockInfo[2],
                                    amount: Number(lockInfo[1]),
                                    lockDate: lockInfo[0],
                                    owner: lockInfo[4],
                                    index: 0
                                }


                            }
                        }

                    }
            }
        } catch (err) {
            console.error('getLockerInfoError', err);
        }

        return lockerInfo;
    };

    const getBeethovenInfo = async (tokenAddress: string) =>
    {
        let isBeethoven = false;
        let beethovenPoolIdStr = "";
        let beethovenScc = false;
        let beethovenLockPeriod = 0;
        let beethovenLocked = false;

        let beethovenInfo = {isBeethoven: false, beethovenPoolIdStr: "", beethovenScc: false, beethovenLockPeriod: 0, beethovenLocked: false, beethovenImg: ''};

        try {
            const offchainDataTokenBeethoven = await getTokensBeethovenByAddress({address: tokenAddress});
            if(offchainDataTokenBeethoven) {
                if (offchainDataTokenBeethoven.length > 0) {
                    isBeethoven = true;
                    const [beethovenPoolId] = await multicall(
                        BeethovenAbi,
                        [
                            {
                                address:  offchainDataTokenBeethoven[0].tokenPool,
                                name: 'getPoolId',
                                params: [],
                            },
                        ]
                    );
                    beethovenPoolIdStr = beethovenPoolId[0]
                    beethovenScc = offchainDataTokenBeethoven[0].scc
                    beethovenLocked = offchainDataTokenBeethoven[0].locked
                    beethovenLockPeriod = offchainDataTokenBeethoven[0].period
                    if( beethovenLockPeriod > 0 ) {
                        beethovenLockPeriod = beethovenLockPeriod + new Date().getTime() / 1000
                        beethovenInfo = {isBeethoven: isBeethoven,
                            beethovenPoolIdStr: beethovenPoolIdStr,
                            beethovenScc: beethovenScc,
                            beethovenLockPeriod: beethovenLockPeriod,
                            beethovenLocked: beethovenLocked,
                            beethovenImg:  icons.BeethovenRouter,}
                    }
                }
            }else{
                return;
            }


        } catch (e) {
            console.error('getBeethovenInfo Error', e);
        }



        return beethovenInfo;

    }
    return {lockerInfo, beethovenInfo};
};
