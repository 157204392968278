import React from 'react';
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import {Button} from "@mui/material";

const StyledIcon = styled(SvgIcon)(({theme}) => ({

}));



const toolTipTheme = createTheme({
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#2F91FA',
                    color: 'white',
                    fontSize: '10px',
                    fontFamily: 'Prompt'
                },
                arrow: {
                    color: '#2F91FA',
                },
            },
        },
    },
});

interface Props extends SvgIconProps {
    tooltipTitle: string;
}

const BlueLinkIcon: React.FC<Props> = ({tooltipTitle, ...props}) => {
    return (
        <ThemeProvider theme={toolTipTheme}>
            <Tooltip title={tooltipTitle}
                     arrow
                     disableInteractive
            >
                <Button sx={{width: '33px', height: '33px', borderRadius: '8px'}}>
                <StyledIcon
                    {...props}
                    sx={{width: '100%', height: '100%', }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg"
                         width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                         preserveAspectRatio="xMidYMid meet">

                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                           fill="#2F91FA" stroke="none">
                            <path d="M3785 5114 c-233 -36 -422 -112 -587 -238 -40 -30 -320 -303 -623
                                -606 -416 -417 -543 -549 -520 -543 17 4 104 7 195 7 185 -1 296 -17 448 -66
                                l95 -30 391 390 c366 364 396 392 466 426 241 117 504 74 691 -113 161 -161
                                217 -386 150 -601 -42 -135 -58 -152 -680 -773 -561 -560 -589 -586 -662 -622
                                -52 -25 -107 -42 -173 -53 -89 -15 -103 -15 -192 0 -121 20 -211 64 -316 151
                                -94 80 -152 102 -248 95 -93 -7 -146 -39 -270 -163 l-103 -103 49 -74 c172
                                -259 465 -443 793 -499 100 -17 312 -15 414 5 100 19 235 65 322 109 152 76
                                196 116 803 721 326 325 618 624 649 664 105 140 189 328 224 507 8 40 14 132
                                14 215 0 158 -12 235 -56 373 -130 399 -465 703 -879 798 -83 19 -327 33 -395
                                23z"/>
                            <path d="M2055 3424 c-169 -26 -337 -92 -489 -191 -87 -57 -1236 -1196 -1325
                                -1313 -100 -133 -180 -309 -217 -480 -26 -118 -26 -362 0 -480 102 -473 463
                                -834 936 -936 118 -26 362 -26 480 0 171 37 347 117 480 218 41 31 323 305
                                625 608 548 550 550 552 505 544 -77 -12 -330 -6 -425 10 -49 9 -136 30 -193
                                48 l-103 32 -392 -391 c-367 -365 -397 -393 -467 -427 -241 -117 -504 -74
                                -691 113 -161 161 -217 386 -150 601 42 135 57 152 685 777 569 567 594 591
                                662 621 234 106 484 71 673 -93 84 -74 132 -97 211 -102 110 -8 163 19 297
                                150 62 61 113 114 113 118 0 4 -23 42 -52 85 -241 360 -711 558 -1163 488z"/>
                        </g>
                    </svg>
                </StyledIcon>
                </Button>
            </Tooltip>
        </ThemeProvider>
    );
};

export default BlueLinkIcon;
