import { FC, useState } from 'react';
import {Box, Button, TextField, Typography, styled, Tooltip} from '@mui/material';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import {ContainedTextField} from "../../common/Input/ContainedTextField";


const InfoCardLabel = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    cursor: 'pointer',
    textWrap: 'wrap',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
      lineHeight: '18px',
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      lineHeight: '18px',
      textAlign: 'right',
    },
  }));
  
  const ValueTitle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    color: 'white',
    whiteSpace: 'normal',
    textAlign: 'justify',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
      lineHeight: '18px',
      img: {
        display: 'none',
      },
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '11px',
      lineHeight: '18px',
      img: {
        display: 'block',
      },
    },
  }));

interface Props {
  isCheckErrors: boolean
    tokenSectionChanges: (tokenName: string, tokenSymbol: string, totalSupply: string) => void,
    isTotalSupplyError: boolean
    shouldBeTotalSupply: string
}

const TokenSection: FC<Props> = ({ tokenSectionChanges, isCheckErrors, isTotalSupplyError, shouldBeTotalSupply }) => {
    const [name, setName] = useState<string>('');
    const [symbol, setSymbol] = useState<string>('');
    const [totalSupply, setTotalSupply] = useState<string>('');
    const [isSpecsExpanded, setSpecsExpanded] = useState<boolean>(false);

    function isNumeric(n: any) {
      return !Number.isNaN(Number(n)) && Number.isFinite(Number(n));
    }

    const onChangeName = (e: any) => {
        const newAmount = e.target.value;
        setName(newAmount);
        tokenSectionChanges(newAmount, symbol, totalSupply)
    };
      const onChangeSymbol = (e: any) => {
        const newAmount = e.target.value;
        setSymbol(newAmount);
        tokenSectionChanges(name, newAmount, totalSupply)
    };
    const onChangeSupply = (e: any) => {
      if (!isNumeric(e.target.value)) return;
      const newAmount = e.target.value;
      setTotalSupply(newAmount);
      tokenSectionChanges( name ,symbol, newAmount)
   };
  return (
    <>
         <InfoCardLabel mt={1}>
                Token Info
         </InfoCardLabel>
        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', border: '0px solid red', gap: '20px', mt: 3}}>
            <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', border: '0px solid yellow', gap:'20px'}}>

                <ContainedTextField variant={'outlined'} placeholder='Token Name' required={true} onChange={onChangeName}  autoComplete='off'
                                            error={false}  label="Token Name" value={name} />

                <ContainedTextField variant={'outlined'} placeholder='Total Supply' required={true} onChange={onChangeSupply}  autoComplete='off'
                                    error={isTotalSupplyError && totalSupply.length !==0 ? true : false}  label={`${isTotalSupplyError && totalSupply.length !==0 ? "Must Be Greater Than: "+shouldBeTotalSupply : 'Total Supply'}`} value={totalSupply} />

            </Box>
            <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', border: '0px solid yellow', gap:'20px', position: 'relative'}}>

                <ContainedTextField variant={'outlined'} placeholder='Token Symbol' required={true} onChange={onChangeSymbol}  autoComplete='off'
                                    error={false}  label="Token Symbol" value={symbol} />

                <Box display='flex' alignItems='center' flexDirection='column'  sx={{
                    justifyContent: 'center',
                    minWidth: '225px',
                    maxWidth: '225px',
                    minHeight: '53px',
                    padding: '6px',
                    border: '1px solid #2E619E',
                    borderRadius: '10px',
                    mb: {xs: isSpecsExpanded ? '50px' : '0px', md: '0px'},

                }}>
                    <Box display='flex' justifyContent='space-around' alignItems='center' gap={2}>
                        <ValueTitle sx={{fontSize:{md:'14px'}}}>
                            Token Specifications
                        </ValueTitle>
                        <Button sx={{minHeight: '20px', minWidth: '20px'}} onClick={() => {setSpecsExpanded(!isSpecsExpanded)}}>
                            <ArrowDropDownCircleIcon  sx={{transform: isSpecsExpanded  ? "rotate(180deg)" : ""}}/>
                        </Button>
                    </Box>
                    <Box sx={{position: 'absolute', top: {md: 60, xs: 130}, display: isSpecsExpanded ? '' : 'none', border: '1px solid #2E619E',
                        borderRadius: '10px',
                        width: {md:'240px', xs: '260px'},
                        maxHeight: '53px',
                        padding: '0px 6px 0px 6px'}}>
                        {isSpecsExpanded &&
                            <>
                                <Box  display='flex'   flexDirection='column' textAlign='center' >
                                    <ValueTitle>No mint functions. Ownership renounced. No blacklist or whitelist functions. No buy tax, No sell tax, No transfer tax</ValueTitle>
                                </Box>
                            </>
                        }
                    </Box>
                </Box>
          </Box>
        </Box>
    </>
  );
};

export default TokenSection;

