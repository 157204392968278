import {getAirdropsInfo} from "../config/constants/backend";
import multicall from "../utils/multicall";
import ERC20 from "../config/abi/Erc20.json";
import {getBalanceInEther} from "../utils";
import {useEffect, useState} from "react";


export const useAirdropFarmingsInfo = (info:any) => {

    const tokenAddress = info?.info?.tokenAddress;
    const mainTokenPoolAddress = info?.info?.mainTokenPool;

    const [poolCount, setPoolCount] = useState(0);
    const [poolRewards, setPoolRewards] = useState(0);


    useEffect(() => {
        getAirdropFarmingsInfo().then()
    }, [info]);


    const getAirdropFarmingsInfo= async () => {

        setPoolCount(await getPoolsCount(tokenAddress))
        setPoolRewards(await getPoolRewards(mainTokenPoolAddress, tokenAddress))

    }

    const getPoolsCount  = async (tokenAddress:string) =>{
        let poolCount = 0;
        try {
            const offchainData = await getAirdropsInfo({address: tokenAddress});
            if(!offchainData)
                return 0

            poolCount = offchainData[0].poolsCount
            return poolCount;
        } catch (e) {
            console.error('getPoolsCount Error',e);
            return 0;
        }
    }


    const getPoolRewards  = async (mainTokenPoolAddress:string, tokenAddress:string) =>{
        let poolRewards = 0;
        try{
            const [balance] = await multicall(
                ERC20,
                [
                    {
                        address: tokenAddress,
                        name: 'balanceOf',
                        params: [mainTokenPoolAddress],
                    },
                ]
            );
            poolRewards = getBalanceInEther(balance[0]);
            return poolRewards;
        }
        catch(e){
            console.error('getPoolRewards Error',e);
            return 0;
        }
    }

    

    return {poolCount, poolRewards}
}