import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';


export const InfoLabelBegin = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '16px',
    maxWidth: '300px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',
    width: '100%',
    marginLeft: '10px',
    border: '0px solid red',
    [theme.breakpoints.up('xs')]: {
        display: 'block',
        textAlign: 'left',
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
        whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
        display: 'block',
        textAlign: 'left',
        whiteSpace: 'break-spaces',
    },
}));

export const InfoLabelEnd = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '16px',
    maxWidth: '200px',
    marginRight: '10px',
    whiteSpace: 'break-spaces',
    textAlign: 'right',
    width: '100%',
    border: '0px solid red',
    [theme.breakpoints.up('xs')]: {
        display: 'block',
        textAlign: 'right',
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
        whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
        display: 'block',
        textAlign: 'right',
        whiteSpace: 'break-spaces',
    },
}));

export const InfoLabelText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '16px',
    marginRight: '10px',
    whiteSpace: 'break-spaces',
    textAlign: 'center',
    width: '100%',

    [theme.breakpoints.up('xs')]: {
        display: 'block',
        textAlign: 'center',
        whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('sm')]: {
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('md')]: {
        display: 'block',
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
}));
