import { createSlice } from '@reduxjs/toolkit';
import { PresaleInfo } from 'types/presale';
import { fetchDeployedPresaleSizeInfo, fetchDeployedPresalesGlobalInfo, fetchPresaleInfo, fetchUserInfo } from './fetchPreSaleGlobalInfo';

interface PresaleState {
    info: PresaleInfo,
    userInfo: any,
    size: number,
    presaleReloadCount: number,
    deployedPresales: { [index: number]: {isUserToken: boolean, info: any} };
}

const initialState: PresaleState = {
    presaleReloadCount: 0,
    info: {isSaleOpen: false, contractAddress: "", tokenAddress: "", saleTokenAddress: ""},
    userInfo: {},
    size: 0,
    deployedPresales: [],
};

export const presaleSlice = createSlice({
    name: 'presale',
    initialState,
    reducers: {
        reset: (state) => {
            state.presaleReloadCount = 0;
            state.info = {isSaleOpen: false, contractAddress: "", tokenAddress: "", saleTokenAddress: ""}
            state.userInfo = {}
            state.size = 0;
            state.deployedPresales = {}
        },
        setPresaleReloadCount: (state, action) => {
            state.presaleReloadCount = action.payload.data;
        },
        setPresaleInfo: (state, action) => {
            state.info = action.payload.data;
        },
        setUserPresaleInfo: (state, action) => {
            state.userInfo = action.payload.data;
        },
        setDeployedPresaleSize: (state, action) => {
            state.size = action.payload.data;
        },
        setFarmGlobalData: (state, action) => {
            for( let i = 0; i < action.payload.data.tokens.length; i+=1 ){
              if( action.payload.data.tokens[i].index !== -1 )
                state.deployedPresales[action.payload.data.tokens[i].index] = action.payload.data.tokens[i];
            }
        },
    },
});

export const { reset, setPresaleInfo, setUserPresaleInfo, setDeployedPresaleSize, setFarmGlobalData, setPresaleReloadCount } = presaleSlice.actions;

export const resetDataPresale = () => async (dispatch: any): Promise<void> => { 
    dispatch( reset( ))
}

export const setPresaleReloadCountAsync = (count: number) => async (dispatch: any): Promise<void> => { 
    dispatch( setPresaleReloadCount( {
        data: count
    }))
}

export const fetchPresaleInfoAsync =
    (chainId: string) =>
        async (dispatch: any): Promise<void> => {
            const { data } = await fetchPresaleInfo(chainId);
            dispatch(
                setPresaleInfo({
                    data,
                })
            );
        };
export const fetchPresaleUserInfoAsync =
    (chainId: string, account: string) =>
        async (dispatch: any): Promise<void> => {
            const { data } = await fetchUserInfo(chainId, account);
            dispatch(
                setUserPresaleInfo({
                    data,
                })
            );
        };


    export const fetchDeployedPresaleSizeAsync =
        () =>
        async (dispatch: any): Promise<void> => {
          const { data } = await fetchDeployedPresaleSizeInfo();
      
          dispatch(
            setDeployedPresaleSize({
              data,
            })
          );
        };

    export const fetchDeployedPresalesDataAsync =
        (chainId: number, account: string, indexes: [number], ftmPrice: number) =>
        async (dispatch: any): Promise<void> => {
        const { data } = await fetchDeployedPresalesGlobalInfo(chainId, account, indexes, ftmPrice);
    
        dispatch(
        setFarmGlobalData({
            data,
        })
        );
  };

export default presaleSlice.reducer;
