import { FC } from 'react';
import { Box, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { extraMenuItems } from 'config/constants/header';
import { useAppSelector } from 'state/hooks';
import { Link} from 'react-scroll';
const ExtraMenuContainer = styled(Box)(({ theme }) => ({}));

const ExtraMenuContent = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '0px',
  left: '0px',
  zIndex: 150,
  background: '#2A3139',
  padding: '35px 35px',
  height: '100vh',
  width: '320px',
  transition: '2.5s all ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  gap: '35px',

  [theme.breakpoints.up('xs')]: {},
  [theme.breakpoints.up('sm')]: {},
}));

const OverlayBg = styled(Box)(() => ({
  position: 'fixed',
  top: '0px',
  left: '0px',
  right: '0px',
  bottom: '0px',
  zIndex: 10,
  background: '#000000',
  opacity: 0.3,
}));


// menu section
const MenuSection = styled(Box)(({ theme }) => ({}));



const MenuContent = styled(Box)(() => ({
  marginTop: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));


// menu sub item
const MenuSubItemContent = styled(Box)(() => ({
  // marginLeft: '28px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));


const LinkStyle = styled(Link)(({theme}) => ({
  lineHeight: 0.8,
  cursor: "pointer",
  fontSize: "1rem",
  fontWeight: 500,
  color: theme.palette.common.gradient,
  borderTop: '1px solid white',
  borderBottom  : '1px solid white',
  borderRight: '1px solid white',
  borderLeft: '1px solid white',
  minWidth: '120px',
  maxHeight: '35px',
  padding: "8px",
  textAlign: 'center',
  "&:hover": {
    color: "white",
    borderColor: '#1B2F60'
  },
  "&.active": {
    color: "white",
    borderColor: '#1B2F60'
  },
}));


interface ExtraMenuProps {
  onClose: () => void;
}

const ExtraMenu: FC<ExtraMenuProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { selectedChainId } = useAppSelector((state) => state.chain);

  // close opended extra menu
  const onCollapse = () => {
    onClose();
  };

  const onClickSubMenu = (menu: any) => {
    if (menu.isExternal) {
      window.open(menu.link, '_blank');
    } else {
      navigate(menu.link);
      onCollapse();
    }
  };
  const pageName = pathname.replace('/', '').split('/')[0];


  return (
    <ExtraMenuContainer>
      {isMobile && 
            <OverlayBg onClick={onCollapse} />
      }
      <ExtraMenuContent>
        <MenuSection>
          <MenuContent>
            {extraMenuItems[Number(selectedChainId)] &&
              extraMenuItems[Number(selectedChainId)].map((row) => (
                <Box key={row.key}>
                  {row.hasSubItems && row.subItems && row.subItems.length > 0 && (
                    <MenuSubItemContent>
                      {row.subItems &&
                        row.subItems.map((subItem: any) => (
                          <LinkStyle
                          onClick={() => onClickSubMenu(subItem)}
                          key={subItem.key}
                          to={subItem.url}
                          sx={{opacity:  subItem.enabled ? '1.0' : '0.5', borderColor: pageName === subItem.key ? '' : '', pointerEvents: subItem.enabled ? '' : 'none'}}
                          // spy={!!true}
                          spyThrottle={10}
                          offset={-100}
                          smooth={!!true}
                          duration={500}
                          activeClass="active"
                        >
                          <Typography variant='body1'>
                            {subItem.name}
                          </Typography>
                      </LinkStyle>
                        ))}
                    </MenuSubItemContent>
                  )}
                </Box>
              ))}
          </MenuContent>
        </MenuSection>
      </ExtraMenuContent>
    </ExtraMenuContainer>
  );
};

export { ExtraMenu };
