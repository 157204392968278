import { FC, useEffect,  useState } from 'react';
import {Box, Button, Grid, Link, styled, Typography} from '@mui/material';
import {useAppDispatch, useAppSelector} from 'state/hooks';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EmptyLoad from 'components/EmtyLoad/EmptyLoad';
import XIcon from "@mui/icons-material/X";
import TelegramIcon from "@mui/icons-material/Telegram";
import LanguageIcon from "@mui/icons-material/Language";
import Card from '../common/Card/Card'
import {InfoLabelBegin, InfoLabelEnd} from "../common/Typography/InfoLabel";
import useCountDownUp from "../../hooks/useCountDownUp";
import BlueLinkIcon from "../common/icons/CustomLink";
import {fetchDeployedStakingDataAsync} from "../../state/stakingLauncher/stakingSlice";
import {useWeb3React} from "@web3-react/core";
import {useFtmPrice} from "../../hooks/useFTMPrice";
import {fetchDeployedTokensDataAsync} from "../../state/deployedTokens/deployedTokensSlice";
import ExtendedTimer from "../common/TimeComponent/ExtendedTimer";


const PoolName = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '25px',
    lineHeight: '24px',
    maxWidth: '220px',
    whiteSpace: 'break-spaces',
    textAlign: 'center',
    overflow: 'clip',

    [theme.breakpoints.up('xs')]: {
        display: 'block',
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
        whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
        display: 'block',
        whiteSpace: 'break-spaces',
    },
}));


interface PoolCardProps {
    info: any,
    images: any,
    onSelect: (info: any) => void,
    isUpdateInfo?: boolean,

}

const StakingMini: FC<PoolCardProps> = ({ info, images, onSelect, isUpdateInfo }) => {
    const { deployedStakers, size } = useAppSelector((staking) => staking.staking);
    const [preinfo, setPreinfo] = useState<any>({})
    const [rewardTokenImage, setRewardTokenImage] = useState<string>("");
    const [tokenTg, setTokenTg] = useState<string>("");
    const [tokenX, setTokenX] = useState<string>("");
    const [tokenWeb, setTokenWeb] = useState<string>("");
    const [customLinks, setCustomLinks] = useState<any>([]);
    const { account } = useWeb3React();
    const { selectedChainId } = useAppSelector((state) => state.chain);
    const ftmPrice = useFtmPrice(Number(selectedChainId));
    const dispatch = useAppDispatch();

    const [isPoolsStarted, setIsPoolsStarted] = useState<boolean>(false);
    const [isPoolsEnded, setIsPoolsEnded] = useState<boolean>(false);
    const [isShowTime, setIsShowTime] = useState<boolean>(false);
    const [poolStartTime, setPoolStartTime] = useState<Number>(0);
    const [poolEndTime, setPoolEndTime] = useState<Number>(0);
    //const [days, hours, minutes, seconds] = useCountDownUp(Number(isPoolsStarted ? poolEndTime : poolStartTime) * 1000);

    useEffect(() => {
        if( preinfo ){

            setPoolStartTime(preinfo.poolStartTime)
            setPoolEndTime(preinfo.poolEndTime)
            const currentDate = Date.now()
            if( currentDate >= preinfo.poolStartTime ){

                setIsShowTime(true)
                setIsPoolsStarted(true)
            }
            if( currentDate >= preinfo.poolEndTime ){

                setIsShowTime(false)
                setIsPoolsEnded(true)
            }
        }
    }, [preinfo]);

    function checkProtocolAndOpen(url: any) {
        try {
            if (!url.startsWith('http://') && !url.startsWith('https://')) {
                const httpsUrl = 'https://' + url;
                window.open(httpsUrl);
            } else {
                window.open(url);
            }
        } catch (err)
        {
            console.log(err)
        }
    }

    useEffect(
        () => {
          try{
            setPreinfo(deployedStakers[info.index])
          }
          catch{

          }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [deployedStakers, size, info]
    );

    useEffect(() => {
        if( images && preinfo && preinfo.address ){
            try{
                setRewardTokenImage(images[preinfo.address.toLowerCase()]?.imageUrl)
                setTokenTg(images[preinfo.address.toLowerCase()]?.tg)
                setTokenX(images[preinfo.address.toLowerCase()]?.twitter)
                setTokenWeb(images[preinfo.address.toLowerCase()]?.web)
                setCustomLinks(images[preinfo.address.toLowerCase()]?.customLinks);
            }
            catch(err){

            }
        }
    }, [images, preinfo]);


    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = Date.now();
            const difference = preinfo?.poolEndTime * 1000 - now;

            if (difference > 0) {
                const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((difference / 1000 / 60) % 60);
                const seconds = Math.floor((difference / 1000) % 60);

                setTimeLeft({
                    days,
                    hours,
                    minutes,
                    seconds,
                });
            } else {
                setTimeLeft({
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                });
            }
        };

        const timer = setInterval(calculateTimeLeft, 1000);

        return () => clearInterval(timer);
    }, [preinfo]);

    const { days, hours, minutes, seconds } = timeLeft;

    return (
        <>
            <Grid xs={12} md={6} sm={6} lg={4} xl={3} item sx={{borderRadius: '10px', opacity: preinfo?.preinfo ? 1.0 : 1.0}}>
                    <Card onClick={() => {onSelect(preinfo)}}
                          minHeight='100px'  justifyContent={'center'} alignItems='center' display={'flex'} flexDirection='column'
                          sx={{borderRadius: '10px', padding: '15px', position: 'relative',}}>
                        <OpenInNewIcon sx={{width: '25px', height: '25px', position: 'absolute', right: 10, top: 10, fill: "#E9E9E9"}}/>
                        { !preinfo &&
                            <EmptyLoad width='100%' height='100%'/>
                        }
                        <Box display='flex' flexDirection='column' justifyContent={'center'} alignItems='center' gap='10px' sx={{height: '100%', width: '100%', border: '0px solid yellow'}}>
                            <Box display='flex' flexDirection='row'  alignItems='center' gap='10px' sx={{flexDirection: {xs: 'column', md: 'row'}, height: '100%', width: '100%', minHeight:'160px',
                                border: '0px solid green'}}>

                                <Box display='flex' alignItems='center' gap='10px' sx={{minHeight: '160px', minWidth: '160px', border: '0px solid blue'}}>
                                { rewardTokenImage?.length < 0 &&
                                    <Box sx={{width: '140px', height: '140px', border: "1px solid grey"}}>
                                    </Box>
                                }
                                { rewardTokenImage?.length > 0 &&
                                    <img  src={rewardTokenImage}  style={{width: '160px', height: '160px', objectFit: 'cover', borderRadius: '10px'}}/>
                                }
                                </Box>

                                <Box  sx={{display: 'flex', gap: "10px", width: '100%', height: '100%', minHeight: '160px', flexDirection: 'column', justifyContent: 'center',
                                    alignItems: 'center',  border: '0px solid red'}}>

                                    <Box  sx={{display: 'flex',width: '100%',  flexDirection: 'column', justifyContent: 'center',
                                        alignItems: 'center', border: '0px solid red'}}>
                                    <PoolName >
                                        {preinfo?.name}
                                    </PoolName>
                                        </Box>

                                    <Box sx={{border: '0px solid red',display: 'flex', gap: 2, width: '100%', flexDirection: 'row',
                                        alignItems: 'flex-end', justifyContent: 'center', zIndex: 1}}>
                                        {tokenX?.length > 0 &&
                                            <Button sx={{ minWidth: '6px', zIndex: 1}} onClick={(e) => {
                                            e.stopPropagation();
                                            checkProtocolAndOpen(tokenX)
                                        }} >
                                            <XIcon sx={{width: '16px', }}/>
                                        </Button>
                                        }
                                        { tokenTg?.length > 0 &&
                                            <Button sx={{ minWidth: '6px', zIndex: 1}} onClick={(e) => {
                                                e.stopPropagation();
                                                checkProtocolAndOpen(tokenTg)
                                            }} >
                                                <TelegramIcon sx={{width: '25px'}}/>
                                            </Button>
                                        }

                                        {
                                            tokenWeb?.length > 0 &&
                                            <Button sx={{ minWidth: '6px', zIndex: 1}} onClick={(e) => {
                                                e.stopPropagation();
                                                checkProtocolAndOpen(tokenWeb)
                                            }} >
                                                <LanguageIcon sx={{width: '25px', height: '25px'}}/>
                                            </Button>
                                        }
                                    </Box>

                                    <Box sx={{border: '0px solid red',display: 'flex', gap: '4px', width: '100%', flexDirection: 'row',
                                        alignItems: 'center', justifyContent: 'center', zIndex: 1}}>

                                        {customLinks && customLinks?.length > 0 && customLinks[0]?.[1]?.length > 0 && (
                                            <BlueLinkIcon tooltipTitle={customLinks[0]?.[0]}
                                                          onClick={(e) => {
                                                              e.stopPropagation();
                                                              checkProtocolAndOpen(customLinks[0]?.[1]);
                                                          }}/>
                                        )}
                                        {customLinks && customLinks?.length > 0 && customLinks[1]?.[1]?.length > 0 && (
                                            <BlueLinkIcon tooltipTitle={customLinks[1]?.[0]}
                                                          onClick={(e) => {
                                                              e.stopPropagation();
                                                              checkProtocolAndOpen(customLinks[1]?.[1]);
                                                          }}/>
                                        )}
                                    </Box>

                                </Box>
                            </Box>

                            <Box display='flex' mt={1} gap={'5px'} flexDirection='column' sx={{border: '0px solid blue', width: '100%'}}>
                                <Box display='flex' flexDirection='row' justifyContent='space-between' sx={{background: "rgba(6,1,1,0.3)", padding: '5px', borderRadius: '10px'}}>
                                    <InfoLabelBegin>
                                        APR:
                                    </InfoLabelBegin>
                                    <InfoLabelEnd>
                                        {preinfo?.apr > 0 ? preinfo?.apr.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '%' : '--.--'}
                                    </InfoLabelEnd>
                                </Box>
                                <Box display='flex' flexDirection='row' justifyContent='space-between' sx={{background: "rgba(6,1,1,0.3)", padding: '5px', borderRadius: '10px'}}>
                                    <InfoLabelBegin>
                                        Total Pool Rewards:
                                    </InfoLabelBegin>
                                    <InfoLabelEnd>
                                        {preinfo?.totalRewards?.toFixed(2) + ' ' + preinfo?.rewardTokenSymbol
                                        }
                                    </InfoLabelEnd>
                                </Box>
                                <Box display='flex' flexDirection='row' justifyContent='space-between' sx={{background: "rgba(6,1,1,0.3)", padding: '5px', borderRadius: '10px'}}>
                                    <InfoLabelBegin>
                                        Total Staked:
                                    </InfoLabelBegin>
                                    <InfoLabelEnd>
                                        { Number(preinfo?.totalStaked)?.toFixed(2) + ' ' + preinfo?.stakeTokenSymbol  }
                                    </InfoLabelEnd>
                                </Box>
                                <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems={'center'} sx={{background: "rgba(6,1,1,0.3)", padding: '5px', borderRadius: '10px'}}>
                                    <Box justifyContent='center' alignItems='center' display='flex' gap='10px' flexDirection={'row'}>
                                        <InfoLabelBegin>
                                            Status
                                        </InfoLabelBegin>
                                        <Box sx={{
                                            minWidth: '20px',
                                            minHeight: '20px',
                                            maxHeight: "20px",
                                            background: preinfo?.poolEndTime * 1000 - Date.now() > 0 ? 'green' : "red" ,
                                            borderRadius: '10px'
                                        }}/>
                                    </Box>
                                    <Box sx={{
                                        border: '0px solid red',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        position: 'relative',
                                        minWidth: '200px',
                                        justifyContent: 'right',
                                        alignItems: 'center',
                                        paddingRight: '10px',
                                    }}>
                                        {preinfo?.poolEndTime * 1000 - Date.now() > 0  &&
                                            <Typography sx={{
                                                textAlign: 'center',
                                                position: 'absolute',
                                                top: -2,
                                                left: 0
                                            }}>
                                                {'Expires in'}
                                            </Typography>
                                        }

                                        {preinfo?.poolEndTime * 1000 - Date.now() > 0 ? (
                                            <ExtendedTimer days={days} hours={hours} minutes={minutes} seconds={seconds}
                                                           showSymbols={false}/>

                                        ) : <div style={{
                                            display: 'flex',
                                            justifyContent: 'right',
                                            alignItems: 'center',
                                            paddingRight: '12px',
                                        }}> Ended</div>}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
            </Grid>
        </>
    );
};

export default StakingMini;
