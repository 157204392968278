import React from 'react';
import { Box, BoxProps } from '@mui/material';


interface LogoBoxProps extends BoxProps {
    imageUrl?: string;
    imageStyles?: React.CSSProperties;
}

const RouterLogo: React.FC<LogoBoxProps> = ({
                                             imageUrl = "",
                                             imageStyles = {},
                                             sx = {},
                                             ...props
                                         }) => {
    return (
        <Box
            sx={{position: 'absolute',  ...sx}}
            {...props}
        >
            {imageUrl && (
                <img
                    src={imageUrl}
                    style={{
                        width: '40px',
                        height: '40px',
                        objectFit: 'cover',
                        ...imageStyles,
                    }}
                />
            )}
        </Box>
    );
};

export default RouterLogo;
