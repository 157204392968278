import { createSlice } from '@reduxjs/toolkit';
import {
    fetchDeployedTokensGlobalInfo,
    fetchDeployedTokensGlobalInfoByCA,
    fetchDeployedTokensSizeInfo,
    fetchDeployedTokensSizeInfoDB,
    fetchStakerInfoData,
} from './fetchDeployedTokensGlobalInfo';

interface DeployedTokens {
    deployedTokens: {isUserToken: boolean; symbol: string}[];
    size: number;
    sizeDB: number;
    fetchingTokens: { [index: number] : {isLoading: boolean}};
    stakersInfo: { [index: number]: {isUserToken: boolean}}
    stakersPools: { [address: number]: {tokenAddress: string, pools: []}}
    tokenInfoPage: {}
    tokenReloadCount: number;
    isLoading: boolean;
}

const initialState: DeployedTokens = {
  deployedTokens: [],
  size: 0,
  sizeDB: 0,
  fetchingTokens: [],
  stakersInfo: [],
  stakersPools: [],
  tokenInfoPage: {},
  tokenReloadCount: 0,
  isLoading: false,
};

export const deployedTokensSlice = createSlice({
    name: 'deployedTokens',
    initialState,
    reducers: {
        reset: (state) => {
            state.deployedTokens = []
            state.tokenInfoPage = {}
            state.size = 0;
            state.sizeDB = 0;
            state.stakersInfo = [];
            state.stakersPools = [];
            state.tokenReloadCount = 0;
        },
        setFarmGlobalData: (state, action) => {
            const incomingTokens = action.payload.data;



            const existingTokenIds = new Set(state.deployedTokens.map((token: any) => token?.info.tokenAddress));

            const newTokens = incomingTokens.filter((token: any) => !existingTokenIds.has(token?.info.tokenAddress));

            state.deployedTokens = [...newTokens, ...state.deployedTokens];

            state.isLoading = false;
          },
        setTokenInfoPage: (state, action) => {
            state.tokenInfoPage = action.payload.data
        },
        setStakerGlobalData: (state, action) => {
                state.stakersInfo= action.payload.data;
        },

        setStakerPoolsData: (state, action) => {
          if( action.payload.data.tokenAddress.length !== 0 ){
            state.stakersPools[action.payload.data.tokenAddress] = action.payload.data.pools;
          }
        },

        setDeployedTokensSize: (state, action) => {
            state.size = action.payload.data;
        },
        setDeployedTokensSizeDB: (state, action) => {
            state.sizeDB = action.payload.data;
        },
        setTokenReloadCount: (state, action) => {
            state.tokenReloadCount = action.payload.data;
        },

        setUserPresaleInfo: (state, action) => {
        },
        startLoading: (state) => {
            state.isLoading = true;
        },

        finishLoading: (state) => {
            state.isLoading = false;
        },
    },
});

export const { reset, setFarmGlobalData, setUserPresaleInfo, setDeployedTokensSize, setStakerGlobalData, setStakerPoolsData,setTokenReloadCount, setTokenInfoPage, setDeployedTokensSizeDB, startLoading, finishLoading } = deployedTokensSlice.actions;

export const resetDataTokens = () => async (dispatch: any): Promise<void> => { 
  dispatch( reset( ))
}
export const setTokenReloadCountAsync = (count: number) => async (dispatch: any): Promise<void> => { 
  dispatch( setTokenReloadCount( {
      data: count
  }))
}
export const fetchDeployedTokensDataAsync =
  (chainId: number, account: string, indexes?: [number], ftmPrice?: number) =>
  async (dispatch: any): Promise<void> => {

      try {
          dispatch(startLoading());
          const { data } = await fetchDeployedTokensGlobalInfo(chainId, account, ftmPrice);

          dispatch(
              setFarmGlobalData({
                  data,
              })
          );
      } catch (error) {
          console.error('Error fetching tokens:', error);
      } finally {
          dispatch(finishLoading());
      }
  };

export const fetchDeployedTokensSizeAsync =
  (chainId: number, deployerAddresses: string[]) =>
  async (dispatch: any): Promise<void> => {
    const { data } = await fetchDeployedTokensSizeInfo(chainId, deployerAddresses);

    dispatch(
      setDeployedTokensSize({
        data,
      })
    );
  };

  export const fetchDeployedTokensSizeDBAsync =
  (chainId: number) =>
  async (dispatch: any): Promise<void> => {
    const { data } = await fetchDeployedTokensSizeInfoDB(chainId);

    dispatch(
      setDeployedTokensSizeDB({
        data,
      })
    );
  };


  export const fetchStakerInfoAsync =
  (chainId: number, account: string, ftmPrice: number, info: any) =>
  async (dispatch: any): Promise<void> => {
    const { data } = await fetchStakerInfoData(chainId, account, ftmPrice, info);
    dispatch(
      setStakerPoolsData({
        data,
      })
    );
  };
  export const fetchDeployedTokensDataByCAAsync =
  (chainId: number, account: string, address: string, ftmPrice: number) =>
  async (dispatch: any): Promise<void> => {
    const { data } = await fetchDeployedTokensGlobalInfoByCA(chainId, account, address, ftmPrice);
    
    dispatch(
      setTokenInfoPage({
        data,
      })
    );
  };


  
export default deployedTokensSlice.reducer;
