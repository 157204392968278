import { FC } from 'react';
import { Button, ButtonProps, styled } from '@mui/material';

const StyledButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Prompt',
  width: '100%',
  height: '100%',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '21px',
  padding: '8px 19px',
  whiteSpace: 'nowrap',
  '&.Mui-disabled': {
    borderColor: 'black',
  },
  '&.active': {
    background: 'linear-gradient(180deg, rgb(20, 80, 140) 0%, rgb(30, 50, 90) 100%)',
    boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.4)',
    opacity: '0.7'
  },
}));

interface ContainedButtonProps extends ButtonProps {
  disabled?: boolean;
  colorType?: string;
  isActive?: boolean;
}

const ContainedButton: FC<ContainedButtonProps> = ({ sx={}, children, disabled, isActive=false, onClick, ...props }) => (
  <StyledButton variant={'outlined'} disabled={disabled} onClick={onClick} className={isActive ? 'active' : ''} {...props} sx={{...sx}} >
    <span style={{ color: disabled ? 'lightgray' : 'inherit', border: '0px solid green' }}>{children}</span>
  </StyledButton>
);

export { ContainedButton };
