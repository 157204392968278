import { FC, useEffect, useRef, useState } from 'react';
import { Box, Grid, styled,  Typography,  useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { getBalanceInEther,  } from 'utils';
import { useWeb3React } from '@web3-react/core';
import { useFtmPrice } from 'hooks/useFTMPrice';
import { getRouters } from 'utils/deployTokens';
import { fetchDeployedPresalesDataAsync,  } from 'state/actions';
import EmptyLoad from 'components/EmtyLoad/EmptyLoad';
import useCountDownUp from 'hooks/useCountDownUp';
import ProgressBar from 'components/common/ ProgressBar/ ProgressBar';
import PresaleModal from 'components/Modal/PresaleModal/PresaleModal';
import ContainedInfoBox from "../common/InfoBox/ContainedInfoBox";
import LogoBox from "../common/LogoContainer/LogoBox";
import Card from '../common/Card/Card'
import RouterLogo from "../common/LogoContainer/RouterLogo";


const PoolName = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '700',
  cursor: 'pointer',
  fontSize: '25px',
  lineHeight: '24px',
  maxWidth: '300px',
  overflow: 'clip',
  whiteSpace: 'break-spaces',
  textAlign: 'left',

  [theme.breakpoints.up('xs')]: {
    display: 'block',
    whiteSpace: 'nowrap',
  },
  [theme.breakpoints.up('sm')]: {
    whiteSpace: 'break-spaces',
  },
  [theme.breakpoints.up('md')]: {
    display: 'block',
    whiteSpace: 'break-spaces',
  },
}));

const PoolSymbol = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '500',
    cursor: 'pointer',
    fontSize: '20px',
    lineHeight: '24px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      whiteSpace: 'break-spaces',
    },
  }));



const PoolName2 = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      textAlign: 'left',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left'
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'left'
    },
  }));


interface PoolCardProps {
    preinfo: any,
    images: any
}

const PresaleMini: FC<PoolCardProps> = ({ preinfo, images }) => {
  const { selectedChainId, } = useAppSelector((state) => state.chain);

  const { pendingTxHash } = useAppSelector((state) => state.modal);
  const pendingTxHashRef: { current: string | undefined } = useRef();
  pendingTxHashRef.current = pendingTxHash;
  const ftmPrice = useFtmPrice(Number(selectedChainId));
  const [tokenPrice, setTokenPrice] = useState<string>("--");
  const [amm, setAmm] = useState<string>("--");
  const [tokenImage, setTokenImage] = useState<string>("");
  const [tokenDescription, setTokenDescription] = useState<string>("");
  const [tokenTg, setTokenTg] = useState<string>("");
  const [tokenX, setTokenX] = useState<string>("");
  const [tokenWeb, setTokenWeb] = useState<string>("");
  const [customLinks, setCustomLinks] = useState<any>([]);

  const [lockedLps, setLockedLps] = useState<Number>(0);
  const [info, setInfo] = useState<any>({});

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [time, setTime] = useState(0);
  const [isLaunchStarted, setLaunchStarted] = useState(false);
  const [isLaunchFinished, setLaunchFinished] = useState(false);

  const [days, hours, minutes, seconds] = useCountDownUp(Number(time.toFixed(0)) * 1000);

  const dispatch = useAppDispatch();
  const { account } = useWeb3React();
  const { deployedPresales, size } = useAppSelector((presale) => presale.presale);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lastUpdateTime, setLastUpdateTime] = useState<number>(new Date().getTime() / 1000);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if( days < 0 && minutes < 0 && hours < 0 && seconds < 0 && !isLaunchStarted )
    {
      dispatch(fetchDeployedPresalesDataAsync(Number(selectedChainId),account ? account : "", [preinfo.index], ftmPrice))
    }
    if( days < 0 && minutes < 0 && hours < 0 && seconds < 0 && isLaunchStarted && !isLaunchFinished)
    {
      dispatch(fetchDeployedPresalesDataAsync(Number(selectedChainId),account ? account : "", [preinfo.index], ftmPrice))
    }

  }, [days, minutes, hours, seconds, selectedChainId]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter + 1);
        dispatch(fetchDeployedPresalesDataAsync(Number(selectedChainId), account ? account : "", [preinfo.index], ftmPrice))
        setLastUpdateTime(new Date().getTime() / 1000)
    }, 30000);
    return () => clearInterval(interval);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, selectedChainId])

  useEffect(() => {
    if( ftmPrice === 0 )
        return
    if( deployedPresales[preinfo.index] ){
        setInfo(deployedPresales[preinfo.index])
        return;
    }
    setInfo({})
    dispatch(fetchDeployedPresalesDataAsync(Number(selectedChainId), account ? account : "", [preinfo.index], ftmPrice))
  }, [preinfo, ftmPrice, selectedChainId]);

  useEffect(() => {
    if( deployedPresales[preinfo.index] ){
        setInfo(deployedPresales[preinfo.index])
        setIsLoading(false)
        setCounter(counter + 1)
    }
  }, [deployedPresales]);
  
  const truncate = (str: string, n: number) => {
    return str?.length > n ? `${str.substr(0, n ) } ... ${str.substr(str.length - n, n) }`: str;
    };

    useEffect(() => {
        if( images && info ){
            if( info?.presaleAddress )
            {
              setTokenImage(images[info?.presaleAddress?.toLowerCase()]?.imageUrl)
              setTokenDescription(images[info?.presaleAddress?.toLowerCase()]?.description)
              setTokenTg(images[info?.presaleAddress?.toLowerCase()]?.tg)
              setTokenX(images[info?.presaleAddress?.toLowerCase()]?.twitter)
              setTokenWeb(images[info?.presaleAddress?.toLowerCase()]?.web)
              setCustomLinks(images[info?.presaleAddress?.toLowerCase()]?.customLinks)
            }
        }
    }, [images, info, lastUpdateTime]);

  useEffect(() => {
    if( ftmPrice && info ){
        setTokenPrice((info?.tokenPriceFTM * ftmPrice).toFixed(info?.tokenPriceFTM < 0.001 ? 6 : 3))
    }
  }, [ftmPrice, info]);

  useEffect(() => {
    if( info ){ 
      //TIME SELECT
      const startIn =  Number(info?.info?.presaleInfo.presaleStartTime)
      const currentTime = new Date().getTime() / 1000
      const presaleDuration = Number(info?.info?.presaleInfo.presaleDuration)
      const endPool = Number(startIn + presaleDuration)

      if( currentTime > startIn ){
         const duration =  startIn + presaleDuration;
        setTime(Number(duration.toFixed(0)))
        setLaunchStarted(true)
        if( endPool < currentTime ){
          setLaunchFinished(true)
        }
      }
      else{
        setTime(startIn)
      }
      const routers = getRouters(Number(selectedChainId))
      setLockedLps(Number(info?.lockLps))
      for( let i = 0; i < routers.length; i+=1 )
      {
        if( routers[i].address === info?.info?.router ){
            setAmm(routers[i].name)
            return
        }
      }
      if( Number(info?.raised) >= Number(getBalanceInEther(info?.info?.presaleInfo?.hardCap)) ){
        setLaunchStarted(true)
        setLaunchFinished(true)
        return;
      }
    }
  }, [info, lastUpdateTime, selectedChainId]);

  const calculateMCapAtLaunch = (): string => {
    let mcap = 0
    if( info ){
       const totalSupply = info?.info?.tokenInfo?.totalSupply ? getBalanceInEther(info?.info?.tokenInfo?.totalSupply) : 0
       const priceAtLaunch = info?.info?.tokenInfo?.tokenLaunchPrice ? getBalanceInEther(info?.info?.tokenInfo?.tokenLaunchPrice) : 0
       mcap = Number(totalSupply) * Number(priceAtLaunch);
    }
    return mcap.toString();
}

  const onCloseModal = () => {
    setIsExpanded(false)
  };

    function getHardCap() : string {
        const presaleTokenCount = parseFloat(Number(getBalanceInEther(info?.info?.presaleInfo?.tokensForPresale)).toString());
        const presalePrice = parseFloat(Number(getBalanceInEther(info?.info?.presaleInfo?.presalePrice)).toString())
        return (presaleTokenCount * presalePrice).toString();
    }
    return (
    <> 
        {isExpanded && <PresaleModal tokenSocials={{tokenDescription: tokenDescription, tokenTg: tokenTg, tokenX: tokenX, tokenWeb: tokenWeb, customLinks: customLinks}} info={info} onClose={onCloseModal} tokenImage={tokenImage} tokenPrice={tokenPrice}/>}
        <Grid xs={12} md={6} sm={6} lg={4} xl={3} item   sx={{borderRadius: '10px', border: '0px solid red', opacity: info?.info ? 1.0 : 1.0, position: 'relative', }}>
            <Card  onClick={() => {setIsExpanded( info?.info ? !isExpanded : false)}} minHeight='100px' justifyContent={'space-between'} alignItems='center' display={'flex'}
                sx={{flexDirection: {md: 'column'}, gap: {xs:'0px', md:'0px'}}}>
                 { info && info?.currentRouterLogo?.length > 0 &&
                 <>
                     <RouterLogo imageUrl={info?.currentRouterLogo} sx={{right: {xs:0, md: 20}, top: '35%' }}/>
                 </>
                }
                { !info.info &&
                    <EmptyLoad width='100%' height='100%'/>
                }

                    <LogoBox imageUrl={tokenImage} sx={{width: '98%', height: '120px', border: '0px solid red'}}/>

                <Box mt={2} mb={2} display='flex' gap={'5px'} flexDirection='column' sx={{border: '0px solid blue', width: '100%', paddingLeft: '15px', paddingRight: '15px'}}>
                    <PoolName >
                        {info?.info?.tokenInfo.tokenName ? ((info?.info?.tokenInfo.tokenName.length ) * 13) > 300 ? truncate(info?.info?.tokenInfo.tokenName, 6) : info?.info?.tokenInfo.tokenName : ''}
                    </PoolName>
                    <PoolSymbol >
                        {info?.info?.tokenInfo.tokenSymbol ? info?.info?.tokenInfo.tokenSymbol.length > 15 ? truncate(info?.info?.tokenInfo.tokenSymbol, 6) : info?.info?.tokenInfo.tokenSymbol : ''}
                    </PoolSymbol>
                    <Box mt={1} display='flex' gap='10px' flexDirection='column'>
                        <ContainedInfoBox>
                            <PoolName2>
                                Status
                            </PoolName2>

                            <Box display='flex' gap={'10px'} alignItems='center'>
                                <Box sx={{
                                    minWidth: '20px',
                                    minHeight: '20px',
                                    maxHeight: "20px",
                                    background: isLaunchStarted && !isLaunchFinished ? 'green' : isLaunchFinished ? 'red' : "orange",
                                    borderRadius: '10px'
                                }}/>
                                {!isLaunchFinished &&
                                    <>
                                        <PoolName2 sx={{border: '0px solid red'}}>
                                            {isLaunchStarted ? 'Ends in' : 'Starts in'} {`${days > 0 ? days <= 9 ? "0" + days + " /" : days + " /" : ""}  ${hours <= 9 ? "0" + hours : hours}:${minutes <= 9 ? "0" + minutes : minutes}:${seconds <= 9 ? "0" + seconds : seconds}`}
                                        </PoolName2>
                                    </>
                                }
                                {isLaunchFinished &&
                                    <PoolName2>Ended</PoolName2>
                                }
                            </Box>
                        </ContainedInfoBox>

                        <ContainedInfoBox>
                            <PoolName2>
                                Participants
                            </PoolName2>
                            <PoolName2>
                                {info ? info?.participants : '-.-'}
                            </PoolName2>
                        </ContainedInfoBox>
                        <ContainedInfoBox>
                            <PoolName2>
                                MCap at launch
                            </PoolName2>
                            <PoolName2>
                                {`${calculateMCapAtLaunch().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} FTM`}
                            </PoolName2>
                        </ContainedInfoBox>

                        <ProgressBar midPoint={info?.raised} startPoint={Number(getBalanceInEther(info?.info?.presaleInfo?.softCap) * 100 / Number(getHardCap()))}
                         endPoint={Number(getHardCap())}/>
                         <Box display='flex' justifyContent='center' sx={{width: '100%'}}>
                         <PoolName2 textAlign='center'>
                            Soft Cap: ( {Number(getBalanceInEther(info?.info?.presaleInfo?.softCap))} FTM )
                          </PoolName2>
                         </Box>
                    </Box>
                </Box>
            </Card>
        </Grid>
    </>
  );
};

export default PresaleMini;
