import React from 'react';
import { Box, BoxProps } from '@mui/material';

interface ContainedInfoBoxProps extends BoxProps {
    children: React.ReactNode;
}

const ContainedInfoBox: React.FC<ContainedInfoBoxProps> = ({ children, sx = {}, ...props }) => {
    return (
        <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{
                width: '100%',
                background: 'rgba(6,1,1,0.3)',
                padding: '5px',
                borderRadius: '10px',
                ...sx,
            }}
            {...props}
        >
            {children}
        </Box>
    );
};

export default ContainedInfoBox;
