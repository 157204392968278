import { FC, useEffect, useState } from 'react';
import { Box,  Tooltip, Typography, styled } from '@mui/material';

const InfoCardLabel = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '400',
    cursor: 'pointer',
    textWrap: 'wrap',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
      lineHeight: '18px',
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      lineHeight: '16px',
      textAlign: 'right',
    },
  }));  

interface Props {
    midPoint: number,
    endPoint: number,
    startPoint: number
}

const ProgressBar: FC<Props> = ({ midPoint, endPoint, startPoint }) => {
    const [precent, setPercent] = useState<number>(0);
    
    useEffect(() => {
        const percent = midPoint * 100 / endPoint
        setPercent(percent)
    }, [midPoint, endPoint]);
  return (
    <Box mt={0} display='flex' flexDirection='column' justifyContent='center' sx={{minHeight: '35px', width: '100%', background: 'rgba(6, 1,1, 0.3)', borderRadius: '10px', position: 'relative'}}>
        <Box display='flex' sx={{height: '100%', minHeight: '30px', width: `${precent}%`, borderRadius: '10px', position: 'absolute', background: 'rgba(79, 181, 83, 0.3)'}}/>
        <Tooltip title={`${startPoint * endPoint / 100} FTM`}>
          <Box display='flex' sx={{height: '100%', minHeight: '30px', width: '5px', borderRadius: '0px',  left:`${startPoint <= 100 ? startPoint : 100}%`, position: 'absolute', background: 'red'}}/>
        </Tooltip>
        <Box  display='flex' alignItems='center' justifyContent={'space-between'} sx={{width: '100%', height: '100%', minHeight: '30px', paddingLeft: '10px', paddingRight: '10px'}}>
            <InfoCardLabel>
                Presale Progress
            </InfoCardLabel>
            <InfoCardLabel>
                {midPoint} / {endPoint.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} FTM
            </InfoCardLabel>
        </Box>
    </Box>
  );
};

export default ProgressBar;
