import { FC, useState } from 'react';
import { Box, TextField, Typography, styled } from '@mui/material';
import {ContainedTextField} from "../../common/Input/ContainedTextField";
import MinTotalSupplyFormula from "./MinTotalSupplyFormula";

const InfoCardLabel = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    cursor: 'pointer',
    textWrap: 'wrap',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
      lineHeight: '18px',
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      lineHeight: '18px',
      textAlign: 'right',
    },
  }));  

  const ValueTitle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
      lineHeight: '18px',
      img: {
        display: 'none',
      },
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
      lineHeight: '18px',
      img: {
        display: 'block',
      },
    },
  }));

interface Props {
    hardCap: string;
    tokenLaunchPrice: string
    totalSupply: string
    presaleTokenPrice: string
    tokensForPresale: string;
    isCheckErrors: boolean
    isTotalSupplyError: boolean
    postPresaleSectionChanges: (launchPrice: string) => void
    comparsionChange: ( comparsion: boolean) => boolean
}

const PostPresaleSection: FC<Props> = ({ hardCap, tokenLaunchPrice, totalSupply, presaleTokenPrice, tokensForPresale, postPresaleSectionChanges, comparsionChange, isCheckErrors, isTotalSupplyError  }) => {
    const [launchPrice, setLaunchPrice] = useState<string>('');

    function isNumeric(n: any) {
        return !Number.isNaN(Number(n)) && Number.isFinite(Number(n));
      }

    const onChangeLaunchPrice = (e: any) => {
        if (!isNumeric(e.target.value)) return;
        const newAmount = e.target.value;
        setLaunchPrice(newAmount);
        postPresaleSectionChanges(newAmount)

    };
  return (
    <>
        <InfoCardLabel mt={2}>
          Token Launch Specs
        </InfoCardLabel>
        <Box mt={1} display='flex' flexDirection='column' justifyContent='center' alignItems='center' sx={{border: '0px solid green'}}>

            <Box  gap='20px' display='flex' flexDirection='row' justifyContent='center' alignItems='center' sx={{border: '0px solid blue', flexDirection: {xs:'column', md:'row'}}}>
              <Box  display='flex' sx={{
                      border: '0px solid grey',
                      }}>
                      <ContainedTextField variant={'outlined'} placeholder='Launch Price' disabled={false} required={true}
                      onChange={onChangeLaunchPrice}  autoComplete='off'
                      error={launchPrice.length !== 0 ? Number(launchPrice) <  Number(tokenLaunchPrice) : false}
                      label={launchPrice.length !== 0 && Number(launchPrice) <  Number(tokenLaunchPrice) ? "Must Be Greater Than: "+tokenLaunchPrice : "Token Launch Price"} value={launchPrice} />
              </Box>
                <Box  display='flex' flexDirection='column' alignItems='center' justifyContent={'center'} sx={{
                        border: '0px solid grey',
                        borderRadius: '10px',
                        background: "rgba(6, 1,1, 0.3)",
                        minHeight: '53.13px',
                        minWidth: '220px',
                        maxWidth: '220px',
                        }}>
                    <ValueTitle> MCap at Launch: </ValueTitle>
                    <ValueTitle> {totalSupply ? Number(totalSupply) * Number(launchPrice) : 0} FTM </ValueTitle>
                </Box>
            </Box>
            <MinTotalSupplyFormula
                hardCap={hardCap}
                tokenLaunchPrice={tokenLaunchPrice}
                totalSupply={totalSupply}
                tokensForPresale={tokensForPresale}
                onComparsionChange={comparsionChange}/>
        </Box>
    </>
  );
};

export default PostPresaleSection;
