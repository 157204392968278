import { FC,  useState } from 'react';
import { Box,  Radio, TextField, Typography, styled } from '@mui/material';
import DataSelect from 'components/common/Select/DataSelect';
import { getRouters, getTokenDeployerInfo } from 'utils/deployTokens';
import ImageUploadBox from 'components/common/UploadBox/ImapeUploadBox';
import { ImageType } from 'react-images-uploading';
import { useAppSelector } from 'state/hooks';
import DescriptionSplit from "../../common/Typography/DescriptionSplit";
import {ContainedTextField} from "../../common/Input/ContainedTextField";

const InfoCardLabel = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    cursor: 'pointer',
    textWrap: 'wrap',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
      lineHeight: '18px',
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      lineHeight: '18px',
      textAlign: 'right',
    },
  }));
  const ValueTitle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
      lineHeight: '18px',
      img: {
        display: 'none',
      },
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
      lineHeight: '18px',
      img: {
        display: 'block',
      },
    },
  }));

  const LenTitle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    color: 'rgba(255,255,255,0.7)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '10px',
      lineHeight: '12px',
      img: {
        display: 'none',
      },
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '10px',
      lineHeight: '12px',
      img: {
        display: 'block',
      },
    },
  }));

interface Props {
  isCheckErrors: boolean;
    liquiditySectionChanges: (ammm: any, lockDuration: string, isBurnChecked: boolean,
        isLiquidityChecked: boolean, isLiquidityCreateChecked: boolean, images: ImageType[], isElock: boolean, 
        description: string, tokenTg: string, tokenX: string, tokenDiscord: string, tokenWeb: string
    ) => void
}

const LiquiditySection: FC<Props> = ({ liquiditySectionChanges, isCheckErrors }) => {
  const { selectedChainId,  } = useAppSelector((state) => state.chain);

    const [isLiquidityChecked, setChecked] = useState(true);
    const [isBurnChecked, setBurnChecked] = useState(false);
    const [isLiquidityCreateChecked, setLiquidityCreateChecked] = useState(false);
    const [ammm, setAmm] = useState<any>(undefined);
    const [amountDays, setAmountDays] = useState<string>('');
    const [isAmountDaysError, setAmountDaysError] = useState(false);
    const [imageUploaded, setImageUploaded] = useState(false);
    const [images, setImages] = useState<ImageType[]>([]);
    const [isELock, setIsElock] = useState(false);
    const tokenDeployerInfo = getTokenDeployerInfo(Number(selectedChainId));
    const [tokenDescrtiption, setTokenDescription] = useState<string>('');
    const [tokenTg, setTokenTg] = useState<string>('');
    const [tokenX, setTokenX] = useState<string>('');
    const [tokenDiscord, setTokenDiscord] = useState<string>('');
    const [tokenWeb, setTokenWeb] = useState<string>('');

    const onChangeDescription = (e: any) => {
      const newAmount = e.target.value;
      if( newAmount.length > 250 )
          return
      setTokenDescription(newAmount);
      liquiditySectionChanges(ammm, amountDays, isBurnChecked, isLiquidityChecked, isLiquidityCreateChecked, images, isELock,
         newAmount, tokenTg, tokenX, tokenDiscord, tokenWeb)
  };
    const onChangeTg = (e: any) => {
      const newAmount = e.target.value;
      if( newAmount.length > 250 )
          return
      setTokenTg(newAmount);
      liquiditySectionChanges(ammm, amountDays, isBurnChecked, isLiquidityChecked, isLiquidityCreateChecked, images,
         isELock, tokenDescrtiption, newAmount, tokenX, tokenDiscord, tokenWeb)
  };
  const onChangeX = (e: any) => {
      const newAmount = e.target.value;
      if( newAmount.length > 250 )
          return
      setTokenX(newAmount);
      liquiditySectionChanges(ammm, amountDays, isBurnChecked, isLiquidityChecked, isLiquidityCreateChecked, images,
        isELock, tokenDescrtiption, tokenTg, newAmount, tokenDiscord, tokenWeb)
  };

  const onChangeWeb = (e: any) => {
    const newAmount = e.target.value;
    if( newAmount.length > 250 )
        return
    setTokenWeb(newAmount);
    liquiditySectionChanges(ammm, amountDays, isBurnChecked, isLiquidityChecked, isLiquidityCreateChecked, images,
      isELock, tokenDescrtiption, tokenTg, tokenX, tokenDiscord, newAmount)
  };
    const imageUpload = (imageList: any) => {
        setImages(imageList)
        liquiditySectionChanges(ammm, amountDays, isBurnChecked, isLiquidityChecked, isLiquidityCreateChecked, imageList, isELock, tokenDescrtiption, 
          tokenTg, tokenX, tokenDiscord, tokenWeb
        )
      };

    function isNumeric(n: any) {
        return !Number.isNaN(Number(n)) && Number.isFinite(Number(n));
      }

    const onChangeAmountDays= (e: any) => {
        if (!isNumeric(e.target.value)) return;
        const newAmount = e.target.value;
    
        if( Number(newAmount) < 15 ){
          setAmountDaysError(true)
        }
        else 
          setAmountDaysError(false)
    
        setAmountDays(newAmount);
        liquiditySectionChanges(ammm, newAmount, isBurnChecked, isLiquidityChecked, isLiquidityCreateChecked, images, isELock, tokenDescrtiption,
          tokenTg, tokenX, tokenDiscord, tokenWeb
        )
      };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(true);
        setBurnChecked(false)
        setLiquidityCreateChecked(false)
        setIsElock(false)
        liquiditySectionChanges(ammm, amountDays, false, true, false, images, isELock, tokenDescrtiption,
          tokenTg, tokenX, tokenDiscord, tokenWeb
        )
      };
    
      const handleCreateLpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(false);
        setBurnChecked(false)
        setLiquidityCreateChecked(true)
        setIsElock(false)
        liquiditySectionChanges(ammm, amountDays, false, false, true, images, isELock, tokenDescrtiption,
          tokenTg, tokenX, tokenDiscord, tokenWeb
        )
      };
    
      const handleBurnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBurnChecked(true); //event.target.checked
        setChecked(false);
        setLiquidityCreateChecked(false)
        setIsElock(false)
        liquiditySectionChanges(ammm, amountDays, true, false, false, images,isELock, tokenDescrtiption,
          tokenTg, tokenX, tokenDiscord, tokenWeb
        )
      };
      const handleChangeElock = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBurnChecked(false); //event.target.checked
        setIsElock(true)
        setChecked(false);
        setLiquidityCreateChecked(false)
        liquiditySectionChanges(ammm, amountDays, true, false, false, images, event.target.checked, tokenDescrtiption,
          tokenTg, tokenX, tokenDiscord, tokenWeb
        )
      };

      const onChangeChain = (option: any) => {
        const routers = getRouters(Number(selectedChainId))
        for( let i = 0; i < routers.length; i+=1 )
        {
          if( routers[i].value === option ){
              setAmm(routers[i])
              liquiditySectionChanges(routers[i], amountDays, isBurnChecked, isLiquidityChecked, isLiquidityCreateChecked, images, isELock, tokenDescrtiption,
                tokenTg, tokenX, tokenDiscord, tokenWeb
              )
              return
          }
        }
        liquiditySectionChanges(undefined, amountDays, isBurnChecked, isLiquidityChecked, isLiquidityCreateChecked, images, isELock, tokenDescrtiption,
          tokenTg, tokenX, tokenDiscord, tokenWeb
        )
        setAmm(undefined)
        setChainSelectOption(option)
      };


      // TODO USE EFFECT?
  const [chainSelectOption, setChainSelectOption] = useState<any>("Spooky");


  const getAmmSelectOptions = () => {
    const finOptions = []
    const ammOptions = getRouters(Number(selectedChainId));
    for( let i = 0; i < ammOptions.length; i++ ){
       // need for skip beethoven
      if (ammOptions[i].value === "6") {
          continue
      }
      if( isELock && ammOptions[i].value === "5"  ){
          finOptions.push(ammOptions[i])
      }
      else if( !isELock && !isLiquidityChecked ) {
          finOptions.push(ammOptions[i])
      }
      if( !isELock && isLiquidityChecked ) {
        if( ammOptions[i].value !== "5" )
          finOptions.push(ammOptions[i])
      }
    }
    return finOptions
  };

    const getNotionText = () => {
        if (isLiquidityCreateChecked ){
            return tokenDeployerInfo ? tokenDeployerInfo?.noLockDescription : []
        }
        if( isBurnChecked ){
            return tokenDeployerInfo ? tokenDeployerInfo?.sccDescription : []
        }
        if( isLiquidityChecked ){
            return tokenDeployerInfo ? tokenDeployerInfo?.lockDescription : []
        }
        if( isELock )
            return tokenDeployerInfo ? tokenDeployerInfo?.eLockDescription : []

        return tokenDeployerInfo ? tokenDeployerInfo?.tokenDescription : []
    };

  const ammSelectOptions = getAmmSelectOptions()

  return (
    <>
        <Box mt={2} display='flex' flexDirection='column' justifyContent='center' alignItems='center' sx={{border: '0px solid blue'}}>
            <InfoCardLabel mt={1}>
                Liquidity Options
            </InfoCardLabel>
            <Box mt={2} display='flex'>
                <Box display='flex' > 
                        <Radio checked={isBurnChecked}
                            onChange={handleBurnChange}/>
                        <ValueTitle>
                        SCC
                        </ValueTitle>
                      </Box>
                      <Box display='flex'>
                      <Radio  
                      checked={isELock}
                      onChange={handleChangeElock}/>
                      <ValueTitle>
                        ELock
                      </ValueTitle>
                    </Box>
                      <Box display='flex'>
                        <Radio  
                        checked={isLiquidityChecked}
                        onChange={handleChange}/>
                        <ValueTitle>
                          Liquidity Lock
                        </ValueTitle>
                      </Box>
                      <Box display='flex'>
                        <Radio  
                        checked={isLiquidityCreateChecked}
                        onChange={handleCreateLpChange}/>
                        <ValueTitle>
                          No Lock
                        </ValueTitle>
                      </Box>
                </Box>
                <Box display='flex' flexDirection='column' justifyContent='center' sx={{width: '100%'}}>
                  { !isELock &&
                      <Box display='flex' flexDirection='row'>
                      <Box mb={1} mt={1} display='flex' justifyContent='center' sx={{width: '100%'}}>
                          <DataSelect label='' width='200px' isError={false}  onSelect={onChangeChain} options={ammSelectOptions} value={chainSelectOption} />
                      </Box>
                  </Box>                
                  }
                </Box>
                { isELock && 
                        <Box display='flex' mt={2} mb={1} justifyContent='center' alignItems='center' textAlign='center'> 
                        <ValueTitle >
                           EqualizerDex (V2)
                        </ValueTitle>
                      </Box>                        
                      }

            <Box display='flex' flexDirection='column' sx={{
                border: '1px solid #1B1E29',
                background: '#1B1E29',
                padding: '10px',
                width: '100%',
                borderRadius: '10px',
                height: '100%'
            }}>
                <DescriptionSplit descriptionArray={getNotionText()}/>
            </Box>

                { (isLiquidityChecked || isELock)  && 
                                  <Box display='flex' mt={0} mb={0} sx={{
                                  border: '0px solid grey',
                                  padding: '10px',
                                  width: '100%',
                                  justifyContent: 'center',
                                  }}>
                                  <ContainedTextField variant={'outlined'} placeholder='Lock Duration' required={true} onChange={onChangeAmountDays} autoComplete='off'
                                                      error={ isAmountDaysError ? amountDays.length !== 0 &&  Number(amountDays) < ( isELock ? 0 :  15 )  : false}
                                                      label={ isAmountDaysError && amountDays.length !== 0 &&  Number(amountDays) < ( isELock ? 0 :  15 )  ? "Lock Duration minimum 15 days" : "Lock Duration (days)"}
                                                      value={amountDays} />
                              </Box>
                }
              </Box>
    </>
  );
};

export default LiquiditySection;
