import { FC,} from 'react';


interface Props {
    days:number;
    hours:number;
    minutes:number;
    seconds:number;
    showSymbols?:boolean;
}

const ExtendedTimer: FC<Props>  = ({days, hours, minutes, seconds, showSymbols = true}) => {

    return (
        <div style={{display: 'flex', justifyContent: 'right', alignItems: 'right'}}>
            {days > 0 && (
                <div style={{margin: '0 5px', textAlign: 'center'}}>
                    <div>{days <= 9 ? "0" + days : days} /</div>
                    {showSymbols && <div style={{fontSize: '12px'}}>d</div>}
                </div>
            )}
            <div style={{textAlign: 'center'}}>
                <div>{hours <= 9 ? "0" + hours : hours}:</div>
                {showSymbols &&<div style={{fontSize: '12px'}}>h</div>}
            </div>
            <div style={{textAlign: 'center'}}>
                <div>{minutes <= 9 ? "0" + minutes : minutes}:</div>
                {showSymbols && <div style={{fontSize: '12px'}}>m</div>}
            </div>
            <div style={{textAlign: 'center'}}>
                <div>{seconds <= 9 ? "0" + seconds : seconds}</div>
                {showSymbols &&<div style={{fontSize: '12px'}}>s</div>}
            </div>
        </div>
    );
}

export default ExtendedTimer;