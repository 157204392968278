import { FC, useEffect,  useState } from 'react';
import { Box,  useMediaQuery,  styled, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { ethers } from 'ethers';
import { icons } from 'config/constants/assets';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import StakingTabPanel from 'components/ListedStaking/StakingTabPanel';
import CountDown from 'components/Earn/Stakings/CountDown';
import { useStakeEstimateInfo } from 'hooks/useStakeLauncherInfo';
import { useAppSelector } from 'state/hooks';
import { useFtmPrice } from 'hooks/useFTMPrice';
import { useWeb3React } from '@web3-react/core';
import useCountDown from 'hooks/useCountDown';
import { getBalanceInEther } from 'utils/formatBalance';
import {InfoLabelBegin, InfoLabelEnd} from "../common/Typography/InfoLabel";


const Container = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    marginTop: '10px',
    background: "rgba(42,49,57, 0.4)",
    border: '1px solid #5E6980',
    display: 'flex',
    maxWidth: '900px',
    borderRadius: '10px',
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
        padding: '0px 16px',
    },
    [theme.breakpoints.up('md')]: {
        padding: '20px 32px',
    },
}));

const PoolName2 = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left',

    [theme.breakpoints.up('xs')]: {
        display: 'block',
        textAlign: 'left',
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
        textAlign: 'left'
    },
    [theme.breakpoints.up('md')]: {
        textAlign: 'left'
    },
}));


const PoolAprLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '16px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',
    width: '100%',
    marginLeft: '10px',
    [theme.breakpoints.up('xs')]: {
        display: 'block',
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
        whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
        display: 'block',
        textAlign: 'center',
        whiteSpace: 'break-spaces',
    },
}));

const PoolAprValue = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '16px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',
    width: '100%',
    marginLeft: '10px',
    [theme.breakpoints.up('xs')]: {
        display: 'block',
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
        whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
        display: 'block',
        textAlign: 'center',
        whiteSpace: 'break-spaces',
    },
}));


// modal body
const ModalBody = styled(Box)(({theme}) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    gap: '20px',
    [theme.breakpoints.up('xs')]: {
        flexDirection: 'column',
        padding: '10px',
    },
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        padding: '0px',
    },
}));

interface Props {
    tokenInfo: any,
    poolInfo: any,

}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{width: '100%'}}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box display={'flex'} sx={{width: '100%'}}>
                    {children}
                </Box>
            )}
        </div>
    );
}


const StakingPool: FC<Props> = ({ tokenInfo, poolInfo}) => {

    const theme = useTheme();
    const { account } = useWeb3React();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { selectedChainId, } = useAppSelector((state) => state.chain);
    const ftmPrice = useFtmPrice(Number(selectedChainId));
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [value, setValue] = useState(0);
    const [lockTime, setLockTime] = useState<number>(0)
    const [counter, setCounter] = useState<number>(0)
    const [pendingRewards, setPendingRewards] = useState<number>(0)
    const [userStaked, setUserStaked] = useState<number>(0)

    const isClaimable = Math.floor((new Date()).getTime() / 1000) > lockTime
    const estimateInfo = useStakeEstimateInfo(Number(selectedChainId), counter, poolInfo, ftmPrice )
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const onUpdate = () => {
        setCounter(counter+1);
    };

    useEffect(() => {
        if( estimateInfo  ){
          try{
            if(Number(getBalanceInEther(estimateInfo.pendingRewards)) < 0 )
              setPendingRewards(0)
            setPendingRewards(getBalanceInEther(estimateInfo.pendingRewards))
            setUserStaked(Number(ethers.utils.formatUnits(estimateInfo.userStaked, poolInfo?.stakeTokenDecimals)))
            setLockTime(Number(estimateInfo.stakeTime) + Number(estimateInfo.lockTime))
          }
          catch(err){
            // 
          }
    
        }
      }, [estimateInfo, account]);;

    return (
        <> <Container>
            {/* body */}
            <ModalBody sx={{position: 'relative', justifyContent: 'center'}}>
                <>
                    <Box  display='flex' onClick={() => {setIsExpanded(!isExpanded)}}  position='absolute' sx={{bottom: 18, right: {xs: 0, md: -50 } , border: '1px solid #5E6980', borderRadius: '25px', padding: '10px', background: '#2A3139', cursor: 'pointer'}}>
                        <ArrowBackIosNewIcon sx={{width: '15px', height: '15px', transform: !isExpanded ? 'rotate(-90deg)' : 'rotate(90deg)'}}/>
                    </Box>
                </>
                <Box display='flex' flexDirection='column' gap={'10px'} justifyContent='center' alignItems='center' sx={{background: "rgba(6,1,1,0.3)",  minHeight: '77px', height: '100%', borderRadius: '10px', minWidth: '150px'}}>
                    <PoolAprLabel>
                        APR
                    </PoolAprLabel>
                    <PoolAprValue>
                     {tokenInfo?.apr > 0 ? tokenInfo?.apr.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '%' : '--.--'}
                    </PoolAprValue>
                </Box>
                <Box display='flex' gap={'10px'} sx={{flexDirection: {xs: 'column'}}}>
                    <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{background: "rgba(6,1,1,0.3)", padding: '5px', minHeight: '34px', borderRadius: '10px', minWidth: '200px'}}>
                        <InfoLabelBegin sx={{minWidth: '100px'}}>
                            TVL:
                        </InfoLabelBegin>
                        <InfoLabelEnd>
                            {poolInfo?.stakeTokenAddress === poolInfo?.rewardTokenAddress ? poolInfo?.totalStaked + poolInfo?.totalRewards + ' ' : poolInfo?.totalStaked} {poolInfo?.stakeTokenSymbol}
                        </InfoLabelEnd>
                    </Box>
                    <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{background: "rgba(6,1,1,0.3)", padding: '5px', minHeight: '34px', borderRadius: '10px', minWidth: '200px'}}>
                        <InfoLabelBegin sx={{minWidth: '160px'}}>
                            Deposit Fee:
                        </InfoLabelBegin>
                        <InfoLabelEnd>
                            {tokenInfo?.depositFee}
                        </InfoLabelEnd>
                    </Box>
                </Box>
                <Box display='flex' gap={'10px'} sx={{flexDirection: {xs: 'column'}}}>
                    <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{background: "rgba(6,1,1,0.3)", padding: '5px', minHeight: '34px', borderRadius: '10px', minWidth: '200px'}}>
                        <InfoLabelBegin sx={{minWidth: '160px'}}>
                            Your Staked {poolInfo?.stakeTokenSymbol}
                        </InfoLabelBegin>
                        <InfoLabelEnd>
                            {userStaked}
                        </InfoLabelEnd>
                    </Box>
                    <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{background: "rgba(6,1,1,0.3)", padding: '5px', minHeight: '34px', borderRadius: '10px', minWidth: '200px'}}>
                        <InfoLabelBegin sx={{minWidth: '160px'}}>
                            Earned {poolInfo?.rewardTokenSymbol}
                        </InfoLabelBegin>
                        <InfoLabelEnd>
                            {pendingRewards?.toFixed(4)}
                        </InfoLabelEnd>
                    </Box>
                </Box>
            </ModalBody>
            {isExpanded &&
                <>
                    <Box mt={2} sx={{width: '100%', height: '2px', background: '#5E6980'}}/>
                    <Box display='flex' flexDirection={isMobile ? 'column' : 'row'} alignItems='center' justifyContent='space-between'>
                        <Box mt={2} display='flex' >
                            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{background: "rgba(6,1,1,0)", padding: '5px', minHeight: '34px', borderRadius: '10px', minWidth: '200px'}}>
                                <InfoLabelBegin sx={{minWidth: '120px'}}>
                                    Time until no tax claim
                                </InfoLabelBegin>
                            </Box>
                            <Box display='flex' flexDirection='row'  alignItems='center' justifyContent='center' gap='10px' sx={{height: '100%', maxWidth: '200px', border: '0px solid red', position: 'relative', minWidth: '100px'}}>
                                <Box display='flex' justifyContent='space-between' alignItems='space-between' gap='5px' sx={{background: "rgba(6,1,1,0.3)", padding: '5px', minHeight: '34px', borderRadius: '10px', minWidth: '140px'}}>
                                    <img src={icons.timeIcon}  style={{width: '25px', height: '25px', minWidth: '20px', objectFit: 'fill'}}/>
                                    <PoolName2 sx={{border: '0px solid red', justifyContent: 'center', alignItems: 'center'}}>
                                        <CountDown lockTime={lockTime}/>
                                    </PoolName2>
                                </Box>
                            </Box>
                        </Box>
                        <Box mt={2} display='flex' gap='5px'>
                            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{background: "rgba(6,1,1,0.3)", padding: '5px', minHeight: '34px', borderRadius: '10px', minWidth: '200px'}}>
                                <InfoLabelBegin sx={{minWidth: '130px'}}>
                                    Early withdraw tax
                                </InfoLabelBegin>
                                <InfoLabelEnd>
                                    { `10%`}
                                </InfoLabelEnd>
                            </Box>
                        </Box>
                    </Box>

                    <Box mt={2} sx={{display: 'flex'}} flexDirection='row' justifyContent='center' alignItems='center'>

                    </Box>


                    <Box mt={2} sx={{display: 'flex', border: '0px solid red'}} flexDirection='column' justifyContent='center' alignItems='center'>
                        <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' sx={{ borderBottom: 1, borderColor: 'divider', border: '0px solid red' }}>
                            <Tabs value={value} sx={{width: '100%'}} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Stake" {...a11yProps(0)} />
                                <Tab label="Unstake" {...a11yProps(1)} />
                                <Tab label="Claim" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <Box mt={2} display='flex' sx={{width: '100%', border: '0px solid red', }}>
                            <CustomTabPanel value={value} index={0}>
                                <StakingTabPanel key={1} type='Stake'  onUpdate={onUpdate} pool={poolInfo} info={estimateInfo} isClaimable={isClaimable}/>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <StakingTabPanel key={2} type='Unstake' onUpdate={onUpdate} pool={poolInfo} info={estimateInfo} isClaimable={isClaimable} />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={2}>
                                <StakingTabPanel key={3} type='Claim'  onUpdate={onUpdate} pool={poolInfo} info={estimateInfo} isClaimable={isClaimable}/>
                            </CustomTabPanel>
                        </Box>
                    </Box>

                </>
            }
        </Container>
        </>
    );
};

export default StakingPool;
