import { FC, useEffect, useRef, useState } from 'react';
import { Box, Grid, Pagination, styled, Typography } from '@mui/material';
import {  useAppSelector } from 'state/hooks';
import { usePinata } from 'hooks/usePinata'
import { ceil } from 'lodash';
import { useFtmPrice } from 'hooks/useFTMPrice';
import PresaleMini from './PresaleMini';
import {presaleBlockList} from "../../config/constants/presaleBlockList"
const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  maxWidth: '1900px',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  minHeight: '200px',
  minWidth: '300px',
  [theme.breakpoints.up('xs')]: {
    padding: '0px 16px',
  },
  [theme.breakpoints.up('md')]: {
    padding: '20px 32px',
  },
}));


const InfoLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '700',
  cursor: 'pointer',
  fontSize: '14px',
  lineHeight: '21px',
  color: 'white',
  margin: 'auto',

  img: {
    width: '20px',
    marginRight: '8px',
  },

  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    lineHeight: '18px',
    img: {
      display: 'none',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
    lineHeight: '21px',
    img: {
      display: 'block',
    },
  },
}));


interface PoolCardProps {
  isStaking: boolean
}

const ListedPresale: FC<PoolCardProps> = ({ isStaking }) => {
  const { size, deployedPresales, presaleReloadCount } = useAppSelector((presale) => presale.presale);
  const { selectedChainId, isWalletChecked } = useAppSelector((state) => state.chain);

  const { pendingTxHash } = useAppSelector((state) => state.modal);
  const pendingTxHashRef: { current: string | undefined } = useRef();
  pendingTxHashRef.current = pendingTxHash;
  const [reloadData, setReloadData] = useState(false);
  const [reloadCounter, setReloadCounter] = useState(0);
  const {combinedData} = usePinata(reloadCounter)
  const [tokensPerPage, setTokensPerPage] = useState(20);
  const [pages, setPages] = useState(0);
  const [loadedTokens, setLoadedTokens] = useState([])
  const [page, setPage] = useState(1);
  const [tokens, setTokens] = useState<any>([]);
  const [presaleReloads, setPresaleReloads] = useState<number>(0);

  const [isStakerOpened, setStakerOpen] = useState<boolean>();

  function isNumeric(n: any) {
    return !Number.isNaN(Number(n)) && Number.isFinite(Number(n));
  }
  const ftmPrice = useFtmPrice(Number(selectedChainId));

  useEffect(
    () => {
      const timer1 = setTimeout(() => { 
        if( reloadData ){
          setReloadCounter(reloadCounter+1) 
          setReloadData(false);
        }
      }, 25 * 1000);
      return () => {
        clearTimeout(timer1);
      };
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deployedPresales, size]
  );

  useEffect(
    () => {
      if( presaleReloads != presaleReloadCount ){
        setPresaleReloads(presaleReloadCount)
        setReloadCounter(reloadCounter+1) 
        setReloadData(false);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deployedPresales, size, presaleReloadCount]
  );


  useEffect(
    () => {
      if( ftmPrice === 0 )
        return
      if( size > 0 ){
        const tokens = []
        const lastTokens: [number] = [0]
        for( let i = size; i > 0; i-= 1 ){
          tokens.push({index: i - 1})
          if( i < size - tokensPerPage ){
            lastTokens.push(i - 1)
          }
        }
        const filteredTokens = tokens.filter((item) => !presaleBlockList.includes(item.index));
        setTokens(filteredTokens)
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [size, ftmPrice]
  );


  useEffect(
    () => {
      if( !isStaking ){
        setStakerOpen(false)
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isStaking]
  );

  useEffect(
    () => {
      if( tokens ){
        setPages(ceil(tokens.length / tokensPerPage))
        setLoadedTokens(tokens.slice(0,tokensPerPage))
        setPage(1)
        
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tokens]
  );

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    try{
      setLoadedTokens(tokens.slice((value-1) * tokensPerPage, (value ) * tokensPerPage))
      setPage(value);

    }
    catch(err){
      console.log(err)
    }
  };

  return (
    <>
      <Container>
        <InfoLabel>
            Current LBMs
        </InfoLabel>
        <Grid mt={2} container spacing={4} justifyContent='center'>
            <>{loadedTokens && loadedTokens
            .map((row: any) => <PresaleMini preinfo={row} images={combinedData}/>)}</>
        </Grid>
        <Pagination page={page} onChange={handlePageChange} sx={{marginTop: '40px'}} count={pages} color="primary" />
      </Container>
    </>
  );
};

export default ListedPresale;
