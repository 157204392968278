import React, {useEffect, useState} from 'react';
import {Box, Typography, useMediaQuery} from '@mui/material';
import {styled} from "@mui/system";
import {ContainedTextField} from "../../common/Input/ContainedTextField";


const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: '10px',
    background: "rgba(6, 1,1, 0.3)",
    padding: '10px',
    marginTop: '12px',
}))

const TokensForLpFormulaContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
}))


const TotalSupplyFormulaContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: '12px',
}))

const CustomTextFieldConatiner = styled(Box)(({ theme }) => ({
    border: '0px solid red',
    display: 'flex',
    width: '150px',
    height: '30px',

    [theme.breakpoints.up('xs')]: {
        display: 'flex',
        width: '80px',
        height: '30px',
    },
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        width: '150px',
        height: '30px',
    },
}))

const CustomTextField = styled(ContainedTextField)(({ theme, isGreenBorder }) => ({

    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: isGreenBorder ? 'green' : "white",
        },
        "&:hover fieldset": {
            borderColor: isGreenBorder ? "green" : "white",
        },
        "&.Mui-focused fieldset": {
            borderColor: isGreenBorder ? "green" : "white",
        },
    },
    '& .MuiInputBase-root': {
        height: '30px',
        textAlign: 'center',
    },
    '& .MuiInputBase-input': {
        textAlign: 'center',
        fontSize: '14px',

    },
    '& input::placeholder': {
        textAlign: 'center',
        fontSize: '12px',
    },
    '& .MuiInputLabel-shrink': {
        top: 0,
        left: 0,
        transform: 'translate(14px, -50%) scale(0.75)',
        textAlign: 'center',
        width: 'auto',
        textColor: 'red',
    },

}))
const SymbolConatiner = styled(Box)(({ theme }) => ({
    border: '0px solid red',
    width: '30px',
    height: '30px',
    alignItems: 'center',
    justifyContent: 'center',
    cursore: 'none',
}))

const Symbol = styled(Typography)(({ theme }) => ({
    border: '0px solid green',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    fontFamily: 'Prompt',
    fontWeight: 300,
    fontSize: '14px',
    cursor: 'text',

}))

interface Props {
    hardCap:string;
    tokenLaunchPrice: string;
    tokensForPresale: string;
    totalSupply: string;
    errorField?: string;
    onComparsionChange: (comparsion: boolean) => boolean;
}

const MinTotalSupplyFormula: React.FC<Props> = ({hardCap, tokenLaunchPrice, tokensForPresale, totalSupply, onComparsionChange}) => {

    const [tokensForLp, setTokensForLp] = useState<string>('');
    const [totalSupplyWithFee, setTotalSupplyWithFee] = useState<string>('');
    const [comparsion, setComparsion] = useState(true);

    const isMobile = useMediaQuery('only screen and (max-width: 450px)');

    useEffect(() => {
        setTokensForLp(calculateTokensForLP(hardCap, tokenLaunchPrice));
        setTotalSupplyWithFee(calculateTotalSupplyWithFee(totalSupply));
        setComparsion(checkComparsion(totalSupplyWithFee, tokensForLp, tokensForPresale))
        onComparsionChange(comparsion)

    }, [hardCap, tokenLaunchPrice, totalSupply, tokensForPresale, totalSupplyWithFee, tokensForLp, comparsion]);


    const calculateTokensForLP = (hardCap: string, tokenLaunchPrice: string) => {
        return hardCap && tokenLaunchPrice !== ''? (parseFloat(hardCap) / parseFloat(tokenLaunchPrice)).toFixed(2) : '';
    }

    const calculateTotalSupplyWithFee = (totalSupply: string) => {
        return totalSupply !== ''? (Number(parseFloat(totalSupply)) * 0.99).toFixed(2) : '';
    }

    const checkComparsion = (totalSupplyWithFee: string, tokensForLp:string, tokensForPresale: string) => {
        if (totalSupplyWithFee && tokensForLp && tokensForPresale !== '')
        {
            return parseFloat(tokensForLp) + parseFloat(tokensForPresale) <= parseFloat(totalSupplyWithFee);
        }
        return true;
    }

    const formatValue = (value:string) => {
        return value.length !== 0 ? Number(value).toFixed(2) : '';
    }

    return (
        <Container>

            <Typography
                component="span"
                sx={{ fontSize: '20px', textAlign: 'center', mb: 1}}
            >
                Please refer to formula below for tokenomics
            </Typography>
            <TokensForLpFormulaContainer>
                <CustomTextFieldConatiner sx={{  }}>
                    <CustomTextField variant={"outlined"}  label={'Hard Cap'}
                                     placeholder={'Hard Cap'}
                                     value={formatValue(hardCap)}
                                     focused={false}
                                     onMouseEnter={undefined}
                                     InputLabelProps={{
                                         sx: {
                                             top: '-35%',
                                             left: '0%',
                                             width: '100%',
                                             textAlign: 'center',
                                             transition: 'all 0.3s ease',
                                             fontSize: {md:'12px', xs:'10px'},
                                             color: hardCap.length > 0 && Number(hardCap) > 0 ? 'green' : '',
                                         },
                                     }}
                                     InputProps={{ readOnly: true }}
                                    isGreenBorder={hardCap.length > 0 && Number(hardCap) > 0}
                                    error={hardCap.length > 0 && Number(hardCap) === 0}/>
                </CustomTextFieldConatiner>
                <SymbolConatiner>
                    <Symbol> / </Symbol>
                </SymbolConatiner>
                <CustomTextFieldConatiner sx={{ }}>
                    <CustomTextField variant={"outlined"}
                                     placeholder={isMobile ? 'Launch Pr': 'Token Launch Price'}
                                     label={isMobile ? 'Launch Pr': 'Token Launch Price'}
                                     value={formatValue(tokenLaunchPrice)}
                                     focused={false}
                                     onMouseEnter={undefined}
                                     InputLabelProps={{
                                         sx: {
                                             top: '-35%',
                                             left: '0%',
                                             width: '100%',
                                             textAlign: 'center',
                                             transition: 'all 0.3s ease',
                                             fontSize: {md:'12px', xs:'10px'},
                                             color: tokenLaunchPrice.length > 0 && Number(tokenLaunchPrice) > 0 ? 'green' : '',
                                         },
                                     }}
                                     InputProps={{ readOnly: true, }}
                                     isGreenBorder={tokenLaunchPrice.length > 0 && Number(tokenLaunchPrice) > 0}
                                     error={(tokenLaunchPrice.length > 0 && Number(tokenLaunchPrice) === 0) || !comparsion}/>
                </CustomTextFieldConatiner>
                <SymbolConatiner >
                    <Symbol> = </Symbol>
                </SymbolConatiner>
                <CustomTextFieldConatiner sx={{ }}>
                    <CustomTextField variant={"outlined"}
                                     placeholder={isMobile ? 'Tokens LP' : 'Tokens For LP'}
                                     label={isMobile ? 'Tokens LP' : 'Tokens For LP'}
                                     value={formatValue(tokensForLp)}
                                     focused={false}
                                     onMouseEnter={undefined}
                                     InputLabelProps={{
                                         sx: {
                                             top: '-35%',
                                             left: '0%',
                                             width: '100%',
                                             textAlign: 'center',
                                             transition: 'all 0.3s ease',
                                             fontSize: {md:'12px', xs:'10px'},
                                             color: tokenLaunchPrice.length > 0 && Number(tokenLaunchPrice) > 0 &&
                                             hardCap.length > 0 && Number(hardCap) > 0 ? 'green' : ''
                                         },
                                     }}
                                     InputProps={{ readOnly: true }}
                                     error={(tokensForLp.length > 0 && Number(tokensForLp)) === 0 || (Number(tokensForLp) === Infinity)}
                                     isGreenBorder={tokenLaunchPrice.length > 0 && Number(tokenLaunchPrice) > 0 &&
                                         hardCap.length > 0 && Number(hardCap) > 0}/>
                </CustomTextFieldConatiner>
            </TokensForLpFormulaContainer>




            <TotalSupplyFormulaContainer>
                <CustomTextFieldConatiner sx={{  }}>
                    <CustomTextField variant={"outlined"}
                                     placeholder={isMobile ? 'Tokens LP' : 'Tokens For LP'}
                                     label={isMobile ? 'Tokens LP' : 'Tokens For LP'}
                                     value={formatValue(tokensForLp)}
                                     focused={false}
                                     onMouseEnter={undefined}
                                     InputLabelProps={{
                                         sx: {
                                             top: '-35%',
                                             left: '0%',
                                             width: '100%',
                                             textAlign: 'center',
                                             transition: 'all 0.3s ease',
                                             fontSize: {md:'12px', xs:'10px'},
                                             color: tokenLaunchPrice.length > 0 && Number(tokenLaunchPrice) > 0 &&
                                             hardCap.length > 0 && Number(hardCap) > 0 ? 'green' : ''
                                         },
                                     }}
                                     InputProps={{ readOnly: true }}
                                     error={(tokensForLp.length > 0 && Number(tokensForLp)) === 0 || (Number(tokensForLp) === Infinity)}
                                     isGreenBorder={tokenLaunchPrice.length > 0 && Number(tokenLaunchPrice) > 0 &&
                                         hardCap.length > 0 && Number(hardCap) > 0}/>
                </CustomTextFieldConatiner>
                <SymbolConatiner>
                    <Symbol sx={{fontSize: '20px'}}> + </Symbol>
                </SymbolConatiner>
                <CustomTextFieldConatiner sx={{}}>
                    <CustomTextField variant={"outlined"}
                                     placeholder={ isMobile ? 'Tok.Presale' : 'Tokens For Presale'}
                                     label={ isMobile ? 'Tok.Presale' : 'Tokens For Presale'}
                                     value={formatValue(tokensForPresale)}
                                     focused={false}
                                     onMouseEnter={undefined}
                                     InputLabelProps={{
                                         sx: {
                                             top: '-35%',
                                             left: '0%',
                                             width: '100%',
                                             textAlign: 'center',
                                             transition: 'all 0.3s ease',
                                             fontSize: {md:'12px', xs:'10px'},
                                             color: tokensForPresale.length > 0 && Number(tokensForPresale) > 0 ? 'green' : ''
                                         },
                                     }}
                                     isGreenBorder={tokensForPresale.length > 0 && Number(tokensForPresale) > 0}
                                     error={tokensForPresale.length > 0 && Number(tokensForPresale) === 0}
                                     InputProps={{ readOnly: true }}/>
                </CustomTextFieldConatiner>
                <SymbolConatiner>
                    <Symbol sx={{color: comparsion  ? '' :  'red'}}> {'<='} </Symbol>
                </SymbolConatiner>
                <CustomTextFieldConatiner sx={{  }}>
                    <CustomTextField variant={"outlined"}
                                     placeholder={ isMobile ? 'Supply -1%' : 'Total Supply -1%'}
                                     label={ isMobile ? 'Supply -1%' : 'Total Supply -1%'}
                                     value={formatValue(totalSupplyWithFee)}
                                     focused={false}
                                     onMouseEnter={undefined}
                                     InputLabelProps={{
                                         sx: {
                                             top: '-35%',
                                             left: '0%',
                                             width: '100%',
                                             textAlign: 'center',
                                             transition: 'all 0.3s ease',
                                             fontSize: {md:'12px', xs:'10px'},
                                             color: totalSupplyWithFee.length > 0 && Number(totalSupplyWithFee) > 0 ? 'green' : ''
                                         },
                                     }}
                                     isGreenBorder={totalSupplyWithFee.length > 0 && Number(totalSupplyWithFee) > 0}
                                     error={(totalSupplyWithFee.length > 0 && Number(totalSupplyWithFee) === 0) || (Number(totalSupplyWithFee) < Number(tokensForPresale))}
                                     InputProps={{ readOnly: true }} />
                </CustomTextFieldConatiner>
            </TotalSupplyFormulaContainer>

        </Container>
    );
};

export default MinTotalSupplyFormula;
