import { FC, useEffect, useState } from 'react';
import { Box,  styled,  } from '@mui/material';
import moment from 'moment';
import {  toTwoDigits } from 'utils';

const GlobalInfoCardValue = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '700',
  color: '#ffffff',

  [theme.breakpoints.up('xs')]: {
    fontSize: '15px',
    lineHeight: '25px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '15px',
    lineHeight: '25px',
  },
}));


interface PoolCardProps {
    lockTime: number,
}

const CountDown: FC<PoolCardProps> = ({ lockTime}) => {
    const [nextEpochCountDown, setNextEpochCountDown] = useState<string>('');

    useEffect(() => {
        const interval = setInterval(() => {
          const timeDiff = moment.duration(moment(1000 * lockTime).diff(moment()));
          const hourDiff = toTwoDigits(timeDiff.hours() + (Number(timeDiff.days()) * 24 ));
          const minDiff = toTwoDigits(timeDiff.minutes());
          const secDiff = toTwoDigits(timeDiff.seconds());
          if( Number(timeDiff.seconds()) < 0 || Number(timeDiff.minutes()) < 0 ) {
            setNextEpochCountDown(`00:00:00`);
            return;
          }
          setNextEpochCountDown(`${hourDiff}:${minDiff}:${secDiff}`);
        }, 1000);
    
        return () => clearInterval(interval);
      }, [lockTime]);
    

  return (
    <>
          <GlobalInfoCardValue>{`${nextEpochCountDown === "NaN:NaN:NaN" ? '00:00:00' : nextEpochCountDown}`}</GlobalInfoCardValue>
    </>
  );
};

export default CountDown;
