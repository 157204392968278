import React, { FC, useState} from 'react';
import {TextField, OutlinedTextFieldProps, TextFieldProps} from '@mui/material';
import { styled } from '@mui/system';



const StyledTextField = styled(TextField)(({ theme }) => ({

    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "white",
        }
    },
    '& .MuiInputLabel-root.Mui-disabled': {
        color: 'lightgray',
    },
    '& .MuiInputBase-root.Mui-disabled': {
        color: 'lightgray',
    },
}));

interface ContainedTextFieldProps extends OutlinedTextFieldProps {
    isGreenBorder?: boolean
}

const ContainedTextField: FC<ContainedTextFieldProps> = ({sx = {}, ...props }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        if (!isClicked) {
            setIsHovered(false);
        }
    };

    const handleClick = () => {
        setIsClicked(true);
    };

    const handleBlur = () => {
        setIsClicked(false);
        setIsHovered(false);
    };

    return (
        <StyledTextField
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
            onBlur={handleBlur}
            focused={isHovered || isClicked}
            {...props}
            sx={{...sx}}
        />
    );
};

export { ContainedTextField };
