import { getBalanceInEther } from 'utils/formatBalance';
import ERC20 from 'config/abi/Erc20.json';
import multicall from 'utils/multicall';
import DeployerAbi from "config/abi/DeployerV2.json"
import BeethovenAbi from "config/abi/BeethovenABI.json"
import { getDeployAddresses, getDeployTokenABI, getLockerAddress, getRouterABI, getRouters, getTokenDeployer, getTokenDeployerInfo, getTokensAdditioanalInfo } from 'utils/deployTokens';
import RouterABI from 'config/abi/UniswapRouterABI.json';
import FactoryABI from 'config/abi/UniswapFactoryABI.json';
import LpPair from 'config/abi/LpPair.json';
import Locker from 'config/abi/LockerABI.json'
import { RouterCategory } from 'types/deploy';
import { getMasterChefAbi } from 'utils/stake';
import { BigNumber, ethers } from 'ethers';

import { getDeployedTokensInfo, getTokensBeethovenByAddress, getAirdropPoolsInfo, getAirdropsInfo, getDeployedTokensByAddressInfo } from 'config/constants/backend';
import { icons, tokens } from "config/constants/assets";
import {chain} from "lodash";
import {formatNumber} from "../../utils";


export const fetchDeployedTokensGlobalInfo = async (chainId: number, account: string, ftmPrice?: number | undefined) => {
  const data = await getDeployedTokensInfo({ chain: chainId });

  let lockerAddress = getLockerAddress(chainId)
  const resultData = await Promise.all(data.map(async (tokenData: any) => {
    const tokenAddInfo = getTokensAdditioanalInfo(tokenData.tokenAddress);
    let isUserToken = false;

    if (account) {
      if (tokenData.deployerAddress.toLowerCase() === account.toLowerCase()) {
        isUserToken = true;
      }
    }

    const lockETH = Number(ethers.utils.formatEther(tokenData.lockEth));
    const availabelRouters = getRouters(chainId);
    let currentRouterLogo = "";
    let routerBuyURL = "";
    let isElock = false;

    if (tokenData.router.length > 0 && tokenData.router !== "0x0000000000000000000000000000000000000000") {
      let currentRouter = availabelRouters[0];

      for (let i = 0; i < availabelRouters.length; i += 1) {
        if (availabelRouters[i].address === tokenData.router) {
          currentRouter = availabelRouters[i];
          isElock = availabelRouters[i].isElock;
        }
      }
      currentRouterLogo = currentRouter.logo;
      routerBuyURL = currentRouter.buyUrl;
    }


    const lockedLiquidity = BigNumber.from(tokenData.lockLiq)

    return {
      info: {
        isElock: isElock,
        dao: tokenData.dao,
        isPresaleToken: tokenData.presale,
        deployerAddress: tokenData.deployerAddress,
        ethAmountLocked: tokenData.lockEth,
        lockedLiquidity: lockedLiquidity,
        mainTokenPool: tokenData.pool,
        name: tokenData.tokenName,
        router: tokenData.router,
        supply:ethers.utils.parseEther(tokenData.supply.toString()),
        timeStamp: tokenData.timestamp,
        tokenAddress: tokenData.tokenAddress,
        tokenAmountLocked: tokenData.lockAmount
      },
      symbol: tokenData.tokenSymbol,
      isUserToken,
      logo: "",
      tokenPriceFTM: tokenData.priceFTM,
      lpAddress: tokenData.pairAddress,
      lockLps: lockETH * 2,
      initialPrice: tokenData.initialPriceFtm,
      currentRouterLogo,
      routerBuyURL,
      lpPrice: tokenData.lpCount * 2,
      tokenAddInfo,
      tokenDeployer: tokenData.tokenDeployer,
      lockerAddress,
      elockId: tokenData.elockId,
    };
  }));

  return {
    data: resultData
  };
};



export const fetchDeployedTokensSizeInfo = async (chainId: number, deployerAddresses: string[]): Promise<any> => {
  const offchainDataDeployed = await getDeployedTokensInfo({chain: chainId});
  const size = offchainDataDeployed.length;
  return {
    data: size,
  };
};

export const fetchDeployedTokensSizeInfoDB = async (chainId: number): Promise<any> => {
  const offchainDataDeployed = await getDeployedTokensInfo({chain: chainId});
  const size = offchainDataDeployed.length;
  // TODO Parse data from DB ( blocked or not tokens )
  // 
  return {
    data: size,
  };
};



export const fetchStakerInfo = async (chainId: number, account: string, ftmPrice: number, info: any): Promise<any> => {

  const stakeTokens =  getRouters(chainId);
  try {
    let poolCount = 0;
    const pools: any[] = []

    if ( info.info.mainTokenPool ){
      const address = info.info.mainTokenPool
      let pool = {}

      const offchainDataAirDrop = await getAirdropsInfo({address: info.info.tokenAddress});
      if(!offchainDataAirDrop)
        return

      const offchainDataPool = await getAirdropPoolsInfo({address: address});
      if(!offchainDataPool)
        return

      for( let i = 0; i < offchainDataPool.length; i+=1 ){
        const lockTime =  offchainDataPool[i].lockTime;
        const totalAllocPointRaw = Number(offchainDataAirDrop[0].totalAllocPoint);
        const allocPoint = Number(offchainDataPool[i].allocPoint);

        pool = {aprYear : 0, lockTime, poolRewards: 0, tvl: 0,
          name: info.info.name, masterChefAddress: info.info?.mainTokenPool, stakingToken: offchainDataPool[i].stakingToken, rewardPerSecond: Number(0), poolStartTime: offchainDataAirDrop[0].startTime, poolId: i, totalAllocPointRaw, allocPoint, poolEndTime: offchainDataAirDrop[0].endTime}
          poolCount += 1;
          pools.push(pool)
      }
      }
      
      return {tokenAddress: info.info.tokenAddress, pools};
  } catch (error: any) {
    return {tokenAddress: "", pools: []};
  }
};

export const fetchStakerInfoData = async (chainId: number, account: string, ftmPrice: number, info: any): Promise<any> => {
  const data = await fetchStakerInfo(chainId, account, ftmPrice, info);
  return {
    data: data,
  };
};


export const fetchDeployedTokensByCA = async (chainId: number, account: string, address: string, ftmPrice: number): Promise<any> => {

  const availabelRouters =  getRouters(chainId);

  const offchainDataDeployed = await getDeployedTokensByAddressInfo({address: address.toLowerCase()});

  if( offchainDataDeployed && offchainDataDeployed.length > 0 ){
    const tokenData = offchainDataDeployed[0]

    if(!tokenData)
      return { index: -1 }
    if( chainId !== tokenData.chain_id )
      return { index: -1 }

    let lpAddress = tokenData.pairAddress
    let currentRouterLogo = ""
    let routerBuyURL = ""
    let price = tokenData.priceFTM;
    let lpCount = tokenData.lpCount;
    let lockerAddress = ""
    const initialPrice = tokenData.initialPriceFtm
    let isUserToken = false;

    if( account ){
        if( tokenData.deployerAddress.toLowerCase() === account.toLowerCase() ){
            isUserToken = true
        }
    }
    const priceInUSD = price * ftmPrice;
    const mcapInUSD = getBalanceInEther(tokenData.supply) * priceInUSD
    let unlockDate = 0;

    try{

      if( tokenData.router.length > 0 && tokenData.router != "0x0000000000000000000000000000000000000000" ){
  
        let currentRouter = availabelRouters[0];
        
        for( let i = 0; i < availabelRouters.length; i+=1){
          if( availabelRouters[i].address === tokenData.router ){
            currentRouter = availabelRouters[i];
          }
        }
        currentRouterLogo = currentRouter.logo;
        routerBuyURL = currentRouter.buyUrl;
      }
        
    }
    catch(err){

    }
    

    const tokenAddInfo =  getTokensAdditioanalInfo(tokenData.tokenAddress);
    const lockedLiquidity = BigNumber.from(tokenData.lockLiq)
    const lockETH = Number(ethers.utils.formatEther(tokenData.lockEth))

    return {index: 1, info: {dao: tokenData.dao,
      deployerAddress: tokenData.deployerAddress,
      ethAmountLocked: tokenData.lockEth,
      lockedLiquidity: lockedLiquidity,
      mainTokenPool: tokenData.pool,
      name: tokenData.tokenName,
      router: tokenData.router,
      supply: ethers.utils.parseEther(tokenData.supply.toString()),
      timeStamp: tokenData.timestamp,
      tokenAddress: tokenData.tokenAddress,
      tokenAmountLocked: tokenData.lockAmount}, symbol: tokenData.tokenSymbol, isUserToken, logo: "", tokenPriceFTM: price, lpAddress, lockLps: lockETH * 2,
       initialPrice, currentRouterLogo,routerBuyURL,
       priceInUSD, mcapInUSD, lpPrice: lpCount * 2 , unlockDate, tokenAddInfo, tokenDeployer: tokenData.tokenDeployer, lockerAddress, elockId : tokenData.elockId};
  }

  

  return { index: -1 }
};

export const fetchDeployedTokensGlobalInfoByCA = async (chainId: number, account: string, address: string, ftmPrice: number): Promise<any> => {
  const data = await fetchDeployedTokensByCA(chainId, account, address, ftmPrice);
  return {
    data: data,
  };
};