import {FC} from 'react';

import {
    Box,
    Button,
    Grid,
    styled,
    Typography,
    DialogActions,
    Divider,
    Dialog,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';


const ToggleSection = styled(Box)(() => ({
    position: 'absolute',
    right: '0',
    top: '0',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '45px',
    height: '45px',
    zIndex: 1
}));

const ModalDialog = styled(Dialog)(({ theme }) => ({
    '.MuiDialog-container > .MuiPaper-root': {
        borderRadius: '10px',
        width: '100%',
        background: 'rgba(36, 44, 55,0.95)',
        padding: '0px 0px 0px',
        border: '1px solid #5E6980',
        boxShadow:
            '0px 2.1574831008911133px 29.22142219543457px 0px rgba(0, 0, 0, 0.05), 0px 3.806384325027466px 39.8596076965332px 0px rgba(0, 0, 0, 0.07), 0px 5.703558921813965px 44.46048355102539px 0px rgba(0, 0, 0, 0.08), 0px 10.160801887512207px 47.2956428527832px 0px rgba(0, 0, 0, 0.09), 0px 25.993473052978516px 52.371063232421875px 0px rgba(0, 0, 0, 0.10), 0px 100px 77px 0px rgba(0, 0, 0, 0.13)',

        [theme.breakpoints.up('xs')]: {
            padding: '0px 0px 0px',
            margin: '24px',
            maxWidth: '260px',
            minHeight: '200px'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '260px',
            minHeight: '200px'
        },
    },
}));

interface NotificationModalProps {
    open: boolean;
    handleClose: () => void;
}


const NotificationModal: FC<NotificationModalProps> = ({ open, handleClose }) =>
{
    return (
        <ModalDialog open={open} onClose={handleClose}>
            <ToggleSection onClick={handleClose}>
                <CloseIcon sx={{width: '20px', height: '20px'}}/>
            </ToggleSection>


            <Box
                flex={1}
                textAlign="center"
                alignItems="center"
                flexDirection={'column'}
                justifyContent="center"
                gap={2}
                display="flex"
                sx={{
                    border: '0px solid blue',
                    paddingTop: '60px',
                    minHeight: '100%',
                    wordWrap: 'break-all',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}>
                <Box sx={{display:'flex', flexDirection: 'column', gap: '20px',  border: '0px solid red',}}>
                <Typography variant="h3"
                            sx={{width: '100%', wordBreak: 'break-word'}}
                >{"Staking Pool Deployed!"}</Typography>
                <Typography variant="h4"
                            sx={{width: '100%', wordBreak: 'break-word', fontWeight: '300', fontSize:'14px'}}
                >{"Note: If your pool is not visible, please refresh page."}</Typography>
                </Box>
            </Box>
        </ModalDialog>
    );
};

export default NotificationModal;