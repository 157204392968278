import { stakingInfos, masterChefInfos } from 'config/constants/staking';
import { StakeCategory } from 'types/stake';

import StakeAbi from 'config/abi/StakeAbi.json';

export const getStakes = (chainId: number) => {
  const tokens = stakingInfos.filter((row: any) => Number(chainId) === Number(row.chainId));
  return tokens;
};

export const getMasterChefAddress = (chainId: number, type: string, category: StakeCategory) => {
  const masterChef = masterChefInfos.find(
    (row: any) => Number(chainId) === Number(row.chainId) && type === row.type && category === row.category
  );

  return masterChef?.address;
};

export const getMasterChefAbi = () => {

  return StakeAbi;
};

export const getRewardEmissionRateFunctionName = (type: string, category: StakeCategory): string | null => {
  if (type === 'smeltRewardPool') {
    if (category === StakeCategory.ACTIVE) return null;
    if (category === StakeCategory.RETIRED) return 'smeltPerSecond';
    if (category === StakeCategory.TEAM) return 'smeltPerSecond';
    if (category === StakeCategory.TEST) return 'smeltPerSecond';
  }
  if (type === 'dealPool' || type === 'deadPool2') {
    return 'rewardPerSecond';
  }
  return null;
};
