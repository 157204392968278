/* eslint-disable no-console */
import { useCallback, useRef, useState } from 'react';
import { Web3ReactHooks } from '@web3-react/core';

import { MetaMask } from '@web3-react/metamask';
import { CoinbaseWallet } from '@web3-react/coinbase-wallet';
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import { useSessionStorage } from 'react-use-storage';
import { connectors, WalletConnector } from 'utils/web3React';
import { setupNetwork } from 'utils/wallet';
import { setIsWalletChecked } from 'state/chain/chainSlice';
import { useAppDispatch } from 'state/hooks';

const getConnectorById = (id?: string): [WalletConnector, Web3ReactHooks] => {
  if (id === 'walletconnectV2') {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return connectors.find(([connector]) => connector instanceof WalletConnectV2)!;
  }
  if (id === 'metamask') {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return connectors.find(([connector]) => connector instanceof MetaMask)!;
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return connectors.find(([connector]) => connector instanceof CoinbaseWallet)!;
};

const getConnectorId = (connector: WalletConnector): string => {
  if (connector instanceof WalletConnectV2) {
    return 'walletconnectV2';
  }
  if (connector instanceof MetaMask) {
    return 'metamask';
  }
  return 'coinbase';
};

const useAuth = (): { autoLogin: any; login: any; logout: any } => {
  const [sessionProvider, setSessionProvider, removeSessionProvider] = useSessionStorage<string | undefined>(
    'wallet_provider'
  );
  const dispatch = useAppDispatch();
  const [connecting] = useState<WalletConnector | undefined>(undefined);
  const connectingRef = useRef<WalletConnector | undefined>(connecting);
  connectingRef.current = connecting;

  const activateInjectedProvider = (connector?: WalletConnector) => {
    if (!connector) return;
    const { ethereum } = window;
    if (!ethereum?.providers) {
      return;
    }

    let provider;
    if (connector instanceof MetaMask) {
      provider = ethereum.providers.find((_provider) => _provider.isMetaMask && !_provider.isBraveWallet);
    }
    if (connector instanceof CoinbaseWallet) {
      provider = ethereum.providers.find(({ isCoinbaseWallet }) => isCoinbaseWallet);
    }

    if (provider && ethereum) {
      ethereum?.setSelectedProvider(provider);
    }
  };

  const logout = useCallback(() => {
    const [connector] = getConnectorById(sessionProvider);

    if (connector?.deactivate) {
      connector.deactivate();
    } else {
      connector?.resetState();
    }

    removeSessionProvider();
  }, [removeSessionProvider, sessionProvider]);

  const login = useCallback(
    async (connectorId: string, activeChainId?: string) => {
      const [connector] = getConnectorById(connectorId);


      if (connectingRef.current) {
        return;
      }

      connectingRef.current = connector;
      if (connectorId) {
        if (connector instanceof MetaMask) {
          await setupNetwork(activeChainId);
        }
        if( connectorId === 'walletconnectV2') {
          if(  connector.provider !== undefined )
          {
            await connector.connectEagerly();
            return
          }
        
            try {
              await connector.activate(Number(activeChainId) || 250);
            } catch (err) {
              console.log('Wallet connect v2 connect error', err);
            }
  
            setSessionProvider(getConnectorId(connector));
            if(  connector.provider !== undefined )
            {
              await connector.connectEagerly();
            }
            return
          
        }

          try {
            await connector.activate(Number(activeChainId) || 250);
          } catch (err) {
            console.log('Wallet connect v2 connect error', err);
          }

        setSessionProvider(getConnectorId(connector));
      } else {
        console.log("Can't find connector", 'The connector config is wrong');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const autoLogin = useCallback(
    async (connector: WalletConnector | undefined) => {
      if (connectingRef.current) {
        return;
      }
      connectingRef.current = connector;
      try {
        if (connector && connector.connectEagerly) {

            const ret =  await connector.connectEagerly();
            setSessionProvider(getConnectorId(connector));
            dispatch(
              setIsWalletChecked(true))
        }
      } catch (err) {
          console.debug('Failed to connect eagerly to wallet', err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { autoLogin, login, logout };
};

export default useAuth;
