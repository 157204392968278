import { Box } from '@mui/material';
import { styled } from '@mui/system';

const Card = styled(Box)(({ theme }) => ({
    background: "rgba(42,49,57, 0.4)",
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
    boxShadow:
        '0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
    // 5A687D
    transform: 'translate3d(0px, 0px, 0px)',
    border: '1px solid transparent',
    "&:hover": {
        boxShadow:
            '0px 100px 77px rgba(0, 0, 0, 0.2), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.189853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.1902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.1829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
        transform: 'translate3d(0px, -4px, 0px)',
        border: '1px solid rgba(46,145,250,0.8)',
        // 5A687D
    },
    transition: 'all 0.25s ease-in-out',

    [theme.breakpoints.up('xs')]: {
        flexDirection: 'column',
        gap: '20px'
    },
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        gap: '20px'
    },
}));

export default Card;
