import {FC, useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Grid,} from '@mui/material';
import TokenSection from './Components/TokenSection';
import InfoSection from './Components/InfoSection';
import PresaleSection from './Components/PresaleSection';
import PostPresaleSection from './Components/PostPresaleSection';
import LiquiditySection from './Components/LiquiditySection';
import {PresaleUIInfo} from 'types/presale';
import {ConnectWallet, ContainedButton,} from 'components/common/Button';
import {usePresale} from 'hooks/usePresaleInfo';
import {useAppDispatch, useAppSelector} from 'state/hooks';
import axios from 'axios';
import {ImageType} from 'react-images-uploading';
import {fetchDeployedPresaleSizeAsync, resetDataPresale, setPresaleReloadCountAsync} from 'state/presale/presaleSlice';
import {useWeb3React} from '@web3-react/core';
import DeployPresaleModal from "../Modal/DeployPresaleModal/DeployPresaleModal";
import ContainedDropDownBox from "../common/DropDownBox/ContainedDropDownBox";
import FancyDropDownBox from "../common/DropDownBox/FancyDropDownBox";
import AddSocialsInstructionsModal from "../Modal/InstructionsModal/AddSocialsInstructionsModal";
import FirstStepImage from "../../assets/PresaleInstructions/1_step.png";
import SecondStepImage from "../../assets/PresaleInstructions/2_step.png";
import ThirdStepImage from "../../assets/PresaleInstructions/3_step.png";


interface PoolCardProps {
}

const DeployPresale: FC<PoolCardProps> = ({  }) => {
    const [launchPrice, setLaunchPrice] = useState<string>('');
    const [totalSupply, setTotalSupply] = useState<string>('');
    const [tokensForPresale, setTokensForPresale] = useState<string>('');
    const [isDeployed, setIsDeployed] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(true);
    const [isCheckErrors, setIsCheckErrors] = useState<boolean>(false);
    const { selectedChainId } = useAppSelector((state) => state.chain);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [userDeployedPresale, setUserDeployedPresale] = useState<any>(undefined);
    const { deployedPresales, size, presaleReloadCount } = useAppSelector((presale) => presale.presale);
    const [isLoadingInfo, setIsLoadingInfo] = useState<boolean>(false);
    const [imageUploaded, setImageUploaded] = useState(false);
    const [hardCap, setHardCap] = useState("")
    const [images, setImages] = useState<ImageType[]>([]);
    const dispatch = useAppDispatch();
    const { account } = useWeb3React();
    const [isTotalSupplyError, setTotalSupplyError] = useState<boolean>(false);
    const [totalSupplySouldBe, setTotalSupplyShouldBe] = useState<string>("");
    const [presaleDeployedAddress, setPresaleDeployedAddress] = useState<string>("");
    const [isPresaleTokenPriceError, setPresaleTokenPriceError] = useState<boolean>(false)
    const [presaleTokenPrice, setPresaleTokenPrice] = useState<string>('');
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [comparsion, setComparsion] = useState<boolean>(false)
    const initialPresaleInfo: PresaleUIInfo = {tokenName: "", tokenSymbol: "", totalSupply: "",
        tokensPresale: "", presaleTokenPrice: "", softCap: "", minBuy: "", presaleStartsIn: "", hardCap: "",
        maxBuy: "", presaleLen: "", amm: undefined, lockDuration: "", isBurnChecked: false, isLiquidityChecked: false, isLiquidityCreateChecked: false,
        launchPrice: "", isElock: false, tokenDescription: "", tokenTg: "", tokenX: "", tokenDiscord: "", tokenWeb: ""
    }

    const [presaleInfo, setPresaleInfo] = useState<PresaleUIInfo>(initialPresaleInfo)


    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenPreviewModal = () => {
        checkError(presaleInfo) ? setIsModalOpen(false) : setIsModalOpen(true)
    }
    const handleClosePreviewModal = () => setIsModalOpen(false);

    const {onPresale} = usePresale(Number(selectedChainId), presaleInfo);

    const onConfirm = async () => {
        if(presaleInfo.isElock ){
          if( Number(presaleInfo.lockDuration) <= 0 )
            return
        }
        else if( presaleInfo.isLiquidityChecked ){
          if( Number(presaleInfo.lockDuration) < 15 )
            return
        }

        setLoading(true);
    
        try {
          handleClosePreviewModal()
          const presaleAddress = await onPresale(presaleInfo);
           if( presaleAddress ) {
               setPresaleDeployedAddress(presaleAddress)
               setModalOpen(true)
               rollUpDropDown()
           }
          setIsDeployed(true)
          
          } catch (err) {
            console.log('Presale err: ', err);
          }
          setLoading(false);
      };


      useEffect(() => {
        if( deployedPresales && isDeployed ){
          let userToken = undefined
          for (const token  of Object.values(deployedPresales)) {
            if( token.isUserToken ){
             // TODO Check name 
              if( token.info.tokenInfo.tokenName === presaleInfo.tokenName ) {
                userToken = token;
              }   
            }
        } 
        if( !userToken ){
            dispatch(fetchDeployedPresaleSizeAsync())
            return;
        }
          setUserDeployedPresale(userToken)
          setIsLoadingInfo(false)
        }
      }, [deployedPresales, size]);

    useEffect(() => {

        isExpanded ? doNothing() : clearFields()

    }, [isExpanded]);

    function calculateMinTotalSupply(presale:any)
    {
        const tokensPresale = parseFloat(presale.tokensPresale);
        const presaleTokenPrice = parseFloat(presale.presaleTokenPrice);
        const launchPrice = parseFloat(presale.launchPrice);

        const calculatedPresaleValue = (presaleTokenPrice * tokensPresale) / launchPrice;
        const requiredTotalSupply = (tokensPresale + calculatedPresaleValue);
        const requiredTotalSupplyWithFee = requiredTotalSupply / 0.99
        return requiredTotalSupplyWithFee;
    }

    const checkError = (presale: PresaleUIInfo) => {
        setIsCheckErrors(true)
        let error = false;

        if (presale.tokenName.length === 0) {
            error = true;
            console.log("Error: tokenName is empty");
        } else {
            console.log("tokenName is valid");
        }

        if (presale.tokenSymbol.length === 0) {
            error = true;
            console.log("Error: tokenSymbol is empty");
        } else {
            console.log("tokenSymbol is valid");
        }

        if (presale.totalSupply.length === 0 || Number(presale.totalSupply) === 0) {
            error = true;
            console.log("Error: totalSupply is empty or zero");
        } else {
            console.log("totalSupply is valid");
        }

        if (presale.tokensPresale.length === 0 || Number(presale.tokensPresale) === 0) {
            error = true;
            console.log("Error: tokensPresale is empty or zero");
        } else {
            console.log("tokensPresale is valid");
        }

        if (presale.presaleTokenPrice.length === 0 || Number(presale.presaleTokenPrice) === 0) {
            error = true;
            console.log("Error: presaleTokenPrice is empty or zero");
        } else {
            console.log("presaleTokenPrice is valid");
        }

        if (presale.softCap.length === 0 || Number(presale.softCap) === 0) {
            error = true;
            console.log("Error: softCap is empty or zero");
        } else {
            console.log("softCap is valid");
        }

        if (presale.minBuy.length === 0 || Number(presale.minBuy) === 0) {
            error = true;
            console.log("Error: minBuy is empty or zero");
        } else {
            console.log("minBuy is valid");
        }

        if (presale.presaleStartsIn.length === 0) {
            error = true;
            console.log("Error: presaleStartsIn is empty or zero");
        } else {
            console.log("presaleStartsIn is valid");
        }

        if (presale.maxBuy.length === 0 || Number(presale.maxBuy) === 0) {
            error = true;
            console.log("Error: maxBuy is empty or zero");
        } else {
            console.log("maxBuy is valid");
        }

        if (presale.presaleLen.length === 0 || Number(presale.presaleLen) === 0) {
            error = true;
            console.log("Error: presaleLen is empty or zero");
        } else {
            console.log("presaleLen is valid");
        }

        if (!presale.isElock && !presale.amm) {
            error = true;
            console.log("Error: Either elock or amm must be set");
        } else {
            console.log("isElock or amm is valid");
        }


        if (presale.isLiquidityChecked) {
            if (presale.lockDuration.length === 0 || Number(presale.lockDuration) === 0 || Number(presale.lockDuration) < 15) {
                error = true;
                console.log("Error: lockDuration is empty or zero while liquidity is checked");
            } else {
                console.log("lockDuration is valid");
            }
        } else {
            console.log("Liquidity is not checked, skipping lockDuration validation");
        }

        if (presale.launchPrice.length === 0 || Number(presale.launchPrice) === 0 ) {
            error = true;
            console.log("Error: launchPrice is empty or zero or presaleTokenPrice greater");
        } else {
            console.log("launchPrice is valid");
        }

        if (!comparsion)
        {
            error = true
        }
        return error;
    }

    const tokenSectionChanges = (tokenName: string, tokenSymbol: string, totalSupply: string) => {
        let presale = presaleInfo;
        presale.tokenName = tokenName;
        presale.tokenSymbol = tokenSymbol;
        presale.totalSupply = totalSupply;


        if( presale.totalSupply.length > 0 ){
          setTotalSupply((Number(presale.totalSupply)).toString())
        }

        //checkError(presale)
        setPresaleInfo(presale)
    }

    const presaleSectionChanges = (tokensForPresale: string, presaleTokenPrice: string, softCap: string, hardCap: string, minBuy: string, maxBuy: string, presaleStartsIn: string,
      presaleLen: string) => {

        let presale = presaleInfo;

        presale.tokensPresale = tokensForPresale;
        setTokensForPresale(tokensForPresale);
        presale.presaleTokenPrice = presaleTokenPrice
        presale.softCap = softCap;
        presale.minBuy = minBuy;
        presale.hardCap = hardCap;
        presale.maxBuy = maxBuy;
        presale.presaleLen = presaleLen;
        presale.presaleStartsIn = presaleStartsIn
        if( presale.presaleTokenPrice.length > 0 && presale.totalSupply.length > 0 &&  presale.tokensPresale.length > 0 ){
          setPresaleTokenPrice(presale.presaleTokenPrice)
          setHardCap((Number(presale.presaleTokenPrice) * Number(presale.tokensPresale)).toFixed(4))
       } else {
            setPresaleTokenPrice('')
            setHardCap('')
        }


        setPresaleInfo(presale)
    }

    const liquiditySectionChanges = (ammm: any, lockDuration: string, isBurnChecked: boolean,
        isLiquidityChecked: boolean, isLiquidityCreateChecked: boolean, images: ImageType[], isElock: boolean, tokenDescription: string,
        tokenTg: string, tokenX: string, tokenDiscord: string, tokenWeb: string
    ) => {
        let presale = presaleInfo;
        presale.amm = ammm;
        presale.lockDuration = lockDuration;
        presale.isBurnChecked = isBurnChecked;
        presale.isLiquidityChecked = isLiquidityChecked;
        presale.isLiquidityCreateChecked = isLiquidityCreateChecked;
        presale.isElock = isElock
        presale.tokenDescription = tokenDescription
        presale.tokenTg = tokenTg
        presale.tokenX = tokenX
        presale.tokenDiscord = tokenDiscord
        presale.tokenWeb = tokenWeb
        setImages(images)
        setPresaleInfo(presale)
    }
    const postPresaleSectionChanges = (launchPrice: string
    ) => {
        let presale = presaleInfo;
        presale.launchPrice = launchPrice;
        setLaunchPrice(launchPrice)
        setPresaleInfo(presale)

    }

    const rollUpDropDown = () =>
    {
        clearFields();
        setIsExpanded(false);
    }

    const clearFields = () => {
        setHardCap('');
        setLaunchPrice('')
        setTokensForPresale('')
        setTotalSupply('')
        setPresaleInfo(initialPresaleInfo);
    }

    const handleComparsionChange = (value: boolean): boolean => {
        setComparsion(value);
        return value;
    }

    function doNothing(){}
    return (
        <>
        {modalOpen && <AddSocialsInstructionsModal open={modalOpen} handleClose={() => {setModalOpen(false);}} firstStepLogo={FirstStepImage} secondStepLogo={SecondStepImage} thirdStepLogo={ThirdStepImage}
        isPresaleCreate={true}/>}

    <FancyDropDownBox globalTittle={'Liquidity Bootstrap Module'}
                              expandedTittle={'Create your Token And Presale'} isExpanded={isExpanded} setIsExpanded={setIsExpanded}>
            <Box display='flex' flexDirection='column' mt={2} mb={2}>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <Box display='flex' flexDirection='column'>
                            <TokenSection isCheckErrors={isCheckErrors} tokenSectionChanges={tokenSectionChanges}
                                          isTotalSupplyError={isTotalSupplyError} shouldBeTotalSupply={totalSupplySouldBe}/>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box display='flex' flexDirection='column'>
                            <PresaleSection hardCapS={isExpanded ? hardCap : '0'} totalSupply={totalSupply}
                                            presaleSectionChanges={presaleSectionChanges} isCheckErrors={isCheckErrors}
                                            isPresaleTokensError={false}
                                            isPresaleTokenPriceError={isPresaleTokenPriceError}/>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box display='flex' flexDirection='column'>
                            <PostPresaleSection tokenLaunchPrice={launchPrice} totalSupply={totalSupply} hardCap={hardCap}
                                                presaleTokenPrice={presaleTokenPrice}
                                                tokensForPresale={tokensForPresale}
                                                postPresaleSectionChanges={postPresaleSectionChanges}
                                                isCheckErrors={isCheckErrors} isTotalSupplyError={isPresaleTokenPriceError}
                                                comparsionChange={handleComparsionChange}/>

                            <LiquiditySection isCheckErrors={isCheckErrors}
                                              liquiditySectionChanges={liquiditySectionChanges}/>
                            <InfoSection/>
                        </Box>
                    </Grid>
                </Grid>

            </Box>

            {account ? (
                <Box display="flex" justifyContent="space-between" alignItems="center">


                    <ContainedButton
                        variant='outlined'
                        disabled={false}

                        onClick={handleOpenPreviewModal}
                        sx={{minWidth: '220px', minHeight: '53px', marginTop: '20px'}}
                    >
                        {isLoading ? <CircularProgress size={20} sx={{color: 'white'}}/> : "Preview"}
                    </ContainedButton>

                    <DeployPresaleModal
                        isDeployed={isDeployed}
                        open={isModalOpen}
                        handleClose={handleClosePreviewModal}
                        presaleInfo={presaleInfo}
                        images={images}
                        handleDeploy={onConfirm}
                    />
                </Box>

            ) : (
                <ConnectWallet width={220} height={53}/>
            )
            }
        </FancyDropDownBox>
            </>
    );
};

export default DeployPresale;
