import { FC, useState } from 'react';
import {Box,  styled, CircularProgress} from '@mui/material';
import { ImageType } from 'react-images-uploading';
import LogoUploadBox from "./LogoUploadBox";
import { ContainedButton} from "../../common/Button";
import {ContainedTextField} from "../../common/Input/ContainedTextField";



const Container = styled(Box)(({theme}) => ({
    border: '0px solid red',
    gap: '16px',
    display: 'flex',
    height:'100%',
    width: '100%',
    [theme.breakpoints.up('xs')]: {
        flexDirection: 'column',
    },
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
    },
}));

interface Props {
  isCheckErrors: boolean;
    isApproved: boolean;
    tokenAssetsSectionChanges: ( tokenTg: string, tokenX: string, tokenWeb: string, imagesStake: ImageType[], imagesReward: ImageType[], buyToken: string) => void;
    isFieldEmpty: boolean;
    onCreateClick:()=> void;
    isLoading: boolean;
    isImageLoading: boolean;
    isDeployed: boolean;
}


const TokenAssetsSection: FC<Props> = ({ tokenAssetsSectionChanges, isFieldEmpty, onCreateClick, isApproved, isLoading, isImageLoading, isDeployed  }) => {
    const [imageUploaded, setImageUploaded] = useState(false);
    const [imagesStake, setImagesStake] = useState<ImageType[]>([]);
    const [imagesReward, setImagesReward] = useState<ImageType[]>([]);
    const [tokenTg, setTokenTg] = useState<string>('');
    const [tokenX, setTokenX] = useState<string>('');
    const [tokenWeb, setTokenWeb] = useState<string>('');
    const [buyTokenUrl, setBuyTokenUrl] = useState<string>('');


    const onChangeTg = (e: any) => {
      const newAmount = e.target.value;
      if( newAmount.length > 250 )
          return
      setTokenTg(newAmount);
        tokenAssetsSectionChanges( newAmount, tokenX, tokenWeb, imagesStake, imagesReward, buyTokenUrl)
  };
  const onChangeTokenUrl = (e: any) => {
    const newAmount = e.target.value;
    if( newAmount.length > 250 )
        return
    setBuyTokenUrl(newAmount);
      tokenAssetsSectionChanges( tokenTg, tokenX, tokenWeb, imagesStake, imagesReward, newAmount)
};
  const onChangeX = (e: any) => {
      const newAmount = e.target.value;
      if( newAmount.length > 250 )
          return
      setTokenX(newAmount);
      tokenAssetsSectionChanges( tokenTg, newAmount, tokenWeb, imagesStake, imagesReward, buyTokenUrl)
  };

  const onChangeWeb = (e: any) => {
    const newAmount = e.target.value;
    if( newAmount.length > 250 )
        return
    setTokenWeb(newAmount);
      tokenAssetsSectionChanges( tokenTg, tokenX, newAmount, imagesStake, imagesReward, buyTokenUrl)

  };
    const imageUploadStaking = (imageList: any) => {
        setImagesStake(imageList)
        tokenAssetsSectionChanges( tokenTg, tokenX, tokenWeb, imageList, imagesReward, buyTokenUrl)
    };

  return (
      <>
        <Box mt={2} gap={2} display='flex' justifyContent='center' alignItems='center'
               sx={{border: '0px solid blue', flexDirection: 'column',
                   padding: {xs: '0px', md: '0px 30px 0px 30px'}}}>

            <Container>
                <Box sx={{border: '0px solid red', width: '100%', minHeight: '100%', flex:1}}>
                    <LogoUploadBox text={'Upload Staking Logo'} imageUpload={imageUploadStaking} hide={imageUploaded}/>
                </Box>
                <Box sx={{border: '0px solid red', width: '100%', minHeight: '100%', flex:1}}>
                <ContainedButton
                    sx={{
                        flex: 1,
                        fontSize: '25px',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        textAlign: 'center',
                        lineHeight: '1.2',
                    }}
                    disabled={isLoading || isDeployed || isFieldEmpty }
                    onClick={onCreateClick}>
                    {isLoading || isImageLoading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : (isApproved ? 'Create Pool' : 'Approve Reward Token')}
                </ContainedButton>
                </Box>
            </Container>
            <Container>
                  <ContainedTextField
                      autoComplete = 'off'
                      variant='outlined'
                      placeholder="Website Link"
                      label="Website Link"
                      fullWidth
                      onChange={onChangeWeb}
                      disabled={imagesStake.length === 0}
                      InputLabelProps={{
                          sx: {
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              width: '100%',
                              textAlign: 'center',
                              transition: 'all 0.3s ease',
                          },
                      }}
                      sx={{
                          '& .MuiInputBase-root': {
                              height: '30px',
                              textAlign: 'center',
                          },
                          '& .MuiInputLabel-shrink': {
                              top: 0,
                              left: 0,
                              transform: 'translate(14px, -50%) scale(0.75)',
                              textAlign: 'left',
                              width: 'auto',
                          },
                          '& input::placeholder': {
                              textAlign: 'center',
                          },
                      }}
                  />

            <ContainedTextField
                autoComplete = 'off'
                variant='outlined'
                placeholder="Buy Token Link"
                label="Buy Token Link"
                fullWidth
                onChange={onChangeTokenUrl}
                disabled={imagesStake.length === 0}
                InputLabelProps={{
                    sx: {
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                        textAlign: 'center',
                        transition: 'all 0.3s ease',
                    },
                }}
                sx={{
                    '& .MuiInputBase-root': {
                        height: '30px',
                        textAlign: 'center',
                    },
                    '& .MuiInputLabel-shrink': {
                        top: 0,
                        left: 0,
                        transform: 'translate(14px, -50%) scale(0.75)',
                        textAlign: 'left',
                        width: 'auto',
                    },
                    '& input::placeholder': {
                        textAlign: 'center',
                    },
                }}
            />

            </Container>


            <Container>

            <ContainedTextField
                      autoComplete = 'off'
                      variant='outlined'
                      placeholder="Telegram Link"
                      label="Telegram Link"
                      fullWidth
                      onChange={onChangeTg}
                      disabled={imagesStake.length === 0}
                      InputLabelProps={{
                          sx: {
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              width: '100%',
                              textAlign: 'center',
                              transition: 'all 0.3s ease',
                          },
                      }}
                      sx={{
                          '& .MuiInputBase-root': {
                              height: '30px',
                              textAlign: 'center',
                          },
                          '& .MuiInputLabel-shrink': {
                              top: 0,
                              left: 0,
                              transform: 'translate(14px, -50%) scale(0.75)',
                              textAlign: 'left',
                              width: 'auto',
                          },
                          '& input::placeholder': {
                              textAlign: 'center',
                          },
                      }}
                  />
                <ContainedTextField
                      autoComplete = 'off'
                      variant='outlined'
                      placeholder="X(Twitter)Link"
                      label="X(Twitter)Link"
                      fullWidth
                      onChange={onChangeX}
                      disabled={imagesStake.length === 0}
                      InputLabelProps={{
                          sx: {
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              width: '100%',
                              textAlign: 'center',
                              transition: 'all 0.3s ease',
                          },
                      }}
                      sx={{
                          '& .MuiInputBase-root': {
                              height: '30px',
                              textAlign: 'center',
                          },
                          '& .MuiInputLabel-shrink': {
                              top: 0,
                              left: 0,
                              transform: 'translate(14px, -50%) scale(0.75)',
                              textAlign: 'left',
                              width: 'auto',
                          },
                          '& input::placeholder': {
                              textAlign: 'center',
                          },
                      }}
                  />
            </Container>
        </Box>
      </>
  );
};

export default TokenAssetsSection;
