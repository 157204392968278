import React, { FC, useEffect, useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import {
  fetchPresaleUserInfoAsync,
  fetchDeployedTokensSizeAsync,
  resetDataPresale,
  resetDataTokens,
  fetchDeployedTokensSizeDBAsync
} from 'state/actions';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import MainLayout from 'layouts/MainLayout';
import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';
import { setSelectedChainId } from 'state/chain/chainSlice';
import StakerPage from 'components/ListedTokens/StakerPage';
import TokenPage from 'components/ListedTokens/TokenPage';
import PresalePage from 'pages/Presale';
import { fetchDeployedPresaleSizeAsync } from 'state/presale/presaleSlice';
import { getDeployAddresses } from 'utils/deployTokens';
import AirDropSeparatedPage from "./components/ListedTokens/AirDropSeparatedPage";
import PresaleTokensPage from 'pages/PresaleTokens';
import StakingLauncherPage from "./pages/StakingLaucher";
import {fetchDeployedStakingSizeAsync} from "./state/stakingLauncher/stakingSlice";

const OverviewPage = React.lazy(() => import('./pages/Overview'));


const Router: FC = () => {
  const { selectedChainId, isWalletChecked } = useAppSelector((state) => state.chain);
  const dispatch = useAppDispatch();
  const { account } = useWeb3React();
  const [counter, setCounter] = useState(0);
  const [counter2, setCounter2] = useState(0);
  const [lastChainId, setLastChainId] = useState(0);
  const [mainLoaded, setMainLoaded] = useState(false);

  const fetchData = async (chainId: number) => {
    try{
      if( chainId != 0 && lastChainId != chainId ){
        dispatch(resetDataPresale())
        dispatch(resetDataTokens())
      }
      setLastChainId(chainId)

      if( isWalletChecked ){
        dispatch(fetchDeployedTokensSizeDBAsync(chainId))
        dispatch(fetchDeployedPresaleSizeAsync())
        dispatch(fetchDeployedStakingSizeAsync())
        dispatch(fetchDeployedTokensSizeAsync(Number(selectedChainId), getDeployAddresses(Number(selectedChainId))))
        setMainLoaded(true);
      }
    }
    catch(err) {
      console.log(err)
    }
  };

  const fetchUserData = async () => {
    if (!account ) return;
    try{
      dispatch(fetchPresaleUserInfoAsync(selectedChainId, account));
    }
    catch(err){
      console.log(err)
    }

    try{

    }
    catch(err){

    }
  };

  useEffect(() => {
    fetchData(Number(selectedChainId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChainId, isWalletChecked]);

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChainId, account]);

  useEffect(() => {
    // fetch farm data
    const interval = setInterval(() => {

        setCounter2(counter2 + 1);

    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, selectedChainId, counter2, isWalletChecked]);

  useEffect(() => {
    if (!selectedChainId) {
          window.localStorage.setItem("selectedChainId", String(DEFAULT_ACTIVE_CHAIN_ID));
         dispatch(setSelectedChainId(String(DEFAULT_ACTIVE_CHAIN_ID)));
     }
    
    const interval = setInterval(() => {
      
      setCounter(counter + 1);
      fetchData(Number(selectedChainId));
    }, 60000);
    return () => clearInterval(interval);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, selectedChainId, isWalletChecked])

  const routes = useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { index: true, element: <Navigate replace to="/token" /> },
        { path: '/token', element: <OverviewPage isStaking={false} /> },
        { path: '/staker', element: <OverviewPage isStaking={true} /> },
        { path: '/presale', element: <PresalePage isStaking={true} /> },
        { path: '/staker/:contractAddress/:index/', element: <StakerPage isSepparatePage={true}/>},
        { path: '/staker/:contractAddress/', element: <StakerPage isSepparatePage={true}/>},
        { path: '/token/:token', element: <TokenPage /> },
        { path: '/token/:token/:chain', element: <TokenPage /> },
        { path: '/airDropPage/:contractAddress/:index/', element: <AirDropSeparatedPage/>},
        { path: '/stakingPools', element: <StakingLauncherPage isStaking={true} /> },

      ],
    },

    { path: '*', element: <Navigate replace to="/" /> },
  ]);

  return routes;
};

export default Router;
