import { FC, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import ImageUploading, { ImageType } from 'react-images-uploading';
import {ContainedButton} from "../../common/Button";

interface Props {
    imageUpload: (images: ImageType[]) => void;
    hide: boolean;
    text: string;
}

const LogoUploadBox: FC<Props> = ({ imageUpload, hide , text}) => {
    const [images, setImages] = useState<ImageType[]>([]);
    const maxNumber = 1;

    const onChange = (imageList: ImageType[]) => {
        setImages(imageList);
        imageUpload(imageList);
    };


    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: hide ? 'none' : 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                border: '0px dashed grey',
            }}
        >
            <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
            >
                {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                  }) => (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            maxHeight: '220px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: '0px dashed blue',
                            cursor: 'pointer',
                        }}
                        {...dragProps}
                    >
                        {imageList.length === 0 ? (
                            <ContainedButton
                                variant={'outlined'}
                                sx={{
                                    backgroundColor: 'rgba(0.6, 0.6, 0.1, 0.3)',
                                    borderRadius: '10px',
                                    width: '100%',
                                    height: '100%',
                                    maxHeight: '79px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    ...(isDragging ? { color: 'red' } : {}),
                                }}
                                onClick={onImageUpload}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '13px',
                                        color: "lightgray",
                                        textAlign: 'center',
                                        padding: '30px',
                                    }}
                                    color='primary'
                                    variant='h5'
                                >
                                    {text}
                                </Typography>
                            </ContainedButton>
                        ) : (
                            imageList.map((image, index) => (
                                <Box key={index} sx={{  backgroundColor: 'rgba(0.6, 0.6, 0.1, 0.3)',
                                    borderRadius: '10px',
                                    flexDirection: 'column',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',}}>
                                    <img
                                        src={image.data_url}
                                        alt=""
                                        width="100"
                                        style={{ marginTop: '10px' }}
                                    />
                                    <Button
                                        sx={{ marginTop: '10px', marginBottom: '10px' }}
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => onImageRemove(index)}
                                    >
                                        <Typography
                                            sx={{ fontSize: '13px', color: "lightgray" }}
                                            color='primary'
                                            variant='h5'
                                        >
                                            Remove
                                        </Typography>
                                    </Button>
                                </Box>
                            ))
                        )}
                    </Box>
                )}
            </ImageUploading>
        </Box>

    );
};

export default LogoUploadBox;
