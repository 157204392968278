import React from 'react';
import { Typography, Link, Box } from '@mui/material';
import {styled} from "@mui/system";

interface DescriptionProps {
    descriptionArray: string | string[];
}

const urlRegex = /(https?:\/\/[^\s]+)/g;
const Container = styled(Box)(() => ({

}));
const ValueTitle = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '400',
    color: 'white',
    whiteSpace: 'wrap',
    wordBreak: 'break-word',
    cursor: 'text',
}));

const DescriptionSplit: React.FC<DescriptionProps> = ({ descriptionArray }) => {
    const descriptions = typeof descriptionArray === 'string' ? [descriptionArray] : descriptionArray;

    const combinedText = Array.isArray(descriptionArray)
        ? descriptionArray.join(' ')
        : descriptionArray;

    const parts = combinedText.split(urlRegex);
    return (
        <Container>
            <ValueTitle>
                {parts.map((part, i) =>
                    urlRegex.test(part) ? (
                        <Link href={part} key={i} target="_blank" sx={{ color: 'white', textDecoration: 'underline' }}>
                            {part}
                        </Link>
                    ) : (
                        part
                    )
                )}
            </ValueTitle>
        </Container>
    );
};

export default DescriptionSplit;
