import {FC, useEffect, useState} from 'react';
import {Box, ClickAwayListener, MenuItem, Select, SelectChangeEvent, styled, TextField} from '@mui/material';
import React from 'react';



interface DataSelectProps {
  disabled?: boolean;
  options: any[];
  value: any;
  label: string;
  onSelect: (option: any) => void;
  width: string;
  isError: boolean;
  isReset?:boolean;
}


const DataSelect: FC<DataSelectProps> = ({ value, disabled, options, onSelect, label, width , isError, isReset}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClickAway = () => {
    setIsOpen(false);
  };

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const onSelectItem = (item: any) => {
    onSelect(item);
    setIsOpen(false);
  };

  const [age, setAge] = useState('0');

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
    onSelect(event.target.value)
  };

    useEffect(() => {
       setAge('0')
    }, [isReset]);


  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Box display='flex' justifyContent='center' sx={{ position: 'relative', zIndex: 1 , border: '0px solid red', width: '100%'}}>
      <Select

          variant='outlined'
          error={isError}
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={age}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{minHeight: '50px',
            maxWidth: '224px',
            width: '100%',
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid white",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "2px solid #4190F7",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "2px solid #4190F7",
            },
            "& .MuiSvgIcon-root": {
              color: "white",
            },
          }}

        >
          <MenuItem value={0}>
            Choose AMM
          </MenuItem>

          {options
            ?.map((chain) => (
                    <MenuItem value={chain.value}>{chain.name}</MenuItem>
          ))}
        </Select>
      </Box>
    </ClickAwayListener>
  );
};

export default DataSelect;
