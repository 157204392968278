import {FC, useEffect, useState} from 'react';
import { Box, Button, CircularProgress, TextField, Typography, styled } from '@mui/material';
import ImageUploadBox from 'components/common/UploadBox/ImapeUploadBox';
import { ImageType } from 'react-images-uploading';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setPresaleReloadCountAsync } from 'state/actions';
import { usePinata } from 'hooks/usePinata';
import CloseIcon from '@mui/icons-material/Close';
import {ContainedTextField} from "../../common/Input/ContainedTextField";
import {ContainedButton} from "../../common/Button";
import ModalDialog from "../../common/ModalDialog/ModalDialog";

const InfoCardLabel = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    cursor: 'pointer',
    textWrap: 'wrap',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
      lineHeight: '18px',
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      lineHeight: '18px',
      textAlign: 'right',
    },
  }));


const ToggleSection = styled(Box)(() => ({
    position: 'absolute',
    right: '0',
    top: '0',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '45px',
    height: '45px',
    zIndex: 1
}));


interface Props {
    close: () => void
    tokenImage: string
    stakerAddress: string
    open: boolean;
    previousSocials: any;
}

const DescriptionEdit: FC<Props> = ({ tokenImage, close, stakerAddress, open, previousSocials }) => {
    const [imageUploaded, setImageUploaded] = useState(false);
    const [images, setImages] = useState<ImageType[]>([]);
    const [buyUrl, setBuyUrl] = useState<string>(previousSocials?.buyUrl);
    const [tokenTg, setTokenTg] = useState<string>(previousSocials?.tg);
    const [tokenX, setTokenX] = useState<string>(previousSocials?.twitter);
    const [tokenWeb, setTokenWeb] = useState<string>(previousSocials?.web);
    const [customUrl1, setCustomUrl1] = useState<string>(previousSocials?.customLinks[0]?.[1]);
    const [customUrl2, setCustomUrl2] = useState<string>(previousSocials?.customLinks[1]?.[1]);
    const [customLabel1, setCustomLabel1] = useState<string>(previousSocials?.customLinks[0]?.[0]);
    const [customLabel2, setCustomLabel2] = useState<string>(previousSocials?.customLinks[1]?.[0]);
    const [customLinks, setCustomLinks] = useState<any>(previousSocials?.customLinks);
    const [isImageUploading, setIsImageUploading] = useState(false);
    const [isImageUploadingError, setIsImageUploadingError] = useState(false);
    const [imageUrlUploaded, setImageUrlUploaded] = useState("");

    const dispatch = useAppDispatch();
    const { presaleReloadCount } = useAppSelector((presale) => presale.presale);
    const {uploadImageToPinata, deleteJsonFromPinata, uploadJsonToPinata} = usePinata(0)

    const onChangeBuyUrl = (e: any) => {
      const newAmount = e.target.value;
      if( newAmount.length > 250 )
          return
        setBuyUrl(newAmount);
  };
    const onChangeTg = (e: any) => {
      const newAmount = e.target.value;
      if( newAmount.length > 250 )
          return
      setTokenTg(newAmount);
  };
  const onChangeX = (e: any) => {
      const newAmount = e.target.value;
      if( newAmount.length > 250 )
          return
      setTokenX(newAmount);
  };

  const onChangeWeb = (e: any) => {
    const newAmount = e.target.value;
    if( newAmount.length > 250 )
        return
    setTokenWeb(newAmount);
  };

    const onChangeCustomUrl1 = (e: any) => {
        const newAmount = e.target.value;
        setCustomUrl1(newAmount);
        setCustomLinks([
            [customLabel1, newAmount],
            [customLabel2, customUrl2],
        ]);
    };
    const onChangeCustomUrl2 = (e: any) => {
        const newAmount = e.target.value;
        setCustomUrl2(newAmount);
        setCustomLinks([
        [customLabel1, customUrl1],
        [customLabel2, newAmount],
        ]);
    };

    const onChangeCustomLabel1 = (e: any) => {
        const newAmount = e.target.value;
        setCustomLabel1(newAmount);
        setCustomLinks([
            [newAmount, customUrl1],
            [customLabel2, customUrl2],
        ]);
    };
    const onChangeCustomLabel2 = (e: any) => {
        const newAmount = e.target.value;
        setCustomLabel2(newAmount);
        setCustomLinks([
            [customLabel1, customUrl1],
            [newAmount, customUrl2],
        ]);
    };

    const imageUpload = (imageList: any) => {
        setImages(imageList)
      };





      const onConfirm = async () => {
        if( tokenImage ){

            setIsImageUploading(true)

            try{
                await deleteJsonFromPinata(stakerAddress)
            }
            catch(err){

            }
            try{
                await uploadJsonToPinata(stakerAddress,"", tokenTg, tokenX, tokenWeb, buyUrl, customLinks)
            }
            catch(err){

            }
            setIsImageUploading(false)
            close()
            dispatch(setPresaleReloadCountAsync(presaleReloadCount + 1));
        }
        else{
            
            if( images.length > 0 ){

                try{
                    if( images[0].file ){
                        setIsImageUploading(true)
                        const url = await uploadImageToPinata(stakerAddress, images[0].file, 1);
                        if( url.length > 0 ){

                            setIsImageUploading(false)
                            setIsImageUploadingError(false)
                            setImageUrlUploaded(url)
                        } else {
                            console.log(`Error while Image update!`)
                            setIsImageUploading(false)
                            
                            setIsImageUploadingError(true)
                        }
                    }
                }
                catch(err){
                    setIsImageUploading(false)

                }
            }
            try{
                await deleteJsonFromPinata(stakerAddress)
            }
            catch(err){

            }
            try{
                await uploadJsonToPinata(stakerAddress,"", tokenTg, tokenX, tokenWeb, buyUrl, customLinks)
            }
            catch(err){

            }
            close()
            dispatch(setPresaleReloadCountAsync(presaleReloadCount + 1));
        }
      };

  return (
    <>
        <ModalDialog open={open} onClose={close}>
            <ToggleSection onClick={close}>
                <CloseIcon sx={{width: '20px', height: '20px'}}/>
            </ToggleSection>
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' sx={{border: '0px solid blue'}}>
            <InfoCardLabel>
                Deployer Dashboard
            </InfoCardLabel>
                {tokenImage?.length > 0 &&
                                <Box mt={2} display='flex' sx={{position: 'relative'}} >
                                <img src={tokenImage?.length > 0 ? tokenImage : ""} style={{
                                    maxWidth: '155px',
                                    maxHeight: '155px',
                                    objectFit: 'cover',
                                    borderRadius: '10px'
                                }}/>
                                </Box>
                }
                {tokenImage?.length === 0 || !tokenImage &&
                    <Box justifyContent='center' alignItems='center' textAlign='center'>
                        <ImageUploadBox imageUpload={imageUpload} hide={imageUploaded}/>
                    </Box>
                }
            <Box gap={1}  display='flex' sx={{
                border: '0px solid grey',
                padding: '0px',
                marginBottom: '10px',
                width: '100%',
                position: 'relative',
                flexDirection: {xs: 'column', md: 'column'}
            }}>


                <Box gap={1}  display='flex' sx={{
                        border: '0px solid grey',
                        padding: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        position: 'relative',
                        flexDirection: {xs: 'column', md: 'row'}
                 }}>
                    <Box gap={1} flexDirection='column'  display='flex' sx={{
                            border: '0px solid grey',
                            padding: '0px',
                            marginTop: '10px',
                            width: '100%',
                            position: 'relative',
                            gap: '16px'
                    }}>
                        <ContainedTextField variant={'outlined'} disabled={images.length === 0 && !tokenImage} sx={{width: '100%'}} rows={1} multiline={true}  placeholder='Buy Token URL'
                                   onChange={onChangeBuyUrl}  autoComplete='off'
                                   label="Buy Token URL" value={buyUrl} />
                        <ContainedTextField variant={'outlined'} disabled={images.length === 0 && !tokenImage} sx={{width: '100%'}} rows={1} multiline={false}  placeholder='Telegram URL'
                                   onChange={onChangeTg}  autoComplete='off'
                                   label="Telegram URL" value={tokenTg} />
                    </Box>
                    <Box gap={1} flexDirection='column'  display='flex' sx={{
                            border: '0px solid grey',
                            padding: '0px',
                            marginTop: '10px',
                            width: '100%',
                            position: 'relative',
                            gap: '16px'
                    }}>
                        <ContainedTextField variant={'outlined'} disabled={images.length === 0 && !tokenImage} sx={{width: '100%'}} rows={1} multiline={false}  placeholder='Twitter URL'
                                   onChange={onChangeX}  autoComplete='off'
                                   label="Twitter URL" value={tokenX} />
                        <ContainedTextField variant={'outlined'} disabled={images.length === 0 && !tokenImage} sx={{width: '100%'}} rows={1} multiline={false}  placeholder='Web URL'
                                   onChange={onChangeWeb}  autoComplete='off'
                                   label="Web URL" value={tokenWeb} />
                    </Box>
                </Box>
              </Box>

            <Typography sx={{
                fontSize: '20px',
                width: '100%',
                border: '0px solid red',
                fontFamily: 'Prompt',
                fontStyle: 'normal',
                fontWeight: '600',
            }}>
                Custom Links
            </Typography>

            <Box sx={{
                display: 'flex',
                flexDirection: {xs : 'column', md: 'column'},
                gap: '16px',
                border: '0px solid red',
                width: '100%',
                position: 'relative',
                mt: '8px',
            }}>
                <Box sx={{
                    border: '0px solid blue',
                    display: 'flex',
                    flexDirection: {xs : 'column', md: 'row'},
                    gap: '8px',
                    flex: 1,
                    padding: '0px',
                    position: 'relative'
                }}>
                    <ContainedTextField variant={'outlined'} disabled={images.length === 0 && !tokenImage} sx={{width: '100%'}} rows={1} multiline={false}  placeholder='LABEL'
                                        onChange={onChangeCustomLabel1}  autoComplete='off'
                                        label="LABEL" value={customLabel1} />
                    <ContainedTextField variant={'outlined'} disabled={images.length === 0 && !tokenImage} sx={{width: '100%'}} rows={1} multiline={false}  placeholder='URL'
                                        onChange={onChangeCustomUrl1}  autoComplete='off'
                                        label="URL" value={customUrl1} />

                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: {xs : 'column', md: 'row'},
                    flex: 1,
                    gap: '8px',
                    border: '0px solid blue',
                    padding: '0px',
                    position: 'relative'
                }}>
                    <ContainedTextField variant={'outlined'} disabled={images.length === 0 && !tokenImage} sx={{width: '100%'}} rows={1} multiline={false}  placeholder='LABEL'
                                        onChange={onChangeCustomLabel2}  autoComplete='off'
                                        label="LABEL" value={customLabel2} />
                    <ContainedTextField variant={'outlined'} disabled={images.length === 0 && !tokenImage} sx={{width: '100%'}} rows={1} multiline={false}  placeholder='URL'
                                        onChange={onChangeCustomUrl2}  autoComplete='off'
                                        label="URL" value={customUrl2} />
                </Box>
            </Box>
        </Box>
              <Box display='flex' gap={'8px'} mt={2} justifyContent='center' alignItems={'center'} sx={{border: '0px solid red', width: '100%'}}>

                  <Box display='flex' justifyContent='center' alignItems={'center'} sx={{border: '0px solid red', flex: 1}}>

                    <ContainedButton onClick={onConfirm} sx={{width: '50%', height: '53.13px'}}>
                        {isImageUploading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : "Confirm"}
                    </ContainedButton>
                  </Box>
                  <Box display='flex' justifyContent='center' alignItems={'center'} sx={{border: '0px solid red', flex: 1}}>
                    <ContainedButton onClick={close} sx={{width: '50%', height: '53.13px'}}>
                        Cancel
                    </ContainedButton>
                  </Box>
                </Box>
        </ModalDialog>
    </>
  );
};

export default DescriptionEdit;