import { ApolloClient, gql, InMemoryCache } from '@apollo/client';

export const GRAPHQL_URL = 'https://based-graph.herokuapp.com/graphql';

export const graphqlClient = new ApolloClient({
    uri: GRAPHQL_URL,
    cache: new InMemoryCache(),
  });
  
  const tokensQuery = `
    query tokensQuery($limit: Int!, $skip: Int!) {
      tokensMany(limit: $limit, skip: $skip)
        {
          tokenAddress
          symbol
          decimals
          priceInUSD
          priceUSD
          priceInWFTM
          tradeVolume
        }
    }
  `;
  


  const tokensDeployedQuery = `
query getTokensCookiesV2ByChain($limit: Int!, $skip: Int!, $chain: Int!){
  getTokensCookiesV2ByChain(limit: $limit, skip: $skip, chain: $chain){
		tokenName,
    tokenAddress,
    pairAddress,
    blocked,
    priceFTM,
    dao,
    mcapFTM,
    initialPriceFtm,
    chain_id,
    lpCount,
    deployerAddress,
    tokenDeployer,
    tokenSymbol,
    lockAmount,
    supply,
    pool,
    lockEth,
    lockLiq,
    router,
    timestamp,
    elockId,
    presale
  }
}
`;

export const getDeployedTokensInfo = async ({ limit, skip, chain }: { limit?: number; skip?: number, chain: number }) => {
  try {
    const result = await graphqlClient.query({
      query: gql(tokensDeployedQuery),
      variables: {
        limit: limit || 500,
        skip: skip || 0,
        chain: chain 
      },
      fetchPolicy: 'network-only',
    });


    const arr = []
    for( let i = result.data.getTokensCookiesV2ByChain.length - 1; i >= 0; i-=1 ){
      const val = result.data.getTokensCookiesV2ByChain[i];
      if( val.blocked )
          continue
      arr.push(val)
    }
    return arr;
  } catch (err) {
    return [];
  }

  };

  const tokensDexQuery = `
  query getTokensDexCookies($limit: Int!, $skip: Int!){
    getTokensDexCookies(limit: $limit, skip: $skip){
      pairAddress,
      priceUSD,
      priceNative,
      chain,
      tokenName
    }
  }
  `;
  export const getTokensDexInfo = async ({ limit, skip }: { limit?: number; skip?: number }) => {
    try {
      const result = await graphqlClient.query({
        query: gql(tokensDexQuery),
        variables: {
          limit: limit || 500,
          skip: skip || 0,
        },
        fetchPolicy: 'network-only',
      });
      return result.data.getTokensDexCookies;
    } catch (err) {
      return [];
    }
  };

  const airdropsDexQuery = `
  query getAirdropsCookiesV2ByAddress($limit: Int!, $skip: Int!, $address: String!){
    getAirdropsCookiesV2ByAddress(limit: $limit, skip: $skip, address: $address){
      address,
      updateTimeUTC,
      poolsCount,
      startTime,
      endTime,
      rewardPerSecond,
      totalAllocPoint
    }
  }
  `;
  export const getAirdropsInfo = async ({ limit, skip,  address }: { limit?: number; skip?: number, address: string }) => {
    try {
      const result = await graphqlClient.query({
        query: gql(airdropsDexQuery),
        variables: {
          limit: limit || 500,
          skip: skip || 0,
          address: address
        },
        fetchPolicy: 'network-only',
      });
      return result.data.getAirdropsCookiesV2ByAddress;
    } catch (err) {
      return [];
    }
  };

  const airdropPoolsDexQuery = `
query getAirdropPoolsCookiesV2ByAddress($limit: Int!, $skip: Int!, $address: String!){
  getAirdropPoolsCookiesV2ByAddress(limit: $limit, skip: $skip, address: $address){
    stakerAddress,
    index,
    lockTime,
    allocPoint,
    stakingToken,
    depositFee,
    rewardToken,
    updateTime,
    updateTimeUTC
  }
}
  `;
  export const getAirdropPoolsInfo = async ({ limit, skip,  address }: { limit?: number; skip?: number, address: string }) => {
    try {
      const result = await graphqlClient.query({
        query: gql(airdropPoolsDexQuery),
        variables: {
          limit: limit || 500,
          skip: skip || 0,
          address: address
        },
        fetchPolicy: 'network-only',
      });
      return result.data.getAirdropPoolsCookiesV2ByAddress;
    } catch (err) {
      return [];
    }
  };

  const tokensBeethovenQuery = `
  query getTokensCookiesBeethovenV2ByAddress($limit: Int!, $skip: Int!, $address: String!){
    getTokensCookiesBeethovenV2ByAddress(limit: $limit, skip: $skip, address: $address){
      tokenAddress,
      poolid,
      vault,
      tokenPool,
      scc,
      locked,
      period
    }
  }
  `;
  export const getTokensBeethovenByAddress = async ({ limit, skip, address }: { limit?: number; skip?: number, address: string }) => {
    try {
      const result = await graphqlClient.query({
        query: gql(tokensBeethovenQuery),
        variables: {
          limit: limit || 500,
          skip: skip || 0,
          address: address
        },
        fetchPolicy: 'network-only',
      });
      return result.data.getTokensCookiesBeethovenV2ByAddress;
    } catch (err) {
      return [];
    }
  };

  const tokensDeployedByAddressQuery = `
  query getTokensCookiesV2ByAddress($limit: Int!, $skip: Int!, $address: String!){
    getTokensCookiesV2ByAddress(limit: $limit, skip: $skip, address: $address){
      tokenName,
      tokenAddress,
      pairAddress,
      blocked,
      priceFTM,
      dao,
      mcapFTM,
      initialPriceFtm,
      chain_id,
      lpCount,
      deployerAddress,
      tokenDeployer,
      tokenSymbol,
      lockAmount,
      supply,
      pool,
      lockEth,
      lockLiq,
      router,
      timestamp,
      elockId
    }
  }
  `;
  
  export const getDeployedTokensByAddressInfo = async ({ limit, skip, address }: { limit?: number; skip?: number, address: string }) => {
    try {

      const result = await graphqlClient.query({
        query: gql(tokensDeployedByAddressQuery),
        variables: {
          limit: limit || 500,
          skip: skip || 0,
          address: address 
        },
        fetchPolicy: 'network-only',
      });
      return result.data.getTokensCookiesV2ByAddress;
    } catch (err) {
      return [];
    }
  
    };
  const stakersQuery = `
  query getStakersCookiesV2($limit: Int!, $skip: Int!){
    getStakersCookiesV2(limit: $limit, skip: $skip){
    address,
    staker_deployer,
    deployer,
    timestamp,
    name,
    lockTime,
    isStarted,
    lastRewardTime,
    accRewardPerShare,
    depositFee,
    poolAllocPoint,
    totalAllocPoint,
    totalRewards,
    totalStaked,
    rewardTokenName,
    rewardTokenSymbol,
    rewardTokenDecimals,
    rewardTokenPriceNative,
    stakeTokenName,
    stakeTokenSymbol,
    stakeTokenDecimals,
    stakeTokenPriceNative,
    poolEndTime,
    poolStartTime,
    stakeTokenAddress,
    rewardTokenAddress,
    blocked
    }
  }
  `;
  export const getStakersInfo = async ({ limit, skip }: { limit?: number; skip?: number }) => {
    try {
      const result = await graphqlClient.query({
        query: gql(stakersQuery),
        variables: {
          limit: limit || 500,
          skip: skip || 0,
        },
        fetchPolicy: 'network-only',
      });
      const arr = []
      for( let i = result.data.getStakersCookiesV2.length - 1; i >= 0; i-=1 ){
        const val = result.data.getStakersCookiesV2[i];
        if( val.blocked )
          continue
        arr.push(val)
      }
      return arr;
    } catch (err) {
      return [];
    }
  };
