import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useState } from "react";
import { AbiItem } from 'web3-utils';
import { BigNumber, ethers } from "ethers";
import { useDispatch } from 'react-redux';
import ERC20 from 'config/abi/Erc20.json';

import {PresaleUIInfo} from "types/presale";
import { getPresaleABI, getPresaleInfo, getSmartPresaleABI } from "utils/presale";
import {useContract, useToken} from "./useContract";
import multicall from "utils/multicall";

export const usePresaleEstimateInfo = (chainId: number, info: any, amount: string) => {
    const [stat, setStat] = useState<any>();
    const contract = useContract(getSmartPresaleABI() as unknown as AbiItem, info ? info?.info?.presaleContract : '');
    
    useEffect(() => {
        if( !contract || !info ){
          setStat({value: 0});
          return;
        }
     
        async function getPresaleInfo() {
          try {

            const ftmNum = Number(amount)
             const price = Number(ethers.utils.formatEther(info.info.presaleInfo.presalePrice))
             const tokensPerFtm = Math.round(ftmNum / price);
             const val = await contract?.estimateSwap(ethers.utils.parseUnits(tokensPerFtm.toString(), 18))

             setStat({value: tokensPerFtm, estimate: val});
          } catch (err) {
            console.error(err);
          }
        }
     
        getPresaleInfo().then();
      }, [ chainId, info, contract, amount]);
      return stat;
}


export const usePresaleInfo = (chainId: number, info: any, counter: number) => {
  const { account } = useWeb3React();
  const [stat, setStat] = useState<any>();
  const contract = useContract(getSmartPresaleABI() as unknown as AbiItem, info ? info?.info?.presaleContract : '');
  
  useEffect(() => {
      if( !contract || !info ){
        setStat({totalEarned: 0, totalSold: 0, balance: 0, ftmAllowance: BigNumber.from(0)});
        return;
      }
   
      async function getPresaleInfo() {
        try {
           const totalEarned = await contract?.totalEarned();

           const totalSold = await contract?.totalSold();

           const presaleToken = await contract?.presaleToken()

           const price = await contract?.presalePrice()

           const [ftmBalance] = await multicall(
            ERC20,
            [
              {
                address: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
                name: 'balanceOf',
                params: [info?.info.presaleContract],
              },
            ]
          );
          let allowance = BigNumber.from(0);
          let accountBalance = BigNumber.from(0);
          let approoved = false
          if( account ){
            const [presaleTokenAllowance, accountTokenBalance] = await multicall(
              ERC20,
              [
                {
                  address: presaleToken,
                  name: 'allowance',
                  params: [account, contract?.address],
                },
                {
                  address: presaleToken,
                  name: 'balanceOf',
                  params:[account]
                }
              ]
            );
            allowance = presaleTokenAllowance[0]
            accountBalance = accountTokenBalance[0]
            
            approoved = allowance.gte(accountBalance);
          }

         const balanceN = Number(ethers.utils.formatEther(totalEarned.toString()))

         setStat({totalEarned: totalEarned, totalSold: totalSold, balance: balanceN, presaleTokenAllowance: allowance, presaleTokenBalance: accountBalance, presaleToken: presaleToken, approoved });
        } catch (err) {
          console.error(err);
        }
      }
   
      getPresaleInfo().then();
    }, [ chainId, info, contract, account, counter]);
    return stat;
}

export const usePresale = (chainId: number, info: PresaleUIInfo) => {
    const { account } = useWeb3React();
    const contract = useContract(getPresaleABI(chainId) as unknown as AbiItem, getPresaleInfo(chainId)[0]?.contractAddress);

    async function getPresaleAddressAndBlockHashFromTransaction(
      // eslint-disable-next-line
      tx: any
    ): Promise<{ presaleAddress: string; blockHash: string }> {
      const receipt = await tx.wait(1);
      if (receipt.status !== 1) {
        throw new Error();
      }
      const events = receipt.events.filter((e: { event: string }) => e.event === 'OwnershipTransferred');
      return { presaleAddress: events[0].address, blockHash: receipt.blockHash };
    }
    const handlePresale= useCallback(
        async (info: PresaleUIInfo): Promise<string | undefined> => {
    
          if (!account || !contract || !info  ) return '';

          const now = new Date().getTime();

          let deployInfo = {presalePrice: ethers.utils.parseUnits(info.presaleTokenPrice,18),
            presaleStartTime: Number(info.presaleStartsIn) === 0 ? (now / 1000 + (Number('0.00347') * 86400)).toFixed(0) : (now / 1000 + (Number(info.presaleStartsIn) * 86400)).toFixed(0),// 86400,
            presaleDuration: (Number(info.presaleLen) * 86400).toFixed(0),//86400,
            minAddressBuy: ethers.utils.parseUnits(info.minBuy,18),
            maxAddressBuy: ethers.utils.parseUnits(info.maxBuy,18),
            softCap:ethers.utils.parseUnits(info.softCap,18),
            hardCap:ethers.utils.parseUnits(info.hardCap,18),
            tokensForPresale:ethers.utils.parseUnits(info.tokensPresale,18)
          }
          let equalRouter = "0x2aa07920E4ecb4ea8C801D9DFEce63875623B285";

          if( info.isLiquidityChecked ){
            let tokenInfo = {totalSupply:  ethers.utils.parseUnits(info.totalSupply,18),
              tokenName: info.tokenName,
              tokenSymbol: info.tokenSymbol,
              router: info.amm.address,
              SCC:"0x0000000000000000000000000000000000000000",
              tokenLaunchPrice: ethers.utils.parseUnits(info.launchPrice,18),
              lockLiquidity: true,
              lockPeriod: Number(info.lockDuration)* 86400 + 1
              }
              let tx = await contract.createPresale(deployInfo,tokenInfo)
              const { presaleAddress, blockHash } = await getPresaleAddressAndBlockHashFromTransaction(tx)
              const receipt = await tx.wait();
              if (receipt.status !== 1) {
                throw new Error();
              }
              return presaleAddress;
          }
          if( info.isElock ){
            let tokenInfo = {totalSupply:  ethers.utils.parseUnits(info.totalSupply,18),
              tokenName: info.tokenName,
              tokenSymbol: info.tokenSymbol,
              router: "0x2aa07920E4ecb4ea8C801D9DFEce63875623B285",
              SCC:"0x0000000000000000000000000000000000000000",
              tokenLaunchPrice: ethers.utils.parseUnits(info.launchPrice,18),
              lockLiquidity: true,
              lockPeriod: Number(info.lockDuration)* 86400 + 1
              }
              let tx = await contract.createPresale(deployInfo,tokenInfo)
              const { presaleAddress, blockHash } = await getPresaleAddressAndBlockHashFromTransaction(tx)
              return presaleAddress;
          }
          
          if( info.isLiquidityCreateChecked ){
            let tokenInfo = {totalSupply:  ethers.utils.parseUnits(info.totalSupply,18),
              tokenName: info.tokenName,
              tokenSymbol: info.tokenSymbol,
              router: info.amm.address,
              SCC: account,
              tokenLaunchPrice: ethers.utils.parseUnits(info.launchPrice,18),
              lockLiquidity: true,
              lockPeriod: 0
              }
              let tx = await contract.createPresale(deployInfo,tokenInfo)
              const { presaleAddress, blockHash } = await getPresaleAddressAndBlockHashFromTransaction(tx)
              const receipt = await tx.wait();
              if (receipt.status !== 1) {
                throw new Error();
              }
              return presaleAddress;
          }

          let tokenInfo = {totalSupply:  ethers.utils.parseUnits(info.totalSupply,18),
            tokenName: info.tokenName,
            tokenSymbol: info.tokenSymbol,
            router: info.amm.address,
            SCC:"0xb48c4eBffc738e57479904BB14Cb8F8A2F7AC5Ee",
            tokenLaunchPrice: ethers.utils.parseUnits(info.launchPrice,18),
            lockLiquidity: true,
            lockPeriod: 0
            }
            let tx = await contract.createPresale(deployInfo,tokenInfo)
            const { presaleAddress, blockHash } = await getPresaleAddressAndBlockHashFromTransaction(tx)
            const receipt = await tx.wait();
            if (receipt.status !== 1) {
              throw new Error();
            }
            return presaleAddress;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [account, contract, chainId, info]
      );

    return {
        onPresale: handlePresale,
    }
}

export const useSmartPresale = (chainId: number, info: any, presaleInfo: any) => {
  const { account } = useWeb3React();
  const contract = useContract(getSmartPresaleABI() as unknown as AbiItem, info ? info?.info?.presaleContract : '');
  const contractDeployer = useContract(getPresaleABI(chainId) as unknown as AbiItem, info ? info?.deployerAddress : '');

  const dispatch = useDispatch();
  const tokenContractFTM = useToken( presaleInfo?.presaleToken ? presaleInfo.presaleToken : "");

  const handleBuyToken= useCallback(
      async (amount: string, estimate: string): Promise<string | undefined> => {
        
        if (!account || !contract || !info  ) return '';

          
          const options = {value: ethers.utils.parseEther(amount.toString())}
          let tx = await contract.buyPreasaleToken(ethers.utils.parseEther(estimate.toString()), options)
          
          const receipt = await tx.wait();
          if (receipt.status !== 1) {
            throw new Error();
          }
         return tx.txHash;
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [account, contract, chainId, info]
    );

    const handleRedeem = useCallback(
      async (amount: BigNumber): Promise<string | undefined> => {
        
        if (!account || !contract || !info  ) return '';

          let tx = await contract.exchangePresaleToken(amount)
          
          const receipt = await tx.wait();
          if (receipt.status !== 1) {
            throw new Error();
          }
         return tx.txHash;
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [account, contract, chainId, info]
    );

    const handleClaim = useCallback(
      async (amount: BigNumber): Promise<string | undefined> => {
        
        if (!account || !contract || !info  ) return '';

          let tx = await contract.buyBack(amount)
          
          const receipt = await tx.wait();
          if (receipt.status !== 1) {
            throw new Error();
          }
         return tx.txHash;
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [account, contract, chainId, info]
    );

    const handleLaunch= useCallback(
      async (amount: string): Promise<string | undefined> => {

        if (!account || !contractDeployer || !info  ) return '';

          let tx = await contractDeployer.launch(info.presaleAddress)
          
          const receipt = await tx.wait();
          if (receipt.status !== 1) {
            throw new Error();
          }
         return tx.txHash;
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [account, contractDeployer, chainId, info, tokenContractFTM]
    );

    const handleApprovePurchaseToken = useCallback(async (): Promise<string | undefined> => {
      if (!account || !contract || !tokenContractFTM ) return '';
      const tx = await tokenContractFTM.approve(contract.address, ethers.constants.MaxUint256);
  
      const receipt = await tx.wait();
  
      if (receipt.status !== 1) {
        throw new Error();
      }
  
      return tx.txHash;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, dispatch, contract, tokenContractFTM]);

  return {
      onBuyToken: handleBuyToken,
      onApproveFTM: handleApprovePurchaseToken,
      onRedeem: handleRedeem,
      onClaim: handleClaim,
      onLaunch: handleLaunch
      
  }
}
