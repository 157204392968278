import { FC, useEffect, useRef, useState } from 'react';
import {Box, CircularProgress, styled, Tab, Tabs, TextField, Tooltip, Typography} from '@mui/material';
import { BigNumber, utils } from 'ethers';
import { useAppSelector } from 'state/hooks';
import { ConnectWallet, ContainedButton } from 'components/common/Button';
import {getBalanceInEther, getBalanceInWei,} from 'utils';
import { ContainedInput } from 'components/common/Input';
import { useWeb3React } from '@web3-react/core';
import { useStake, } from 'hooks/useStakeLauncherInfo';

const Container = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: "transparent",

    [theme.breakpoints.up('xs')]: {
        padding: '0px 16px',
    },
    [theme.breakpoints.up('md')]: {
        padding: '0px 32px',
    },
}));

const Body = styled(Box)(({ theme }) => ({
    border: '0px solid red',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('xs')]: {
        flexDirection: 'column',
        gap: '16px'

    },
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        flexDirection: 'row',
        gap: '4px'
    },
}));


const GlobalInfoCard = styled(Box)(({ theme }) => ({
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',

    [theme.breakpoints.up('xs')]: {
        padding: '10px 0px',
    },
    [theme.breakpoints.up('md')]: {
        padding: '15px 0px',
    },
}));

const GlobalInfoCardLabel = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    width: '100%',
    textWrap: 'wrap',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
        fontSize: '12px',
        lineHeight: '18px',
        img: {
            display: 'none',
        },
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '14px',
        lineHeight: '18px',
        img: {
            display: 'block',
        },
    },
}));


const ActionButton = styled(ContainedButton)(() => ({
    maxWidth: '102px',
    height: '30px',
}));


const AmountInput = styled(ContainedInput)(() => ({
    height: '30px',
    maxWidth: '200px',
}));


const PoolInfoLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '16px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',
    width: '100%',
    [theme.breakpoints.up('xs')]: {
        display: 'block',
        textAlign: 'left',
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
        whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
        display: 'block',
        textAlign: 'left',
        whiteSpace: 'break-spaces',
    },
}));

const PoolInfoLabelEnd = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '16px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'right',
    width: '100%',

    [theme.breakpoints.up('xs')]: {
        display: 'block',
        textAlign: 'right',
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
        whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
        display: 'block',
        textAlign: 'right',
        whiteSpace: 'break-spaces',
    },
}));

interface PoolCardProps {
    pool: any;
    type: string;
    info?: any;
    onUpdate: () => void
    isClaimable?: boolean
}

const StakingTabPanel: FC<PoolCardProps> = ({ pool, type, info, onUpdate, isClaimable }) => {
    const { pendingTxHash } = useAppSelector((state) => state.modal);
    const pendingTxHashRef: { current: string | undefined } = useRef();
    pendingTxHashRef.current = pendingTxHash;
    const [amount, setAmount] = useState<string>('');
    const [amountInWei, setAmountInWei] = useState<BigNumber>(BigNumber.from(0));
    const { account } = useWeb3React();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [balance, setBalance] = useState<number>(0);
    const [allowance, setAllowance] = useState<number>(0);
    const [allowanceWei, setAllowanceInWei] = useState<BigNumber>(BigNumber.from(0));
    const { selectedChainId } = useAppSelector((state) => state.chain);

    const {onApprove, onStake, onUnstake} = useStake(Number(selectedChainId), amount, info, pool)
    const isApproved = account ? allowanceWei.gte(amountInWei) : false

    useEffect(() => {
        if( info  ){
            try{
                if( type === "Stake" ){
                    
                    setBalance(Number(utils.formatUnits(info.accountBalance, info.decimals)))
                    setAllowance(Number(utils.formatUnits(info.allowance, info.decimals)));
                    setAllowanceInWei(info?.allowance)
                }
                if( type === "Unstake"){
                    setBalance(Number(utils.formatUnits(info.userStaked, info.decimals)))
                }
            }
            catch(err){
                //
            }
        }
    }, [info]);


    useEffect(() => {
        if( balance && type === 'Unstake' && isClaimable ){
            setAmount((utils.formatUnits(info.userStaked, info.decimals)))
            setAmountInWei(info?.userStaked);
        } else if( balance && type === 'Unstake' && !isClaimable ){
            setAmount((utils.formatUnits(info.userStaked, info.decimals)))
            setAmountInWei(info?.userStaked);
        }
    }, [balance, info]);

    const getButtonText = () => {
        if( type === 'Stake' ){
            if( !isApproved ) {
                return "Approve";
            }
        }
        if( type === 'Unstake' && isClaimable ){
            return "Unstake & Claim"
        }
        return type;
    };

    const onConfirm = async () => {
        setLoading(true);
        try {
            if( type === 'Stake'){
                if( !isApproved ){
                    setLoading(true)
                    await onApprove()
                    onUpdate()
                } else {
                    setLoading(true)
                    await onStake(amountInWei.toString())
                    onUpdate()
                }
            }
            if( type === 'Unstake' ){
                setLoading(true)

                await onUnstake(amountInWei.toString())
                onUpdate()
            }
            if( type === 'Claim' ){
                setLoading(true)
                await onUnstake("0")
                onUpdate()
            }
        } catch (err) {

        }
        setLoading(false);
    };

    const onMax = async () => {
        setAmount(balance?.toString())
        setAmountInWei(info?.accountBalance);
        if( type === "Unstake"){
            setAmount((utils.formatUnits(info.userStaked, info.decimals)))
            setAmountInWei(info?.userStaked);
        }
    };

    const onChangeAmount = (e: any) => {
        const newAmount = e.target.value;
        setAmount(newAmount);
        setAmountInWei(getBalanceInWei(Number(newAmount).toString() || '0', pool?.stakeTokenDecimals));
    };

    const getNoteText = () => {
        if( type === 'Stake')
        {
            return "After staking is initiated timer will be set to 72 hours. In order to claim your rewards with no penalty to initial staked amount , timer needs to hit 0."
        }
        else if (type === 'Unstake') {
            return "If you want to initiate Unstake while Timer is not expired (72 hours have not passed since you staked) you will lose 10% of the amount that is being unstaked and all accumulated rewards are reset to 0."
        }
        else if( type === 'Claim' ){
            return "Claim can be initiated only when Timer is expired. After rewards are claimed, Timer will be reset to 72 hours."
        }
        return type;
    };
    const getNoteText2 = () => {
        if( type === 'Stake')
        {
            return `Staking more ${info?.tokenName} tokens on top of existing staked amount will reset the timer to 72 hours.`
        }
        if( type === 'Claim' ){
            return `Please use UNSTAKE & CLAIM button if you want to withdraw initial staked ${info?.tokenName} tokens with accumulated rewards.`
        }
        return "";
    };
    const getNoteText3 = () => {
        if( type === 'Stake') {
            return `If staking more ${info?.tokenName} tokens while timer didnt expire, your accrued rewards go to 0.`
        }
        if( type === 'Claim' ){
            return "If you initiate claim transaction before timer hits 0, you will receive 0 rewards."
        }
        return "";
    };

    return (
        <>
            <Container>
                {/*  */}
                <Body>
                    { type !== 'Claim' &&
                        <Box display='flex' flexDirection='column' sx={{minWidth: "200px", border: '0px solid red'}}>
                            <AmountInput onChange={onChangeAmount}  onClickMax={() => {}} value={amount} />
                            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{minHeight: '34px', borderRadius: '10px'}}>
                                <PoolInfoLabel>
                                    Balance
                                </PoolInfoLabel>
                                <PoolInfoLabelEnd>
                                    {balance?.toFixed(4)}
                                </PoolInfoLabelEnd>
                            </Box>
                        </Box>
                    }
                    {account ? (
                        <>

                            { type !== 'Claim' &&
                                <ActionButton variant='outlined'  disabled={false} onClick={onMax} sx={{ maxWidth: '40px' }}>
                                    Max
                                </ActionButton>
                            }
                            { type !== 'Claim' &&
                            <ActionButton variant='outlined'  disabled={ amount === '' || balance===0 || Number(amount) > Number(balance)} onClick={onConfirm} sx={{ minWidth: '150px', width: '100%'}}>
                                {isLoading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : getButtonText()}
                            </ActionButton>
                            }
                            { type === 'Claim' &&
                            <ActionButton variant='outlined'  disabled={ getBalanceInEther(info?.pendingRewards) === 0 || !isClaimable} onClick={onConfirm} sx={{ minWidth: '150px', width: '100%'}}>
                                {isLoading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : getButtonText()}
                            </ActionButton>
                            }
                        </>

                    ) : (
                        <ConnectWallet  width={150} />
                    )}
                </Body>
                <Box display='flex' sx={{width: '100%'}} flexDirection='column' justifyContent='center' alignItems='center'>
                    <GlobalInfoCard>
                        <GlobalInfoCardLabel>{`*${getNoteText()}`}</GlobalInfoCardLabel>
                        <GlobalInfoCardLabel sx={{display: getNoteText2().length === 0 ? 'none' : ''}}>{`*${getNoteText2()}`}</GlobalInfoCardLabel>
                        <GlobalInfoCardLabel sx={{display: getNoteText3().length === 0 ? 'none' : ''}}>{`*${getNoteText3()}`}</GlobalInfoCardLabel>
                    </GlobalInfoCard>
                </Box>
            </Container>
        </>
    );
};

export default StakingTabPanel;
