import { FC,  useEffect, useState} from 'react';
import {Box, Button, Grid, Link, styled, Typography} from '@mui/material';
import {ContainedButton,} from 'components/common/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import useCountDownUp from 'hooks/useCountDownUp';
import StakingPool from "./StakingPool";
import {InfoLabelBegin, InfoLabelEnd} from "../common/Typography/InfoLabel";
import DescriptionEdit from "./Components/DescriptionEdit";
import EditIcon from "@mui/icons-material/Edit";
import {useWeb3React} from "@web3-react/core";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import {useAppSelector} from "../../state/hooks";
import { CHAIN_INFO } from 'config/constants/chains';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ExtendedTimer from "../common/TimeComponent/ExtendedTimer";

const Container = styled(Box)(({theme}) => ({
    position: 'relative',
    width: '100%',
    marginTop: '30px',
    background: "rgba(42,49,57, 0.4)",
    display: 'flex',
    maxWidth: '900px',
    borderRadius: '10px',
    flexDirection: 'column',
    minHeight: '700px',
    minWidth: '300px',
    [theme.breakpoints.up('xs')]: {
        padding: '0px 16px',
        minWidth: '280px',
    },
    [theme.breakpoints.up('md')]: {
        padding: '20px 32px',
    },
}));


const PoolName = styled(Typography)(({ theme }) => ({
    border: '0px solid green',
    fontFamily: 'Prompt',
    fontWeight: '600',
    cursor: 'text',
    fontSize: '24px',
    lineHeight: '24px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',

    [theme.breakpoints.up('xs')]: {
        fontSize: '24px',
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {

    },
    [theme.breakpoints.up('md')]: {

    },
}));

const PoolSymbol = styled(Typography)(({theme}) => ({
    border: '0px solid red',
    fontFamily: 'Prompt',
    fontWeight: '600',
    fontSize: '24px',
    
    lineHeight: '24px',
    whiteSpace: 'break-spaces',
    textAlign: 'center',
    margin: 0,
    padding: 0,
    display: 'inline',

    [theme.breakpoints.up('xs')]: {
        margin: 0,
        padding: 0,
        display: 'inline',
        fontSize: '24px',

        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
        whiteSpace: 'break-spaces',
        fontSize: '24px',

    },
    [theme.breakpoints.up('md')]: {

        whiteSpace: 'break-spaces',
        fontSize: '24px',

    },
}));


// modal body
const ModalBody = styled(Box)(() => ({
    marginTop: '23px',
    marginBottom: '21px',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    gap: '20px'
}));

interface Props {
    index?: number,
    stakerInfo?: any,
    isSepparatePage: boolean,
    images: any,
    onClose?: () => void
}

const StakingModal: FC<Props> = ({index, isSepparatePage, stakerInfo, onClose, images}) => {
    const { account } = useWeb3React();
    const { selectedChainId } = useAppSelector((state) => state.chain);
    const [isPoolsStarted, setIsPoolsStarted] = useState<boolean>(false);
    const [isPoolsEnded, setIsPoolsEnded] = useState<boolean>(false);
    const [isShowTime, setIsShowTime] = useState<boolean>(false);
    const [poolStartTime, setPoolStartTime] = useState<Number>(0);
    const [poolEndTime, setPoolEndTime] = useState<Number>(0);
    const [buyUrl, setBuyUrl] = useState<string>("");
    const [rewardTokenImage, setRewardTokenImage] = useState<string>("");
    const [isDescriptionEdit, setIsDescriptionEdit] = useState(false);

    const [days, hours, minutes, seconds] = useCountDownUp(isShowTime ? Number(isPoolsStarted ? poolEndTime : poolStartTime) * 1000 : new Date().getUTCSeconds() * 1000);
    const truncate = (str: string, n: number) => {
        return str?.length > n ? `${str.substr(0, n ) } ... ${str.substr(str.length - n, n) }`: str;
    };

    function checkProtocolAndOpen(url: any) {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            const httpsUrl = 'https://' + url;
            window.open(httpsUrl);
        } else {
            window.open(url);
        }
    }


    useEffect(() => {
        if( images && stakerInfo ){
          {
            try{
                setBuyUrl(images[stakerInfo.address.toLowerCase()]?.buyUrl)
                setRewardTokenImage(images[stakerInfo.address.toLowerCase()]?.imageUrl)
            }
            catch(err){

            }

          }
        }
    }, [images, stakerInfo]);

    useEffect(() => {
        if( stakerInfo ){
            setPoolStartTime(stakerInfo.poolStartTime)
            setPoolEndTime(stakerInfo.poolEndTime)
            const currentDate = Math.floor((new Date()).getTime() / 1000)
            if( currentDate >= stakerInfo.poolStartTime ){
                setIsShowTime(true)
                setIsPoolsStarted(true)
            }
            if( currentDate >= stakerInfo.poolEndTime ){
                setIsShowTime(false)
                setIsPoolsEnded(true)
            }
        }
    }, [stakerInfo]);

    function goToChainExplorer(selectedChainId: number, address: string){
        checkProtocolAndOpen(CHAIN_INFO[selectedChainId].explorer + 'address/' + address)
    }



    return (
        <> <Container>
            {/* body */}
            <ModalBody sx={{position: 'relative'}}>
                {!isSepparatePage &&
                    <>
                        <Box onClick={onClose} display='flex' position='absolute' sx={{
                            top: {xs: -50, md: -50},
                            left: {xs: -0, md: -50},
                            border: '1px solid #5E6980',
                            borderRadius: '15px',
                            padding: '10px',
                            background: '#2A3139',
                            cursor: 'pointer'
                        }}>
                            <ArrowBackIosNewIcon sx={{width: '20px', height: '20px'}}/>
                        </Box>
                    </>
                }

                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} sx={{width: '100%'}}>
                    <Box mt={2} gap='30px' display='flex' justifyContent={'space-between'} alignItems={'center'}
                         sx={{border: '0px solid red', width: '100%',height: {xs:'400px', md: '300px'}, flexDirection: {xs: 'column', md: 'row'}}}>
                        <Box
                            display='flex'
                            flexDirection='column'
                            alignItems='center'
                            sx={{
                                border: '0px solid blue',
                                width: '100%',
                                height: '100%',
                                paddingTop: {xs: '0px', md: '100px'}
                            }}
                        >

                            <Grid container alignItems="center" justifyContent="center" >
                                <Grid item justifyContent='center' alignItems="center" xs={12} sx={{border: '0px solid red', }}>
                                    <PoolName sx={{display: {xs: 'flex', md: 'flex'}, textAlign: 'center', width: '100%'}}>
                                        STAKE
                                    </PoolName>
                                </Grid>
                                <Grid item xs={12} sx={{border: '0px solid red'}}>
                                    <PoolSymbol>
                                        {stakerInfo?.stakeTokenName}

                                    </PoolSymbol>
                                </Grid>
                            </Grid>
                        </Box>

                            <Box sx={{display: 'flex', position: 'relative', width: '180px',
                                height: '200px',
                                minWidth: '240px',
                                minHeight: '260px',
                                objectFit: 'cover',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                //borderRadius: '50%',
                                border: '0px solid red'}}>
                                <Box display={isDescriptionEdit ? '': 'none'}>
                                    <DescriptionEdit tokenImage={rewardTokenImage} stakerAddress={stakerInfo?.address} close={() => {setIsDescriptionEdit(false)}}
                                                     open={isDescriptionEdit} previousSocials={images[stakerInfo.address.toLowerCase()]}/>
                                </Box>
                                <Button onClick={() => {setIsDescriptionEdit(!isDescriptionEdit)}} sx={{maxWidth: '15px',position: 'absolute',
                                    display: stakerInfo?.deployer === account ? '' : 'none',
                                    top:0,  right: 0, background: 'rgba(16,69,79,0.8)'}}>
                                    <EditIcon sx={{fill: '#BDE038'}} />
                                </Button>

                                <img src={rewardTokenImage} style={{
                                    width: '180px',
                                    height: '180px',
                                    minWidth: '180px',
                                    minHeight: '180px',
                                    objectFit: 'cover',
                                    borderRadius: '50%',
                                    border: '2px solid white'
                                }}/>
                            </Box>

                        <Box
                            display='flex'
                            flexDirection='column'
                            alignItems='center'
                            sx={{
                                border: '0px solid blue',
                                width: '100%',
                                height: '100%',
                                paddingTop: {xs: '0px', md: '100px'}
                            }}
                        >
                            <Grid container alignItems="center" justifyContent="center">
                                <Grid item xs={12} alignItems="center" justifyContent="center" sx={{border: '0px solid red'}}>
                                    <PoolName sx={{
                                        display: { xs: 'flex', md: 'flex' },

                                    }}>
                                        EARN
                                    </PoolName>
                                </Grid>
                                <Grid item xs={12} sx={{border: '0px solid red'}}>
                                    <PoolSymbol>
                                        {stakerInfo?.rewardTokenName}
                                    </PoolSymbol>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </ModalBody>
            <Grid container display='flex' spacing={1} justifyContent='center' sx={{border: '0px solid red'}}>
                <Grid item xs={12} md={6}>
                    <Box display='flex' flexDirection='column' gap={1} sx={{border: '0px solid red'}}>
                        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{
                            background: "rgba(6,1,1,0.3)",
                            padding: '5px',
                            minHeight: '34px',
                            borderRadius: '10px',
                            minWidth: '200px',
                            width: '100%'
                        }}>
                            <InfoLabelBegin>
                                {stakerInfo?.stakeTokenSymbol}
                                {stakerInfo?.stakeTokenSymbol.length > 15 ? '' : ' Address'}
                            </InfoLabelBegin>

                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={()=>{goToChainExplorer(Number(selectedChainId), stakerInfo?.stakeTokenAddress)}}
                                style={{maxHeight: '24px',borderRadius: '0px',   display: stakerInfo?.rewardTokenAddress ? "" : "none", width: '190px', }}
                            >
                                <Typography style={{fontSize: '11px', color: "lightgray"}} color='primary' variant={'h5'} >{truncate(stakerInfo?.stakeTokenAddress, 4)}
                                </Typography>
                                <OpenInNewIcon sx={{width: 'auto', height: '25px', position: 'relative', marginLeft: '5px'}}/>
                            </Button>
                        </Box>
                        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{
                            background: "rgba(6,1,1,0.3)",
                            padding: '5px',
                            minHeight: '34px',
                            borderRadius: '10px',
                            minWidth: '200px'
                        }}>
                            <InfoLabelBegin sx={{minWidth: '120px'}}>
                                {stakerInfo?.stakeTokenSymbol}
                                {stakerInfo?.stakeTokenSymbol.length > 16 ? '' : ' Price'}
                            </InfoLabelBegin>
                            <InfoLabelEnd>
                                {stakerInfo?.stakeTokenPrice === 0 ? '--.--' : stakerInfo?.stakeTokenPrice.toFixed(4)}{" " + stakerInfo?.nativeSymbol}
                            </InfoLabelEnd>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box display='flex' flexDirection='column' gap={1}>
                        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{
                            background: "rgba(6,1,1,0.3)",
                            minHeight: '34px',
                            padding: '5px',
                            borderRadius: '10px',
                            minWidth: '200px'
                        }}>
                            <InfoLabelBegin>
                                {stakerInfo?.rewardTokenSymbol} Address
                            </InfoLabelBegin>
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={()=>{goToChainExplorer(Number(selectedChainId), stakerInfo?.rewardTokenAddress)}}
                                style={{maxHeight: '24px',borderRadius: '0px',   display: stakerInfo?.rewardTokenAddress ? "" : "none", width: '190px', }}
                            >
                                <Typography style={{fontSize: '11px', color: "lightgray"}} color='primary' variant={'h5'} >{truncate(stakerInfo?.rewardTokenAddress, 4)}
                                </Typography>
                                <OpenInNewIcon sx={{width: 'auto', height: '25px', position: 'relative', marginLeft: '5px'}}/>
                            </Button>
                        </Box>
                        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{
                            background: "rgba(6,1,1,0.3)",
                            minHeight: '34px',
                            padding: '5px',
                            borderRadius: '10px',
                            minWidth: '200px'
                        }}>
                            <InfoLabelBegin>
                                {stakerInfo?.rewardTokenSymbol} Price
                            </InfoLabelBegin>
                            <InfoLabelEnd>
                                {stakerInfo?.rewardTokenPrice  === 0 ? '--.--' : stakerInfo?.rewardTokenPrice.toFixed(4)}{" " + stakerInfo?.nativeSymbol}
                            </InfoLabelEnd>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Box mt={5} sx={{
                border: '0px solid red',
                display: 'flex',
                flexDirection: {xs: 'column', md: 'row'},
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                gap: 2,

            }}>
                <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{
                    background: "rgba(6,1,1,0.3)",
                    minHeight: '34px',
                    padding: '5px',
                    borderRadius: '10px',
                    flex: 1
                }}>
                    <InfoLabelBegin>
                        Rewards {`${ isPoolsStarted ? isPoolsEnded ? 'ended' : 'ends in' : 'starts in' }`}
                    </InfoLabelBegin>
                    <InfoLabelEnd>
                        {isShowTime && !isPoolsEnded ? (
                            <ExtendedTimer days={days} hours={hours} minutes={minutes} seconds={seconds}/>
                        ) : ``}
                    </InfoLabelEnd>
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    maxHeight: '50px',
                    flex: 1
                }}>


                    {buyUrl?.length > 0 &&
                        <ContainedButton onClick={() => {checkProtocolAndOpen(buyUrl)}}
                                         sx={{maxWidth: '200px', height: '100%'}}>
                            Buy {stakerInfo?.stakeTokenSymbol}
                        </ContainedButton>
                    }

                </Box>

            </Box>

            <>
                <Box mt={2} gap='10px' display='flex' flexDirection='column'>
                    {stakerInfo?.subPool?.map((item:any) => (
                        <StakingPool key={item.subPoolId} tokenInfo={item} poolInfo={stakerInfo} />
                    ))}
                </Box>

            </>

        </Container>
        </>
    );
};

export default StakingModal;
