import React from 'react';
import { Box, BoxProps } from '@mui/material';


interface LogoBoxProps extends BoxProps {
    imageUrl?: string;
    imageStyles?: React.CSSProperties;
}

const LogoBox: React.FC<LogoBoxProps> = ({
                                             imageUrl = "",
                                             imageStyles = {},
                                             sx = {},
                                             ...props
                                         }) => {
    return (
        <Box
            sx={{
                width: '120px',
                height: '140px',
                border: imageUrl.length > 0 ? "none": '1px solid grey',
                borderRadius: '10px',
                ...sx,
            }}
            {...props}
        >
            {imageUrl && (
                <img
                    src={imageUrl}
                    style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                        borderRadius: '10px',
                        ...imageStyles,
                    }}
                />
            )}
        </Box>
    );
};

export default LogoBox;
