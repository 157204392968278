import { FC, useEffect, useState } from 'react';
import { Box, Grid, styled, Typography } from '@mui/material';

import { useAppSelector } from 'state/hooks';
import DeployStakingPool from "../components/DeployStakingPool/DeployStakingPool";
import ListedStaking from "../components/ListedStaking/ListedStaking";



// overview section
const OverviewSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    // gap: '20px',
    background: 'transparent',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    [theme.breakpoints.up('xs')]: {
        flexDirection: 'column',
    },
    [theme.breakpoints.up('sm')]: {
        flexDirection: 'column',
    },
}));


interface Props {
    isStaking: boolean
}

const StakingLauncherPage: FC<Props> = ({ isStaking }) => {

    const [isStakingMiniOpen, setStakingMiniOpen] = useState<boolean>(false)

    const handleOpenStakingMini = (state: boolean) =>
    {
        setStakingMiniOpen(state);
    }

    return (
        <>
            <OverviewSection mt={5}>
                {isStaking && !isStakingMiniOpen &&
                    <Box mt={2} flexDirection={'column'} display='flex' justifyContent='center' alignItems='center' textAlign='center' sx={{}}>
                        <DeployStakingPool/>
                    </Box>
                }

                <Box mt={2} flexDirection={'column'} display='flex' justifyContent='center' alignItems='center' textAlign='center' sx={{width: '100%'}}>
                    <ListedStaking isStaking={isStaking} handleOpenStakingMini={handleOpenStakingMini}/>
                </Box>
            </OverviewSection>
        </>
    );
};

export default StakingLauncherPage;
