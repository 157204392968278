import { FC, useEffect, useRef, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    Grid,
    Link,
    styled,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import { useAppSelector } from 'state/hooks';
import {ConnectWallet, ContainedBlueButton, ContainedButton} from 'components/common/Button';
import { getBalanceInEther } from 'utils/formatBalance';
import {  ethers } from 'ethers';
import {  getRouters } from 'utils/deployTokens';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CloseIcon from '@mui/icons-material/Close';
import useCountDownUp from 'hooks/useCountDownUp';
import { useLocker } from 'hooks/useLocker';
import { ImageType } from 'react-images-uploading';
import axios from 'axios';
import ProgressBar from 'components/common/ ProgressBar/ ProgressBar';
import {  usePresaleEstimateInfo, usePresaleInfo, useSmartPresale } from 'hooks/usePresaleInfo';
import TokenomicsTab from "./Components/TokenomicsTab";
import LanguageIcon from '@mui/icons-material/Language';
import XIcon from '@mui/icons-material/X';
import TelegramIcon from '@mui/icons-material/Telegram';
import { useEthBalance } from 'hooks/useEthBalance';
import DescriptionEdit from './Components/DescriptionEdit';
import EditIcon from '@mui/icons-material/Edit';
import ContainedInfoBox from "../../common/InfoBox/ContainedInfoBox";
import {InfoLabelBegin, InfoLabelEnd} from "../../common/Typography/InfoLabel";
import LogoBox from "../../common/LogoContainer/LogoBox";
import ModalDialog from "../../common/ModalDialog/ModalDialog";
import RouterLogo from "../../common/LogoContainer/RouterLogo";
import {ContainedTextField} from "../../common/Input/ContainedTextField";
import BlueLinkIcon from "../../common/icons/CustomLink";


// toggle section
const ToggleSection = styled(Box)(() => ({
    position: 'absolute',
    right: '0',
    top: '0',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '45px',
    height: '45px',
    zIndex: 1
}));

const ModalHeader = styled(Grid)(({ theme }) => ({
    gap:'16px',
    marginBottom: '20px',
    border: '0px solid red',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '165px',

    [theme.breakpoints.up('xs')]: {
        container: true,
        height: 'auto',
        mb: 20,
        flexDirection: 'column',
    },
    [theme.breakpoints.up('md')]: {
        container: true,
        mb: 20,
        flexDirection: 'row',
    },
}));

const RowItem = styled(Grid)(({ theme }) => ({
    border: '0px solid green',
    display: 'flex',
    gap: '16px',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    minHeight: '165px',
    [theme.breakpoints.up('xs')]: {
        item: true,
        flexDirection: 'column',
    },
    [theme.breakpoints.up('md')]: {
        item: true,
        flexDirection: 'row',
    },
}));

const ColumnItem = styled(Grid)(({ theme }) => ({
    border: '0px solid green',
    display: 'flex',
    gap: '16px',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    minHeight: '165px',
    [theme.breakpoints.up('xs')]: {
        item: true,
        flexDirection: 'column',
    },
    [theme.breakpoints.up('md')]: {
        item: true,
        flexDirection: 'column',
    },
}));

const PoolName = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '700',
    cursor: 'pointer',
    fontSize: '25px',
    lineHeight: '24px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',

    [theme.breakpoints.up('xs')]: {
        display: 'block',
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
        whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
        display: 'block',
        whiteSpace: 'break-spaces',
    },
}));

const PoolSymbol = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '500',
    cursor: 'pointer',
    fontSize: '20px',
    lineHeight: '24px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',

    [theme.breakpoints.up('xs')]: {
        display: 'block',
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
        whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
        display: 'block',
        whiteSpace: 'break-spaces',
    },
}));

const PoolSymbol2 = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '500',
    cursor: 'pointer',
    fontSize: '20px',
    lineHeight: '24px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'center',

    [theme.breakpoints.up('xs')]: {
        display: 'block',
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
        whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
        display: 'block',
        whiteSpace: 'break-spaces',
    },
}));

const PoolName2 = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left',

    [theme.breakpoints.up('xs')]: {
        display: 'block',
        textAlign: 'left',
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
        textAlign: 'left'
    },
    [theme.breakpoints.up('md')]: {
        textAlign: 'left'
    },
}));
const PoolName3 = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '700',
    cursor: 'pointer',
    fontSize: '25px',
    lineHeight: '24px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'center',

    [theme.breakpoints.up('xs')]: {
        display: 'block',
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
        textAlign: 'center'
    },
    [theme.breakpoints.up('md')]: {
        textAlign: 'center'
    },
}));



const ValueTitle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '200',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
        fontSize: '12px',
        lineHeight: '18px',
        img: {
            display: 'none',
        },
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '14px',
        lineHeight: '18px',
        img: {
            display: 'block',
        },
    },
}));


interface Props {
    info: any;
    onClose: () => void;
    tokenImage: string;
    tokenPrice: string;
    tokenSocials: {tokenDescription: string, tokenTg: string, tokenX: string, tokenWeb: string, customLinks?: any[] | ''}
}

const PresaleModal: FC<Props> = ({ info, onClose, tokenImage, tokenPrice, tokenSocials }) => {
    const { pendingTxHash } = useAppSelector((state) => state.modal);
    const pendingTxHashRef: { current: string | undefined } = useRef();
    pendingTxHashRef.current = pendingTxHash;
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isLoadingLaunch, setLoadingLaunch] = useState<boolean>(false);
    const [isLoadingRedeem, setLoadingRedeem] = useState<boolean>(false);
    const [isLoadingClaim, setLoadingClaim] = useState<boolean>(false);
    const { selectedChainId, isWalletChecked } = useAppSelector((state) => state.chain);

    const [imageUploaded, setImageUploaded] = useState(false);
    const [isImageUploading, setIsImageUploading] = useState(false);
    const [ftmAmount, setFtmAmount] = useState<string>('');
    const [time, setTime] = useState(0);
    const [isLaunchStarted, setLaunchStarted] = useState(false);
    const [isLaunchFinished, setLaunchFinished] = useState(false);
    const [images, setImages] = useState<ImageType[]>([]);
    const theme = useTheme();
    const { account } = useWeb3React();
    const [lockTime, setLockTime] = useState(0);
    const estimateInfo = usePresaleEstimateInfo(Number(selectedChainId), info, ftmAmount)
    const [days, hours, minutes, seconds] = useCountDownUp(Number(time.toFixed(0)) * 1000);
    const [daysUntilUnlock, hoursUntilUnlock, minutesUntilUnclock, secsUntilUnlock] = useCountDownUp(lockTime ? lockTime * 1000 : new Date().getUTCSeconds() * 1000);
    const {onWithdraw} = useLocker(info)
    const [counter, setCounter] = useState<number>(0)

    const presaleInfo = usePresaleInfo(Number(selectedChainId), info, counter)
    const {onBuyToken, onApproveFTM, onClaim, onLaunch, onRedeem} = useSmartPresale(Number(selectedChainId), info, presaleInfo)

    const [totalEarned, setTotalEarned] = useState("");

    const [isRedeemDisabled, setRedeemDisabled] = useState(true);
    const [isClaimDisabled, setClaimDisabled] = useState(true);
    const [isLaunchTokenDisabled, setLaunchTokenDisabled] = useState(true);
    const [isDescriptionEdit, setIsDescriptionEdit] = useState(false);

    const userNativeBalance = useEthBalance()

    const isApproved = presaleInfo?.approoved

    const [lockedLps, setLockedLps] = useState<Number>(0);
    const [amm, setAmm] = useState<string>("--");

    const [isTokenomicsTabOpen, setIsTokenomicsTabOpen] = useState(false);



    const handleMainTabClick = () => {
        setIsTokenomicsTabOpen(false);
    };

    const handleDescriptionTabClick = () => {
        setIsTokenomicsTabOpen(true);
    };
    useEffect(() => {
        if( account && ftmAmount && presaleInfo ){
            try{

            }
            catch(err){
            }
        }
        try{
            setTotalEarned(ethers.utils.formatEther(presaleInfo?.totalEarned))
        }
        catch(err){
        }
    }, [account, presaleInfo, ftmAmount]);


    useEffect(() => {
        if( Number(totalEarned) >= Number(getBalanceInEther(info?.info?.presaleInfo?.hardCap)) ){
            setLaunchStarted(true)
            setLaunchFinished(true)
        }

        if( info && isLaunchFinished ){

            try{

                if( presaleInfo.balance >= Number(getBalanceInEther(info?.info?.presaleInfo?.softCap))){
                    setRedeemDisabled(false)
                    setLaunchTokenDisabled(false)
                }
            }
            catch(err){

            }

            try{

                if( presaleInfo.balance < Number(getBalanceInEther(info?.info?.presaleInfo?.softCap))){
                    setClaimDisabled(false)

                }
            }
            catch(err){

            }


        }
    }, [days, minutes, hours, seconds, info, presaleInfo, isLaunchFinished]);

    useEffect(() => {
        if( info ){
            const routers = getRouters(Number(selectedChainId))
            setLockedLps(Number(info?.lockLps))
            for( let i = 0; i < routers.length; i+=1 )
            {
                if( routers[i].address === info?.info?.router ){
                    setAmm(routers[i].name)
                    return
                }
            }
        }
    }, [info, selectedChainId]);

    const onUpdate = () => {
        setCounter(counter+1);
    };

    useEffect(() => {
        if( info ){


            //TIME SELECT
            const startIn =  Number(info?.info?.presaleInfo.presaleStartTime)
            const lockDur =  Number(info?.info?.tokenInfo?.lockPeriod)

            const currentTime = new Date().getTime() / 1000
            const presaleDuration = Number(info?.info?.presaleInfo.presaleDuration)
            const endPool = Number(startIn + presaleDuration)


            if( currentTime > startIn ){
                const duration =  startIn + presaleDuration;
                setTime(Number(duration.toFixed(0)))
                setLaunchStarted(true)
                if( endPool < currentTime ){
                    setLaunchFinished(true)
                }
            }
            else{
                setTime(startIn)
            }
            if(lockDur > 0 )
                setLockTime(Number(Number(startIn + presaleDuration + ( lockDur ) ).toFixed(0)))




            if( Number(totalEarned) >= Number(getBalanceInEther(info?.info?.presaleInfo?.hardCap)) ){
                setLaunchStarted(true)
                setLaunchFinished(true)
                return;
            }
        }
    }, [info, presaleInfo, presaleInfo?.balance]);

    const handleClose = () => {
        onClose();
    };

    const imageUpload = (imageList: any) => {
        setImages(imageList)
    };

    const key = process.env.REACT_APP_PINATA_API_KEY
    const apiKey = process.env.REACT_APP_PINATA_API_API

    const pinataConfig = {
        root: 'https://api.pinata.cloud',
        headers: {
            'pinata_api_key': apiKey,
            'pinata_secret_api_key': key
        }
    };


    const truncate = (str: string, n: number) => {
        return str?.length > n ? `${str.substr(0, n ) } ... ${str.substr(str.length - n, n) }`: str;
    };


    function isNumeric(n: any) {
        return !Number.isNaN(Number(n)) && Number.isFinite(Number(n));
    }
    const onChangeFTMAmount = (e: any) => {
        if (!isNumeric(e.target.value)) return;
        const newAmount = e.target.value;
        setFtmAmount(newAmount);
    };


    const buyToken = async () => {
        setLoading(true);
        try {
            await onBuyToken(ftmAmount, estimateInfo.value)
            onUpdate()
        } catch (err) {
            console.log('Presale err: ', err);
        }
        setLoading(false);
    };
    
    const claimToken = async () => {
        setLoadingClaim(true);
        try {
            if(!isApproved ){
                await onApproveFTM()
                onUpdate()
                setLoadingClaim(false);
                return;
            }
            await onClaim(presaleInfo?.presaleTokenBalance)
            onUpdate()
        } catch (err) {
            console.log('Presale err: ', err);
        }
        setLoadingClaim(false);
    };

    const redeemToken = async () => {
        setLoadingRedeem(true);
        try {
            if(!isApproved ){
                await onApproveFTM()
                onUpdate()
                setLoadingRedeem(false);
                return;
            }
            await onRedeem(presaleInfo?.presaleTokenBalance)
            onUpdate()
        } catch (err) {
            console.log('Presale err: ', err);
        }
        setLoadingRedeem(false);
    };

    const launchToken = async () => {
        setLoadingLaunch(true);
        try {
            await onLaunch(ftmAmount)
            onUpdate()
        } catch (err) {
            console.log('Presale err: ', err);
        }
        setLoadingLaunch(false);
    };

    const getButtonText = () => {
        return "BUY";
    };


    const getLiquidityAtLaunch = (info: any) =>{
        const hardCap = info?.info?.presaleInfo?.hardCap ? Number(getBalanceInEther(info?.info?.presaleInfo?.hardCap)) : 0

        return hardCap * 2;

    }

    function checkProtocolAndOpen(url: any) {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            const httpsUrl = 'https://' + url;
            window.open(httpsUrl);
        } else {
            window.open(url);
        }
    }
    return (

        <ModalDialog onClose={handleClose} open theme={theme}>

            <ToggleSection border={'0px solid red'} onClick={handleClose}>
                <CloseIcon sx={{width: '20px', height: '20px'}}/>
            </ToggleSection>

            {info && info?.currentRouterLogo?.length > 0 &&
            <RouterLogo imageUrl={info?.currentRouterLogo} sx={{left: {xs:4, md: 4}, top: 4, zIndex: 2000 }}/>
            }

            <Box display={isDescriptionEdit ? '': 'none'}>
                <DescriptionEdit tokenImage={tokenImage} presaleAddress={info?.presaleAddress} close={() => {setIsDescriptionEdit(false)}}
                socials={tokenSocials}/>
            </Box>

            <Box display={isDescriptionEdit ? 'none': ''}>

                <ModalHeader>
                    <RowItem>
                        {/*============Logo box============*/}
                        <Box sx={{
                            border: "0px solid red",
                            width: '100%',
                            height: 'auto',
                            minWidth: '165px',
                            minHeight: '165px',
                        }}>
                            {tokenImage?.length > 0 &&
                                <Box display='flex' sx={{position: 'relative'}}>
                                <Button onClick={() => {setIsDescriptionEdit(!isDescriptionEdit)}} sx={{position: 'absolute', display: info?.info?.presaleMaster === account ? '' : 'none' , right: 0, background: 'rgba(16,69,79,0.8)'}}>
                                    <EditIcon sx={{fill: '#BDE038'}} />
                                </Button>
                                <LogoBox imageUrl={tokenImage} sx={{minWidth: '165px', minHeight: '165px',}}></LogoBox>
                                </Box>
                            }
                            {!tokenImage && !info?.isUserToken &&
                                <Box style={{width: '120px', height: '120px', border: "0px solid grey"}}>
                                </Box>
                            }
                            {
                                !tokenImage && info?.isUserToken && !imageUploaded &&
                                <>
                               <Box display='flex' sx={{position: 'relative'}}>
                                <Button onClick={() => {setIsDescriptionEdit(!isDescriptionEdit)}} sx={{position: 'absolute', right: 0, background: 'rgba(16,69,79,0.8)'}}>
                                    <EditIcon />
                                </Button>
                                <img src={tokenImage?.length > 0 ? tokenImage : ""} style={{
                                    minWidth: '165px',
                                    minHeight: '165px',
                                    objectFit: 'cover',
                                    borderRadius: '10px'
                                }}/>
                                </Box>
                                </>
                            }
                        </Box>

                        {/*=====================Info Item====================*/}

                        <Box sx={{
                            width: '100%',
                            height: '100',
                            minHeight: {xs: '100px', md: '165px',}
                        }}>
                            {isTokenomicsTabOpen && (

                                <Box sx={{
                                    border: '0px solid red',
                                    minHeight: {xs: '100px', md: '165px',},
                                    width: '100%',
                                    display: 'flex',
                                    gap: '10px',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',


                                }}>
                                    <PoolName3>
                                        {info?.info?.tokenInfo?.tokenName ? info?.info?.tokenInfo?.tokenName.length > 15 ? truncate(info?.info?.tokenInfo?.tokenName, 6) : info?.info?.tokenInfo?.tokenName : ''}
                                    </PoolName3>
                                    <PoolSymbol2>
                                        {info?.info?.tokenInfo?.tokenSymbol}
                                    </PoolSymbol2>

                                <Box display='flex' justifyContent='center' sx={{display: {xs: 'flex', md: 'flex', position: 'relative', bottom: 0, right: '0%', minWidth: '128px'}}} >
                                        { tokenSocials?.tokenTg?.length > 0 &&
                                            <Button onClick={() => {checkProtocolAndOpen(tokenSocials?.tokenTg)}} sx={{zIndex: 1}}>
                                              <TelegramIcon sx={{width: '25px'}} />
                                            </Button>
                                        }
                                        { tokenSocials?.tokenX?.length > 0 &&
                                          <Button onClick={() => {checkProtocolAndOpen(tokenSocials?.tokenX)}} sx={{zIndex: 1}}>
                                          <XIcon sx={{width: '18px'}} />
                                            </Button>
                                        }
                                        { tokenSocials?.tokenWeb?.length  > 0 && 
                                          <Button onClick={() => {checkProtocolAndOpen(tokenSocials?.tokenWeb)}} sx={{zIndex: 1}}>
                                          <LanguageIcon sx={{width: '25px', height: '25px'}} />
                                        </Button>
                                        }
                                      </Box>
                                    <Box  sx={{display:'flex', flexDirection: 'row', justifyContent: 'center',}}>
                                        {tokenSocials?.customLinks && tokenSocials?.customLinks?.length > 0 && tokenSocials?.customLinks[0]?.[1]?.length > 0 && (
                                            <BlueLinkIcon tooltipTitle={tokenSocials?.customLinks[0]?.[0]}
                                                          onClick={(e) => {
                                                              e.stopPropagation();
                                                              // @ts-ignore
                                                              checkProtocolAndOpen(tokenSocials?.customLinks[0]?.[1]);
                                                          }}/>
                                        )}

                                        {tokenSocials?.customLinks && tokenSocials?.customLinks?.length > 0 && tokenSocials?.customLinks[1]?.[1]?.length > 0 && (
                                            <BlueLinkIcon tooltipTitle={tokenSocials?.customLinks[1]?.[0]}
                                                          onClick={(e) => {
                                                              e.stopPropagation();
                                                              // @ts-ignore
                                                              checkProtocolAndOpen(tokenSocials?.customLinks[1]?.[1]);
                                                          }}/>
                                        )}
                                    </Box>

                                </Box>
                                
                            )}
                            {!isTokenomicsTabOpen && (

                                <Box display='flex' gap={'10px'} flexDirection='column' justifyContent='start'
                                     sx={{border: '0px solid blue', height: '100%'}}>
                                    <PoolName>
                                        {info?.info?.tokenInfo?.tokenName ? info?.info?.tokenInfo?.tokenName.length > 15 ? truncate(info?.info?.tokenInfo?.tokenName, 6) : info?.info?.tokenInfo?.tokenName : ''}
                                    </PoolName>
                                    <PoolSymbol>
                                        {info?.info?.tokenInfo?.tokenSymbol}
                                    </PoolSymbol>
                                    <ContainedInfoBox>
                                            <Box justifyContent='center' alignItems='center' display='flex' gap='10px'>
                                                <Box sx={{
                                                    minWidth: '20px',
                                                    minHeight: '20px',
                                                    maxHeight: "20px",
                                                    background: isLaunchStarted && !isLaunchFinished ? 'green' : isLaunchFinished ? 'red' : "orange",
                                                    borderRadius: '10px'
                                                }}>

                                                </Box>
                                            </Box>
                                            {!isLaunchFinished &&
                                                <PoolName2 sx={{border: '0px solid red'}}>
                                                    {isLaunchStarted ? 'Ends in' : 'Starts in'} {days > 0 ? days : hours > 0 ? hours : minutes > 0 ? minutes : seconds} {days > 0 ? "d" : hours > 0 ? "h" : minutes > 0 ? "m" : "s"}
                                                </PoolName2>
                                            }
                                            {isLaunchFinished &&
                                                <PoolName2>Ended</PoolName2>
                                            }
                                    </ContainedInfoBox>
                                    <ContainedInfoBox sx={{width: '100%'}}>
                                            <PoolName2>
                                                Raised:
                                            </PoolName2>
                                            <PoolName2>
                                                {presaleInfo?.balance}
                                            </PoolName2>
                                    </ContainedInfoBox>
                                </Box>
                            )}
                        </Box>
                    </RowItem>

                    {/*===============Tabs ======================*/}
                    <ColumnItem>

                        <ContainedButton
                            sx={{
                                flex: 1,
                                width: '100%',
                                height: '100%',
                                wordBreak: 'break-word',
                                fontSize: '25px'
                            }}
                            isActive={!isTokenomicsTabOpen}
                            onClick={handleMainTabClick}

                        >
                            Main PreSale Page
                        </ContainedButton>

                        <ContainedButton
                            sx={{
                                flex: 1,
                                width: '100%',
                                height: '100%',
                                wordBreak: 'break-word',
                                fontSize: '20px'
                            }}
                            isActive={isTokenomicsTabOpen}
                            onClick={handleDescriptionTabClick}

                        >
                            Description & Tokenomics
                        </ContainedButton>

                    </ColumnItem>
                </ModalHeader>
            </Box>

            <Box display={isDescriptionEdit ? 'none': ''}>
                {isTokenomicsTabOpen && (
                    <TokenomicsTab
                        open={true}
                        handleClose={handleMainTabClick}
                        info={info}
                        tokenDescription={tokenSocials.tokenDescription}
                    >

                    </TokenomicsTab>
                )}

                {!isTokenomicsTabOpen && (


                    <Grid container display='flex' spacing={2} justifyContent='center'
                          sx={{
                              border: '0px solid red',
                          }}>
                        <Grid item xs={12} md={6}>
                            <Box display='flex' flexDirection='column' gap={1}>
                            <ContainedInfoBox  sx={{minHeight: '34px', minWidth: '200px'}}>
                                <InfoLabelBegin>
                                        Presale Creator:
                                </InfoLabelBegin>
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => {
                                            navigator.clipboard.writeText(info?.info?.presaleMaster)
                                        }}
                                        style={{
                                            maxHeight: '24px',
                                            borderRadius: '0px',
                                            width: '240px',
                                            maxWidth: '240px'
                                        }}
                                    >
                                        <Typography style={{fontSize: '11px', color: "lightgray"}} color='primary'
                                                    variant={'h5'}> {truncate(info?.info?.presaleMaster, 4)}
                                        </Typography>
                                        <FileCopyIcon style={{marginLeft: '2px', maxHeight: "18px", width: 'auto'}}/>
                                    </Button>
                                </ContainedInfoBox>
                                <ContainedInfoBox sx={{minHeight: '34px', minWidth: '200px'}}>
                                    <InfoLabelBegin sx={{minWidth: '130px'}}>
                                        Tokens for Presale:
                                    </InfoLabelBegin>
                                    <InfoLabelEnd>
                                        {info?.info?.presaleInfo?.tokensForPresale ? Number(getBalanceInEther(info?.info.presaleInfo?.tokensForPresale)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
                                    </InfoLabelEnd>
                                </ContainedInfoBox>
                                <ContainedInfoBox display='flex' flexDirection='row' justifyContent='space-between'
                                     alignItems='center' sx={{
                                    background: "rgba(6,1,1,0.3)",
                                    padding: '5px',
                                    minHeight: '34px',
                                    borderRadius: '10px',
                                    minWidth: '200px'
                                }}>
                                    <InfoLabelBegin>
                                        Presale Price ( ftm ):
                                    </InfoLabelBegin>
                                    <InfoLabelEnd>
                                        {info?.info?.presaleInfo?.presalePrice ? Number(getBalanceInEther(info?.info.presaleInfo?.presalePrice)) : 0}
                                    </InfoLabelEnd>

                                </ContainedInfoBox>
                                <ContainedInfoBox display='flex' flexDirection='row' justifyContent='space-between'
                                     alignItems='center' sx={{
                                    background: "rgba(6,1,1,0.3)",
                                    padding: '5px',
                                    minHeight: '34px',
                                    borderRadius: '10px',
                                    minWidth: '200px'
                                }}>
                                    <InfoLabelBegin sx={{minWidth: '200px'}}>
                                        Min / Max Per Wallet ( ftm ):
                                    </InfoLabelBegin>
                                    <InfoLabelEnd>
                                        {info?.info?.presaleInfo?.minAddressBuy ? Number(getBalanceInEther(info?.info.presaleInfo?.minAddressBuy)) : 0}/
                                        {info?.info?.presaleInfo?.maxAddressBuy ? Number(getBalanceInEther(info?.info.presaleInfo?.maxAddressBuy)) : 0}
                                    </InfoLabelEnd>
                                </ContainedInfoBox>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box display='flex' flexDirection='column' gap={1}>
                                <ContainedInfoBox  sx={{
                                    minHeight: '34px',
                                }}>
                                    <InfoLabelBegin sx={{minWidth: '200px'}}>
                                        Token Launch Price ( ftm ):
                                    </InfoLabelBegin>
                                    <InfoLabelEnd>
                                        {info?.info?.tokenInfo?.tokenLaunchPrice ? Number(getBalanceInEther(info?.info.tokenInfo?.tokenLaunchPrice)).toFixed(4) : 0}
                                    </InfoLabelEnd>
                                </ContainedInfoBox>
                                <ContainedInfoBox display='flex' flexDirection='row' justifyContent='space-between'
                                     alignItems='center' sx={{
                                    background: "rgba(6,1,1,0.3)",
                                    minHeight: '34px',
                                    padding: '5px',
                                    borderRadius: '10px',
                                    minWidth: '200px'
                                }}>
                                    <InfoLabelBegin>
                                        MCap at launch ( ftm ):
                                    </InfoLabelBegin>
                                    <InfoLabelEnd>
                                        {info?.info?.tokenInfo?.tokenLaunchPrice ? (Number(getBalanceInEther(info?.info?.tokenInfo?.tokenLaunchPrice)) *
                                            getBalanceInEther(info?.info?.tokenInfo?.totalSupply)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
                                    </InfoLabelEnd>
                                </ContainedInfoBox>
                                <ContainedInfoBox display='flex' flexDirection='row' justifyContent='space-between'
                                     alignItems='center' sx={{
                                    background: "rgba(6,1,1,0.3)",
                                    minHeight: '34px',
                                    padding: '5px',
                                    borderRadius: '10px',
                                    minWidth: '200px'
                                }}>
                                    <InfoLabelBegin>
                                        Liquidity Lock:
                                    </InfoLabelBegin>
                                    <InfoLabelEnd>
                                        {info.isElock &&
                                            <>
                                                ELock {lockTime > 0 ? daysUntilUnlock > 0 ? daysUntilUnlock : hoursUntilUnlock > 0 ? hoursUntilUnlock : minutesUntilUnclock > 0 ? minutesUntilUnclock : secsUntilUnlock : ""}{lockTime > 0 ? daysUntilUnlock > 0 ? "d " : hoursUntilUnlock > 0 ? "h " : minutesUntilUnclock > 0 ? "m " : "s " : ''}

                                            </>
                                        }
                                        {info?.info?.tokenInfo?.lockLiquidity && lockTime <= 0 && !info.isElock &&
                                            <>
                                                Unlocked
                                            </>
                                        }
                                        {info?.info?.tokenInfo?.lockLiquidity && lockTime > 0 && !info.isElock &&
                                            <>
                                                Locked
                                                ( {lockTime > 0 ? daysUntilUnlock > 0 ? daysUntilUnlock : hoursUntilUnlock > 0 ? hoursUntilUnlock : minutesUntilUnclock > 0 ? minutesUntilUnclock : secsUntilUnlock : ""}{lockTime > 0 ? daysUntilUnlock > 0 ? "d " : hoursUntilUnlock > 0 ? "h " : minutesUntilUnclock > 0 ? "m " : "s " : ''})
                                            </>
                                        }
                                        {!info?.info?.tokenInfo?.lockLiquidity && !info.isElock &&
                                            <>
                                                {info?.info?.tokenInfo?.SCC !== '0x0000000000000000000000000000000000000000' ? 'SCC' : 'No Lock'}
                                            </>
                                        }

                                    </InfoLabelEnd>
                                </ContainedInfoBox>
                                <ContainedInfoBox  sx={{
                                    background: "rgba(6,1,1,0.3)",
                                    padding: '5px',
                                    minHeight: '34px',
                                    borderRadius: '10px',
                                    minWidth: '200px'
                                }}>
                                    <InfoLabelBegin>
                                        Liquidity at launch (ftm):
                                    </InfoLabelBegin>
                                    <InfoLabelEnd>
                                        {getLiquidityAtLaunch(info).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    </InfoLabelEnd>
                                </ContainedInfoBox>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12} alignItems='center' justifyContent='center'>
                            <Box display='flex' justifyContent='center' alignItems='center'>
                                <ProgressBar midPoint={Number(totalEarned)}
                                             startPoint={Number(getBalanceInEther(info?.info?.presaleInfo?.softCap) * 100 / Number(getBalanceInEther(info?.info?.presaleInfo?.hardCap)))}
                                             endPoint={Number(getBalanceInEther(info?.info?.presaleInfo?.hardCap))}/>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <>
                                <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                                    <Box display='flex' sx={{
                                        border: '0px solid grey',
                                        padding: '10px',
                                        width: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        <ContainedTextField variant={'outlined'} disabled={isLaunchFinished} placeholder='FTM Amount' required={true}
                                                   onChange={onChangeFTMAmount} autoComplete='off' label="FTM Amount"
                                                   error={(Number(ftmAmount) < (Number(getBalanceInEther(info?.info.presaleInfo?.minAddressBuy))) && ftmAmount.length !== 0) || ((Number(ftmAmount) > Number(getBalanceInEther(info?.info.presaleInfo?.maxAddressBuy))) && ftmAmount.length !== 0)}
                                                   value={ftmAmount}
                                        />
                                    </Box>

                                    <ContainedInfoBox sx={{
                                        height: '53px',
                                        mb: '8px',
                                        maxWidth: '222px'
                                    }}>
                                        <InfoLabelBegin sx={{maxWidth: '55px'}}>
                                            Recieve:
                                        </InfoLabelBegin>
                                        <InfoLabelEnd sx={{maxWidth: {xs:'150px', sm:'150px' , md:'150px' }, wordBreak:{xs:'break-word'}, whiteSpace: {xs: 'break-spaces'},}}>
                                            {estimateInfo?.value}
                                        </InfoLabelEnd>

                                    </ContainedInfoBox>

                                    <Box display='flex' justifyContent='center' sx={{
                                        border: '0px solid red',
                                        width: '220px',
                                        height: '53px'

                                    }}>

                                        {account ? (
                                            <>
                                                <ContainedButton disabled={isLaunchFinished || !isLaunchStarted} onClick={buyToken}
                                                                     sx={{width: '100%', height: '100%'}}>
                                                    {isLoading ? <CircularProgress size={20}
                                                                                   sx={{color: 'white'}}/> : getButtonText()}
                                                </ContainedButton>
                                            </>

                                        ) : (
                                            <></>
                                        )
                                        }
                                    </Box>
                                </Box>
                            </>


                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Box display='flex' sx={{
                                height: '100%', width: '100%', minHeight: '100px', background: 'rgba(6,1,1,0.3)',
                                borderRadius: '10px',
                            }}>
                                <Box mt={0} display='flex' flexDirection='column' sx={{
                                    border: '1px solid #1B1E29',
                                    background: '#1B1E29',
                                    padding: '10px',
                                    width: '100%',
                                    borderRadius: '10px',
                                    height: '100%'
                                }}>
                                    <ValueTitle justifyContent={'center'} border={'0px solid red'}>
                                        INFORMATION FOR PARTICIPANTS
                                    </ValueTitle>
                                    <ValueTitle mt={1}>
                                        Presale is open if status is LIVE or Hard Cap is not reached.
                                        Any investor can call Launch Token if its available, which creates the token,
                                        all funds raised are automatically used to create Liquidity and sent to SCC,
                                        Presale Creator wallet, or Locked depending on settings set by Presale Creator.
                                    </ValueTitle>
                                    <ValueTitle mt={1}>
                                        Claim your tokens If hard cap is reached.
                                    </ValueTitle>
                                    <ValueTitle mt={0}>
                                        Claim your refund If soft cap is not reached.
                                    </ValueTitle>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid mt={0} item xs={12} sx={{width: '100%', height: '100%'}} justifyContent='center'>
                            <Box display='flex' gap={2} justifyContent='row'
                                 sx={{width: '100%', flexDirection: {xs: 'column', md: 'row'}}}>
                                <ContainedInfoBox display='flex' flex = {1} flexDirection='row' justifyContent='space-between'
                                     alignItems='center' sx={{
                                    background: "rgba(6,1,1,0.3)",
                                    minHeight: '34px',
                                    padding: '5px',
                                    borderRadius: '10px',
                                    minWidth: '200px'
                                }}>
                                    <InfoLabelBegin sx={{xs: {whiteSpace: 'wrap', minHeight: '100px',}, minWidth: '280px'}}>
                                        YOUR {info?.info?.tokenInfo?.tokenName.toUpperCase()} BALANCE
                                    </InfoLabelBegin>
                                    <InfoLabelEnd>
                                        {presaleInfo?.presaleTokenBalance ? getBalanceInEther(presaleInfo?.presaleTokenBalance) : "-.-"}
                                    </InfoLabelEnd>

                                </ContainedInfoBox>
                                <ContainedInfoBox display='flex'  flex = {1} flexDirection='row' justifyContent='space-between'
                                     alignItems='center' sx={{
                                    background: "rgba(6,1,1,0.3)",
                                    minHeight: '34px',
                                    padding: '5px',
                                    borderRadius: '10px',
                                    minWidth: '200px'
                                }}>
                                    <InfoLabelBegin>
                                        YOUR FTM BALANCE
                                    </InfoLabelBegin>
                                    <InfoLabelEnd>
                                        {userNativeBalance ? getBalanceInEther(userNativeBalance).toFixed(4) : "-.-"}
                                    </InfoLabelEnd>

                                </ContainedInfoBox>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display='flex' gap={2} justifyContent='center' alignItems='center'
                                 sx={{flexDirection: {xs: 'column', md: 'row'}}}>
                                {account ? (
                                    <>
                                        <ContainedButton
                                            variant={'outlined'}
                                            disabled={isRedeemDisabled || presaleInfo?.presaleTokenBalance?.eq(0) ||  !info?.tokenLaunched}
                                            onClick={redeemToken} sx={{minWidth: '180px'}}>
                                            {isLoadingRedeem ? <CircularProgress size={20}
                                                                                 sx={{color: 'white'}}/> : !isApproved? "Approve For Redeem" : "Redeem your shares"}
                                        </ContainedButton>
                                        <ContainedButton
                                            variant={'outlined'}
                                            disabled={isClaimDisabled || presaleInfo?.presaleTokenBalance?.eq(0) || !info?.tokenLaunched}
                                            onClick={claimToken} sx={{minWidth: '180px'}}>
                                            {isLoadingClaim ? <CircularProgress size={20}
                                                                                sx={{color: 'white'}}/> : !isApproved ? "Approve For Refund" : "Claim Refund"}
                                        </ContainedButton>
                                        <ContainedButton
                                            variant={'outlined'}
                                            disabled={isLaunchTokenDisabled || info?.tokenLaunched}
                                                             onClick={launchToken}
                                                             sx={{minWidth: '180px', marginLeft: '0px'}}>
                                            {isLoadingLaunch ?
                                                <CircularProgress size={20} sx={{color: 'white'}}/> : "Launch Token"}
                                        </ContainedButton>
                                    </>

                                ) : (
                                    <ConnectWallet width={150}/>
                                )
                                }

                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </ModalDialog>
    );
};
export default PresaleModal;
