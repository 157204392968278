import {FC} from 'react';
import {
    Box,
    styled,
    Typography,
} from '@mui/material';
import ModalDialog from "../../common/ModalDialog/ModalDialog";
import CloseIcon from '@mui/icons-material/Close';


const ToggleSection = styled(Box)(() => ({
    position: 'absolute',
    right: '0',
    top: '0',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '45px',
    height: '45px',
    zIndex: 1
}));
const BodyBox = styled(Box)(() => ({
    border: '0px solid red',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    gap: '16px',
    paddingBottom: '10px',

}))
const TittleBox = styled(Box)(() => ({
    border: '0px solid red',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',

}))

const StepBox = styled(Box)(() => ({
    border: '0px solid red',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    gap: '16px',
}))

const ImageBox = styled(Box)(() => ({
    border: '0px solid green',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 0.85
}))

const TextBox = styled(Box)(() => ({
    border: '0px solid blue',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    padding: '0px 0px 0px 0px',
}))

const Text = styled(Typography)(() => ({
    border: '0px solid green',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Prompt',
    fontSize: '18px',
    fontWeight: '600',
    wordWrap: 'normal',
    textWrap: 'wrap',
    textAlign: 'center',

}))


interface ModalProps {
    open: boolean;
    handleClose: () => void;
    firstStepLogo: string;
    secondStepLogo: string;
    thirdStepLogo: string;
    isTokenCreate?: boolean;
    isPresaleCreate?: boolean;
}


const AddSocialsInstructionsModal: FC<ModalProps> = ({ open, handleClose, firstStepLogo, secondStepLogo, thirdStepLogo, isTokenCreate = false, isPresaleCreate=false }) =>
{
    return (
        <ModalDialog open={open} onClose={handleClose} sx={{'.MuiDialog-container > .MuiPaper-root':{background: 'rgba(35, 38, 41, 100)', }}}>
            <ToggleSection onClick={handleClose}>
                <CloseIcon sx={{width: '20px', height: '20px'}}/>
            </ToggleSection>
            {isTokenCreate &&
            <BodyBox>
                <TittleBox>
                    <Text sx={{fontSize: '25px'}}>
                        Token Created Successfully !
                    </Text>
                    <Text>
                        Add Logo and Socials instructions:
                    </Text>
                </TittleBox>
                <StepBox>
                    <TextBox>
                        <Text>
                            1. Click on your token to open token modal.
                        </Text>
                    </TextBox>
                    <ImageBox> <img src={firstStepLogo}/> </ImageBox>
                </StepBox>
                <StepBox>
                    <TextBox>
                        <Text>
                            2. In token modal click Edit button to access Deployer Dashboard.
                        </Text>
                    </TextBox>
                    <ImageBox> <img src={secondStepLogo}/> </ImageBox>
                </StepBox>
                <StepBox>
                    <TextBox>
                        <Text>
                            3. Upload your Logo, input short description
                            and social links. Click Confirm.
                        </Text>
                    </TextBox>
                    <ImageBox> <img src={thirdStepLogo}/> </ImageBox>
                </StepBox>

            </BodyBox>
            }
            {isPresaleCreate &&
                <BodyBox>
                    <TittleBox>
                        <Text sx={{fontSize: '25px'}}>
                            Presale Created Successfully !
                        </Text>
                        <Text>
                            Add Logo and Socials instructions:
                        </Text>
                    </TittleBox>
                    <StepBox>
                        <TextBox>
                            <Text>
                                1. Click on your presale to open presale modal.
                            </Text>
                        </TextBox>
                        <ImageBox> <img src={firstStepLogo}/> </ImageBox>
                    </StepBox>
                    <StepBox>
                        <TextBox>
                            <Text>
                                2. In presale modal click Edit button to access Deployer Dashboard.
                            </Text>
                        </TextBox>
                        <ImageBox> <img src={secondStepLogo}/> </ImageBox>
                    </StepBox>
                    <StepBox>
                        <TextBox>
                            <Text>
                                3. Upload your Logo, input short description
                                and social links. Click Confirm.
                            </Text>
                        </TextBox>
                        <ImageBox> <img src={thirdStepLogo}/> </ImageBox>
                    </StepBox>

                </BodyBox>

            }
        </ModalDialog>
    );
};

export default AddSocialsInstructionsModal;

