import { useState, useEffect } from "react";

import {StakingPoolInfo, SubPoolInfo} from 'types/stakingPool'

import tokenImageTest from 'assets/Bug.png'
import rewardTokenImageTest from 'assets/tokens/FVM.png'

const defaultSubPool: SubPoolInfo[] = [
    {
        subPoolId: 1,
        stakingTokenAddress: "0x32..3432",
        rewardTokenAddress: "0x25..8532",
        stakingTokenPrice: "100",
        rewardTokenPrice: "10",
        apr: "12%",
        tvl: "$1000",
        stakingTokenCount: "10000",
        depositFee: "1%",
        rewardTokenCount: "50",
    },
    {
        subPoolId: 2,
        stakingTokenAddress: "0x32..3432",
        rewardTokenAddress: "0x25..8532",
        stakingTokenPrice: "100",
        rewardTokenPrice: "10",
        apr: "12%",
        tvl: "$1000",
        stakingTokenCount: "10000",
        depositFee: "1%",
        rewardTokenCount: "50",
    },
    {
        subPoolId: 3,
        stakingTokenAddress: "0x32..3432",
        rewardTokenAddress: "0x25..8532",
        stakingTokenPrice: "100",
        rewardTokenPrice: "10",
        apr: "12%",
        tvl: "$1000",
        stakingTokenCount: "10000",
        depositFee: "1%",
        rewardTokenCount: "50",
    },
    {
        subPoolId: 4,
        stakingTokenAddress: "0x32..3432",
        rewardTokenAddress: "0x25..8532",
        stakingTokenPrice: "100",
        rewardTokenPrice: "10",
        apr: "12%",
        tvl: "$1000",
        stakingTokenCount: "10000",
        depositFee: "1%",
        rewardTokenCount: "50",
    },
];


const defaultInfoList: StakingPoolInfo[] = [
    {
        name: "Staking Pool 1",
        poolId: 1,
        stakingTokenName: "Token A",
        stakingTokenSymbol: "TKA",
        rewardTokenName: "Reward A",
        rewardTokenSymbol: "RWA",
        stakingTokenImage: tokenImageTest,
        rewardTokenImage: rewardTokenImageTest,
        stakingTokenAddress: "0x32..3432",
        rewardTokenAddress: "0x25..8532",
        stakingTokenPrice: "100",
        rewardTokenPrice: "10",
        apr: "12%",
        tvl: "1000000",
        stakingTokenCount: "10000",
        depositFee: "1%",
        rewardTokenCount: "50",
        tgLink: "5000",
        twitterLink: "5000",
        webLink: "5000",
        subPool: defaultSubPool,

    },
    {
        name: "Staking Pool 1",
        poolId: 2,
        stakingTokenName: "Token B",
        stakingTokenSymbol: "TKB",
        rewardTokenName: "Reward B",
        rewardTokenSymbol: "RWB",
        stakingTokenImage: tokenImageTest,
        rewardTokenImage: rewardTokenImageTest,
        stakingTokenAddress: "0x32..3432",
        rewardTokenAddress: "0x25..8532",
        stakingTokenPrice: "100",
        rewardTokenPrice: "10",
        apr: "12%",
        tvl: "1000000",
        stakingTokenCount: "10000",
        depositFee: "1%",
        rewardTokenCount: "50",
        tgLink: "",
        twitterLink: "",
        webLink: "",
        subPool: defaultSubPool,
    },

];

export const useStakingLauncherInfo = () => {
    const [infoList, setInfoList] = useState<StakingPoolInfo[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchInfoList = async () => {
            try {

                setInfoList(defaultInfoList);
                // const response = await fetch("");
                // if (!response.ok) {
                //   throw new Error("Failed to fetch info list");
                // }
                // const data: StakingPoolInfo[] = await response.json();
                // setInfoList(data);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        };

        fetchInfoList().then();
    }, []);

    return { infoList, loading, error };
};