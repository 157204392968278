import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useState } from "react";
import { AbiItem } from 'web3-utils';
import { BigNumber, Contract, constants, ethers, providers, utils } from "ethers";
import { useDispatch } from 'react-redux';
import { fetchDeployedTokensDataAsync, fetchPresaleUserInfoAsync } from "state/actions";
import pairAbi from 'config/abi/LpPair.json';
import ERC20 from 'config/abi/Erc20.json';
import multicall, { multicallNative} from 'utils/multicall';
import LpPair from 'config/abi/LpPair.json';

import { FarmInfo } from "types/stake";
import { getMasterChefAbi } from "utils/stake";
import {useContract, useToken} from "./useContract";
import { setPendingTxHash } from 'state/modal/modalSlice';

import { getBalanceInEther, getContract } from "utils";
import { getTokenPriceFromCGC } from "utils/coingecko";
import { getTokenInfo } from "utils/tokens";
import { getTokenDeployerInfo } from "utils/deployTokens";
import { getTokensDexInfo } from "config/constants/backend";

export const useStakeDeployedAccountInfo = (chainId: number, pool: any, counter: number) => {
  const { account } = useWeb3React();
  const [stat, setStat] = useState<any>();
  const contract = useContract(getMasterChefAbi() as unknown as AbiItem, pool ? pool?.masterChefAddress : '');
  const token = useContract(ERC20 as unknown as AbiItem, pool ? pool?.stakingToken : '');

  useEffect(() => {
      async function getStakeInfo() {
        if( !contract || !token || !pool || !account ) return;
        let accountBalance = BigNumber.from(0);
        let userStaked = BigNumber.from(0);
        let stakeTime = BigNumber.from(0);
        let allowance = BigNumber.from(0);
        let pendingRewards = BigNumber.from(0);
        try {
          if( contract && token && pool){
              if( account ){
                  accountBalance = await token.balanceOf(account);
                  const userInfo = await contract.userInfo(0, account);
                  userStaked = userInfo.amount;
                  stakeTime = userInfo.stakeTime;
                  allowance = await token.allowance(account, contract.address)
                  pendingRewards= await contract.pendingReward(0, account);
              }
              setStat({accountBalance, userStaked, stakeTime, allowance, pendingRewards, masterChefAddress: pool.masterChefAddress, stakingToken: pool.stakingToken, poolId: 0, isLp: false});
          }
        } catch (err) {
          console.log(err)
          // setStat({accountBalance, userStaked, stakeTime, allowance, pendingRewards, masterChefAddress: "", stakingToken: "", poolId: 0, isLp: false});
        }
      }
   
      getStakeInfo().then();
    }, [ chainId, pool, contract, token, account, counter]);
    return stat;
}

export const useStakeEstimateInfo = (chainId: number, counter: number, stakerInfo: any, ftmPrice: number) => {
    const { account } = useWeb3React();
    const [stat, setStat] = useState<any>();
    const contract = useContract(getMasterChefAbi() as unknown as AbiItem, stakerInfo ? stakerInfo?.address : '');
    const token = useContract(ERC20 as unknown as AbiItem, stakerInfo ? stakerInfo?.stakeTokenAddress : '');
    // const rewardTokInfo = getTokenInfo(stakerInfo?.stakeTokenAddress)
    // const deployerTokInfo = getTokenDeployerInfo(chainId)
    
    useEffect(() => {
        async function getStakeInfo() {
          try {
            if( contract && token){
              let poolRewards = 0;
              let accountBalance = BigNumber.from(0);
              let userStaked = BigNumber.from(0);
              let stakeTime = BigNumber.from(0);
              let allowance = BigNumber.from(0);
              let pendingRewards = BigNumber.from(0);
              let tvlUSDC = 0;
              let poolEnded = false;
              if( account ){
              const [userInfo, pendingRewardsRaw] = await multicall(
                getMasterChefAbi(),
                [
                  {
                    address: stakerInfo?.address,
                    name: 'userInfo',
                    params: [0, account],
                  },
                  {
                    address: stakerInfo?.address,
                    name: 'pendingReward',
                    params: [0, account],
                  },
                ]
              );
              const [accountBalanceRaw, allowanceRaw] = await multicall(
                ERC20,
                [
                  {
                    address: stakerInfo?.stakeTokenAddress,
                    name: 'balanceOf',
                    params: [account],
                  },
                  {
                    address: stakerInfo?.stakeTokenAddress,
                    name: 'allowance',
                    params: [account, stakerInfo?.address ],
                  }
                ]
              );

              accountBalance = accountBalanceRaw[0]
              allowance = allowanceRaw[0]
              userStaked = userInfo.amount;
              pendingRewards = pendingRewardsRaw[0]
              stakeTime = userInfo.stakeTime;
            }

            const lockTime = stakerInfo?.lockTime
            const currentDate = Math.floor((new Date()).getTime() / 1000)
            if( currentDate > stakerInfo.poolEndTime ){
              poolEnded = true;
            }
            setStat({accountBalance, userStaked, stakeTime, allowance, pendingRewards, lockTime, poolEnded, decimals: stakerInfo?.stakeTokenDecimals});
          }
            
          } catch (err) {
              console.log(err)
          }
        }
     
        getStakeInfo().then();
      }, [ chainId, stakerInfo, contract, token, account, counter, ftmPrice]);
      return stat;
}

export const useTokenApprove = (chainId: number, amount: string, pool: FarmInfo ) => {
    const { account } = useWeb3React();
    const [stat, setStat] = useState<any>();
    const contract = useContract(getMasterChefAbi() as unknown as AbiItem, pool ? pool?.masterChefAddress : '');
    
    useEffect(() => {
        if( !contract || !pool || amount.length <= 0){
          setStat({value: 0});
          return;
        }
        // HOW MUCH APPROVED
        async function getStakeInfo() {
          try {
            const res = await contract?.estimatePreSale(ethers.utils.parseUnits(amount.toString(), 18));
            setStat({value: res});
          } catch (err) {
            console.error(err);
          }
        }
     
        getStakeInfo().then();
      }, [ chainId, amount, pool, contract]);
      return stat;
}

export const useTokenPrice = (chainId: number, lastPairUpdates: number) => {
    const { account, connector } = useWeb3React();
    const [stat, setStat] = useState<any>();
    const nativePair = "0x9c0Dd6BA0E2c611585c75F06f024BC8826FdB446";
    const usdcPair = "0x29715d8D279cAB143A12fF515b40a2b35d7BAD37";

    useEffect(() => {
        // if( !contract ){
        //   setStat({value: 0});
        //   return;
        // }
        // HOW MUCH APPROVED
        async function getStakeInfo() {
          try {


            if((Date.now().valueOf() - lastPairUpdates) <= 30000)
            {
                return;
            }
                
            // const token0Native = await contract.token0();
            // const token1Native = await contract.token1();
            
            // const provider = new providers.Web3Provider(connector.provider!);
            
            // const token0Contract = getContract(token0Native, ERC20, provider, account ? account : undefined);
            // const token1Contract = getContract(token1Native, ERC20, provider, account ? account : undefined);

            // const token0NativeDecimals = await token0Contract.decimals();
            // const token1NativeDecimals = await token1Contract.decimals();


            // const token0NativeBalance = await token0Contract.balanceOf(nativePair);
            // const token1NativeBalance = await token1Contract.balanceOf(nativePair);
            
            // let priceLp = 1;

            // const format0 = ethers.utils.formatUnits(BigNumber.from(token0NativeBalance), token0NativeDecimals);
            // const format1 = ethers.utils.formatUnits(token1NativeBalance, token1NativeDecimals);
            // priceLp = Number(format1) / Number(format0)
            const price = await getTokenPriceFromCGC("fantom");
            if( price ){
                if(price.pairs.length > 0){
                    setStat({value: price.pairs[0].priceUsd});
                }
            }
            // setStat({value: res});
          } catch (err) {
            console.log(err);
          }
        }
     
        getStakeInfo().then();
      }, [ chainId]);
      return stat;
}

export const useStake = (chainId: number, amount: string, info: any, poolInfo: any) => {
    const { account } = useWeb3React();
    const contract = useContract(getMasterChefAbi() as unknown as AbiItem, poolInfo ? poolInfo?.address : '');
    const dispatch = useDispatch();
    const tokenContract = useContract(ERC20 as unknown as AbiItem, poolInfo ? poolInfo?.stakeTokenAddress : '');

    const handleStake= useCallback(
        async (estimate: string): Promise<string | undefined> => {

          if (!account || !contract || !info ) return '';

          const tx = await contract.stake(0, estimate); 
    
          const receipt = await tx.wait();
    
          if (receipt.status !== 1) {
            throw new Error();
          }
          return tx.txHash;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [account, contract, amount]
    );

    const handleUnStake= useCallback(
      async (estimate: string): Promise<string | undefined> => {

        if (!account || !contract || !info ) return '';
        
        if( estimate === "0" ) {
            const tx  = await contract.unstake(0, 0); 
            const receipt = await tx.wait();
  
            if (receipt.status !== 1) {
              throw new Error();
            }
            return tx.txHash;
        }
        else {
          let decimals = 18;
          if( tokenContract?.address === '0x2F733095B80A04b38b0D10cC884524a3d09b836a' || tokenContract?.address === '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913')
            decimals = 6;

           const tx = await contract.unstake(0, estimate); 
           const receipt = await tx.wait();
  
           if (receipt.status !== 1) {
             throw new Error();
           }
         //   dispatch(fetchBridgeUserDataAsync(account, chainId.toFixed(), info, tokenFrom));
           dispatch(fetchPresaleUserInfoAsync(chainId.toString(), account))
           return tx.txHash;

        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [account, contract, amount]
  );

    const handleApprovePurchaseToken = useCallback(async (): Promise<string | undefined> => {


        if (!account || !contract || !tokenContract ) return '';
        const tx = await tokenContract.approve(contract.address, ethers.constants.MaxUint256);
    
        dispatch(setPendingTxHash(tx.hash));
        const receipt = await tx.wait();
    
        if (receipt.status !== 1) {
          throw new Error();
        }
    
        // dispatch(fetchTheatreUserDataAsync(account, selectedChainId));
    
        return tx.txHash;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [account, dispatch, contract, tokenContract]);

    return {
        onStake: handleStake,
        onApprove: handleApprovePurchaseToken,
        onUnstake: handleUnStake
    }
}
