import {FC, useEffect, useState} from 'react';
import { Box, Button, CircularProgress, TextField, Typography, styled } from '@mui/material';
import ImageUploadBox from 'components/common/UploadBox/ImapeUploadBox';
import { ImageType } from 'react-images-uploading';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setPresaleReloadCountAsync } from 'state/actions';
import { usePinata } from 'hooks/usePinata';
import {ContainedButton} from "../../../common/Button";
import {ContainedTextField} from "../../../common/Input/ContainedTextField";

const InfoCardLabel = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    cursor: 'pointer',
    textWrap: 'wrap',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
      lineHeight: '18px',
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      lineHeight: '18px',
      textAlign: 'right',
    },
  }));


  const LenTitle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    color: 'rgba(255,255,255,0.7)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '10px',
      lineHeight: '12px',
      img: {
        display: 'none',
      },
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '10px',
      lineHeight: '12px',
      img: {
        display: 'block',
      },
    },
  }));


interface Props {
    close: () => void
    tokenImage: string
    presaleAddress: string
    socials?: any
}

const DescriptionEdit: FC<Props> = ({ tokenImage, close, presaleAddress, socials  }) => {
    const [imageUploaded, setImageUploaded] = useState(false);
    const [images, setImages] = useState<ImageType[]>([]);
    const [tokenDescrtiption, setTokenDescription] = useState<string>('');
    const [tokenTg, setTokenTg] = useState<string>('');
    const [tokenX, setTokenX] = useState<string>('');
    const [tokenWeb, setTokenWeb] = useState<string>('');
    const [customUrl1, setCustomUrl1] = useState<string>('');
    const [customUrl2, setCustomUrl2] = useState<string>('');
    const [customLabel1, setCustomLabel1] = useState<string>('');
    const [customLabel2, setCustomLabel2] = useState<string>('');
    const [customLinks, setCustomLinks] = useState<any>([]);
    const [isImageUploading, setIsImageUploading] = useState(false);
    const [isImageUploadingError, setIsImageUploadingError] = useState(false);
    const [imageUrlUploaded, setImageUrlUploaded] = useState("");
    const [socialsFilled, setSocialsFilled] = useState(false);

    const dispatch = useAppDispatch();
    const { presaleReloadCount } = useAppSelector((presale) => presale.presale);
    const {uploadImageToPinata, deleteJsonFromPinata, uploadJsonToPinata} = usePinata(0)


    useEffect(() => {
        if (socials && !socialsFilled) {
            if (socials?.tokenDescription?.length > 0) {
                setTokenDescription(socials?.tokenDescription)
                setSocialsFilled(true)
            }
            if (socials?.tokenWeb?.length > 0) {
                setTokenWeb(socials?.tokenWeb)
                setSocialsFilled(true)
            }
            if (socials?.tokenX?.length > 0) {
                setTokenX(socials?.tokenX)
                setSocialsFilled(true)
            }
            if (socials?.tokenTg?.length > 0) {
                setTokenTg(socials?.tokenTg)
                setSocialsFilled(true)
            }
            if (socials?.customLinks?.length > 0) {
                setCustomLabel1(socials?.customLinks[0]?.[0])
                setCustomLinks(socials?.customLinks);
                setSocialsFilled(true)
            }
            if (socials?.customLinks?.length > 0) {
                setCustomLabel2(socials?.customLinks[1]?.[0])
                setCustomLinks(socials?.customLinks);
                setSocialsFilled(true)
            }
            if (socials?.customLinks?.length > 0) {
                setCustomUrl1(socials?.customLinks[0]?.[1])
                setCustomLinks(socials?.customLinks);
                setSocialsFilled(true)
            }
            if (socials?.customLinks?.length > 0) {
                setCustomUrl2(socials?.customLinks[1]?.[1])
                setCustomLinks(socials?.customLinks);
                setSocialsFilled(true)
            }
        }
    }, [socials]);

    const onChangeDescription = (e: any) => {
      const newAmount = e.target.value;
      if( newAmount.length > 250 )
          return
      setTokenDescription(newAmount);
  };
    const onChangeTg = (e: any) => {
      const newAmount = e.target.value;
      if( newAmount.length > 250 )
          return
      setTokenTg(newAmount);
  };
  const onChangeX = (e: any) => {
      const newAmount = e.target.value;
      if( newAmount.length > 250 )
          return
      setTokenX(newAmount);
  };

  const onChangeWeb = (e: any) => {
    const newAmount = e.target.value;
    if( newAmount.length > 250 )
        return
    setTokenWeb(newAmount);
  };

    const onChangeCustomUrl1 = (e: any) => {
        const newAmount = e.target.value;
        setCustomUrl1(newAmount);
        setCustomLinks([
            [customLabel1, newAmount],
            [customLabel2, customUrl2],
        ]);
    };
    const onChangeCustomUrl2 = (e: any) => {
        const newAmount = e.target.value;
        setCustomUrl2(newAmount);
        setCustomLinks([
            [customLabel1, customUrl1],
            [customLabel2, newAmount],
        ]);
    };

    const onChangeCustomLabel1 = (e: any) => {
        const newAmount = e.target.value;
        setCustomLabel1(newAmount);
        setCustomLinks([
            [newAmount, customUrl1],
            [customLabel2, customUrl2],
        ]);
    };
    const onChangeCustomLabel2 = (e: any) => {
        const newAmount = e.target.value;
        setCustomLabel2(newAmount);
        setCustomLinks([
            [customLabel1, customUrl1],
            [newAmount, customUrl2],
        ]);
    };


    const imageUpload = (imageList: any) => {
        setImages(imageList)
      };


      const onConfirm = async () => {
        if( tokenImage ){

            setIsImageUploading(true)

            try{
                await deleteJsonFromPinata(presaleAddress)
            }
            catch(err){

            }
            try{
                await uploadJsonToPinata(presaleAddress,tokenDescrtiption, tokenTg, tokenX, tokenWeb, "", customLinks)
            }
            catch(err){

            }
            setIsImageUploading(false)
            close()
            dispatch(setPresaleReloadCountAsync(presaleReloadCount + 1));
        }
        else{
            
            if( images.length > 0 ){

                try{
                    if( images[0].file ){
                        setIsImageUploading(true)
                        const url = await uploadImageToPinata(presaleAddress, images[0].file, 1);
                        if( url.length > 0 ){

                            setIsImageUploading(false)
                            setIsImageUploadingError(false)
                            setImageUrlUploaded(url)
                        } else {
                            console.log(`Error while Image update!`)
                            setIsImageUploading(false)
                            
                            setIsImageUploadingError(true)
                        }
                    }
                }
                catch(err){
                    setIsImageUploading(false)

                }
            }
            try{
                await deleteJsonFromPinata(presaleAddress)
            }
            catch(err){

            }
            try{
                await uploadJsonToPinata(presaleAddress,tokenDescrtiption, tokenTg, tokenX, tokenWeb, "", customLinks)
            }
            catch(err){

            }
            close()
            dispatch(setPresaleReloadCountAsync(presaleReloadCount + 1));
        }
      };

  return (
    <>
        <Box mt={2} display='flex' flexDirection='column' justifyContent='center' alignItems='center' sx={{border: '0px solid blue'}}>
            <InfoCardLabel mt={1}>
                Deployer Dashboard
            </InfoCardLabel>
                {tokenImage?.length > 0 &&
                                <Box mt={2} display='flex' sx={{position: 'relative'}}>
                                <img src={tokenImage?.length > 0 ? tokenImage : ""} style={{
                                    minWidth: '165px',
                                    minHeight: '165px',
                                    maxWidth: '200px',
                                    
                                    objectFit: 'cover',
                                    borderRadius: '10px'
                                }}/>
                                </Box>
                }
                {tokenImage?.length === 0 || !tokenImage &&
                    <Box justifyContent='center' alignItems='center' textAlign='center'>
                        <ImageUploadBox imageUpload={imageUpload} hide={imageUploaded}/>
                    </Box>
                }
                <Box gap={1} flexDirection='column'  display='flex' sx={{
                        border: '0px solid grey',
                        padding: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        position: 'relative'
                 }}>
                  <LenTitle sx={{position: "absolute", top: 5, right: 15}} >
                    {tokenDescrtiption.length}/250 
                  </LenTitle>  
                  <ContainedTextField variant={'outlined'} disabled={images.length === 0 && !tokenImage} sx={{width: '100%'}} rows={3} multiline={true}  placeholder='Presale Description'
                        onChange={onChangeDescription}  autoComplete='off' 
                        label="Presale Description" value={tokenDescrtiption} />
                  <Box display='flex' mt={'16px'} sx={{flexDirection: {xs: 'column', md: "row"}}} gap={1}>
                  <ContainedTextField variant={'outlined'} disabled={images.length === 0 && !tokenImage} sx={{width: '100%'}} rows={1} multiline={false}  placeholder='Telegram URL'
                        onChange={onChangeTg}  autoComplete='off' 
                        label="Telegram URL" value={tokenTg} />
                  <ContainedTextField variant={'outlined'} disabled={images.length === 0 && !tokenImage} sx={{width: '100%'}} rows={1} multiline={false}  placeholder='Twitter URL'
                        onChange={onChangeX}  autoComplete='off' 
                        label="Twitter URL" value={tokenX} />
                  <ContainedTextField variant={'outlined'} disabled={images.length === 0 && !tokenImage} sx={{width: '100%'}} rows={1} multiline={false}  placeholder='Web URL'
                        onChange={onChangeWeb}  autoComplete='off' 
                        label="Web URL" value={tokenWeb} />

                  </Box>
                    <Typography sx={{
                        fontSize: '20px',
                        border: '0px solid red',
                        fontFamily: 'Prompt',
                        fontStyle: 'normal',
                        fontWeight: '600',
                    }}>
                        Custom Links
                    </Typography>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: {xs : 'column', md: 'column'},
                        gap: '16px',
                        border: '0px solid red',
                        width: '100%',
                        position: 'relative',
                    }}>
                        <Box sx={{
                            border: '0px solid blue',
                            display: 'flex',
                            flexDirection: {xs : 'column', md: 'row'},
                            gap: '16px',
                            flex: 1,
                            padding: '0px',
                            position: 'relative'
                        }}>
                            <ContainedTextField variant={'outlined'} disabled={images.length === 0 && !tokenImage} sx={{width: '100%'}} rows={1} multiline={false}  placeholder='LABEL'
                                                onChange={onChangeCustomLabel1}  autoComplete='off'
                                                label="LABEL" value={customLabel1} />
                            <ContainedTextField variant={'outlined'} disabled={images.length === 0 && !tokenImage} sx={{width: '100%'}} rows={1} multiline={false}  placeholder='URL'
                                                onChange={onChangeCustomUrl1}  autoComplete='off'
                                                label="URL" value={customUrl1} />

                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: {xs : 'column', md: 'row'},
                            flex: 1,
                            gap: '16px',
                            border: '0px solid blue',
                            padding: '0px',
                            position: 'relative'
                        }}>
                            <ContainedTextField variant={'outlined'} disabled={images.length === 0 && !tokenImage} sx={{width: '100%'}} rows={1} multiline={false}  placeholder='LABEL'
                                                onChange={onChangeCustomLabel2}  autoComplete='off'
                                                label="LABEL" value={customLabel2} />
                            <ContainedTextField variant={'outlined'} disabled={images.length === 0 && !tokenImage} sx={{width: '100%'}} rows={1} multiline={false}  placeholder='URL'
                                                onChange={onChangeCustomUrl2}  autoComplete='off'
                                                label="URL" value={customUrl2} />
                        </Box>


                    </Box>

                </Box>
              </Box>
        <Box display='flex' gap={'8px'} mt={2} justifyContent='center' alignItems={'center'} sx={{border: '0px solid red', width: '100%'}}>

            <Box display='flex' justifyContent='center' alignItems={'center'} sx={{border: '0px solid red', flex: 1}}>

                <ContainedButton onClick={onConfirm} sx={{width: '50%', height: '53.13px'}}>
                    {isImageUploading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : "Confirm"}
                </ContainedButton>
            </Box>
            <Box display='flex' justifyContent='center' alignItems={'center'} sx={{border: '0px solid red', flex: 1}}>
                <ContainedButton onClick={close} sx={{width: '50%', height: '53.13px'}}>
                    Cancel
                </ContainedButton>
            </Box>
        </Box>
    </>
  );
};

export default DescriptionEdit;