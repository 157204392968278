const stakingLauncherInfo = [
    {
        chainId: 169,
        contractAddress: "0x724E0A3c4BCd95fe88f9D4302E3c8984BFc0AAa1",
        tokenAddress: "",
        saleTokenAddress: ""
    },
    {
        chainId: 250,
        contractAddress: "0x724E0A3c4BCd95fe88f9D4302E3c8984BFc0AAa1",
        tokenAddress: "",
        saleTokenAddress: ""
    }
]

const stakingLauncherInfos = [...stakingLauncherInfo];

export {stakingLauncherInfos}