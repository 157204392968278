import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useState } from "react";
import { AbiItem } from 'web3-utils';
import { BigNumber,  ethers,  utils } from "ethers";
import { useDispatch } from 'react-redux';
import {  fetchPresaleUserInfoAsync } from "state/actions";
import pairAbi from 'config/abi/LpPair.json';
import ERC20 from 'config/abi/Erc20.json';
import multicall from 'utils/multicall';

import LpPair from 'config/abi/LpPair.json';

import { FarmInfo } from "types/stake";
import { getMasterChefAbi } from "utils/stake";
import {useContract, useToken} from "./useContract";
import { setPendingTxHash } from 'state/modal/modalSlice';

import { getBalanceInEther,  } from "utils";
import { getTokenInfo } from "utils/tokens";
import { getTokenDeployerInfo } from "utils/deployTokens";
import { getTokensDexInfo } from "config/constants/backend";


export const useStakeEstimateInfo = (chainId: number, pool: FarmInfo, counter: number, tokenInfo: any, ftmPrice: number) => {
    const { account } = useWeb3React();
    const [stat, setStat] = useState<any>();
    const contract = useContract(getMasterChefAbi() as unknown as AbiItem, pool ? pool?.masterChefAddress : '');
    const token = useContract(ERC20 as unknown as AbiItem, pool ? pool?.stakingToken : '');
    const tokenLp = useContract(pairAbi as unknown as AbiItem, pool ? pool?.stakingToken0 : '');
    const rewardTokInfo = getTokenInfo(pool?.stakingToken)
    const deployerTokInfo = getTokenDeployerInfo(chainId)
    const [loading, setLoading] = useState(true);
    const [retryCount, setRetryCount] = useState(0);
    const maxRetries = 10;
    const retryDelay = 2000;
    useEffect(() => {
        async function getStakeInfo() {
          try {
              setLoading(true)
            if( pool && rewardTokInfo){
              let poolRewards = 0;
              let accountBalance = BigNumber.from(0);
              let userStaked = BigNumber.from(0);
              let stakeTime = BigNumber.from(0);
              let allowance = BigNumber.from(0);
              let pendingRewards = BigNumber.from(0);
              let tvlUSDC = 0;
  
              const [poolInfo, totalAllocPoint, rewardToken, poolEndTime] = await multicall(
                getMasterChefAbi(),
                [
                  {
                    address: pool?.masterChefAddress,
                    name: 'poolInfo',
                    params: [pool.poolId],
                  },
                  {
                    address: pool?.masterChefAddress,
                    name: 'totalAllocPoint',
                    params: [],
                  },
                  {
                    address: pool?.masterChefAddress,
                    name: 'rewardToken',
                    params: [],
                  },
                  {
                    address: pool?.masterChefAddress,
                    name: 'poolEndTime',
                    params: [],
                  },
                ]
            );
            let decimals = 18

            if( rewardTokInfo )
              decimals = rewardTokInfo.decimals

            const [totalRewardsRaw] = await multicall(
              ERC20,
              [
                {
                  address: rewardToken[0],
                  name: 'balanceOf',
                  params: [pool?.masterChefAddress],
                },
              ]
            );
            const totalRewards = getBalanceInEther(totalRewardsRaw[0]);
  
            const allocPoint = Number(poolInfo.allocPoint);
            const totalAllocPointRaw = Number(totalAllocPoint);
            const poolWeight = allocPoint / totalAllocPointRaw;
  
            let totalStaked = BigNumber.from(0)

            const [balance, stakingTokenSupply, totalStakedRaw] = await multicall(
              ERC20,
              [
                {
                  address: rewardToken[0],
                  name: 'balanceOf',
                  params: [pool?.masterChefAddress],
                },
                {
                  address: pool?.stakingToken,
                  name: 'totalSupply',
                  params: [],
                },
                {
                  address: pool?.stakingToken,
                  name: 'balanceOf',
                  params: [pool?.masterChefAddress],
                },
              ]
            );
            totalStaked = totalStakedRaw[0]
            
  
            let depositFee = Number(poolInfo.depositFee);
            if( depositFee > 0 )
              depositFee = depositFee / 100

            poolRewards = (getBalanceInEther(balance) - Number(utils.formatUnits(totalStaked, decimals)) )/ 2;
            const aprYear = poolRewards * allocPoint / 100 / getBalanceInEther(totalStaked) * 100;
            let apr = 0;
            const  lockTime =  poolInfo.lockTime;
            let tvl = 0;
            const curretnToken = getTokenInfo(pool?.stakingToken);

            let currentTokenPrice = 1;
            
            if( curretnToken ){
              if( curretnToken.cgc.length > 0 ){
                const offchainData = await getTokensDexInfo({});
                if(!offchainData)
                  return
                for( let i = 0; i < offchainData.length; i+=1 ){
                    if( offchainData[i].tokenName === curretnToken.cgc &&  offchainData[i].chain === curretnToken.chain )
                    {
                      if( offchainData[i].tokenName === 'ETH' ) {
                        // currentTokenPrice = offchainData[i].priceNative

                        tvl = Number(utils.formatUnits(totalStaked,decimals));
                        apr = ((totalRewards * tokenInfo.tokenPriceFTM ) * poolWeight) / (tvl * ftmPrice) * 600;

                        break;
                      } else{
                        currentTokenPrice = offchainData[i].priceNative
                        tvl = Number(utils.formatUnits(totalStaked,decimals));
                        apr = ((totalRewards * tokenInfo.tokenPriceFTM ) * poolWeight) / (tvl * currentTokenPrice) * 600;
                        break;
                      }

                    }
                  }
              }
              else if( curretnToken.lp.length  >  0){                     
                const [reserves] = await multicall(
                  LpPair,
                  [
                    {
                      address: curretnToken.lp,
                      name: 'getReserves',
                      params: [],
                    },
                  ]
                );
  
                const [token0] = await multicall(
                  LpPair,
                  [
                    {
                      address: curretnToken.lp,
                      name: 'token0',
                      params: [],
                    }
                  ]
                );
                if( token0[0].toLowerCase() === deployerTokInfo?.nativeToken.toLowerCase() ) {
                  currentTokenPrice = getBalanceInEther(reserves._reserve0) / getBalanceInEther(reserves._reserve1)
                }
                else {
                  currentTokenPrice = getBalanceInEther(reserves._reserve1) / getBalanceInEther(reserves._reserve0)
                }
                tvl = Number(utils.formatUnits(totalStaked,decimals));
                apr = ((totalRewards * tokenInfo.tokenPriceFTM ) * poolWeight) / (tvl * currentTokenPrice) * 600;
              } else if( curretnToken.address.toLowerCase() === deployerTokInfo?.nativeToken.toLowerCase() ){
                tvl = Number(utils.formatUnits(totalStaked,decimals));
                apr = ((totalRewards * tokenInfo.tokenPriceFTM ) * poolWeight) / (tvl * ftmPrice) * 600;
              } else if( curretnToken.address === '0x2F733095B80A04b38b0D10cC884524a3d09b836a' ||
                 curretnToken.address === '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913' ){
                tvl = Number(utils.formatUnits(totalStaked,decimals));
                let usdcPriceInFtm = 1;
                if( ftmPrice <= 1 )
                   usdcPriceInFtm = 1 + (1 - ftmPrice )
                else
                  usdcPriceInFtm = 1 / ftmPrice
                apr = ((totalRewards * tokenInfo.tokenPriceFTM ) * poolWeight) / (tvl * usdcPriceInFtm) * 600;
                currentTokenPrice = usdcPriceInFtm
              }
            } else {
              tvl = Number(utils.formatUnits(totalStaked,decimals));
              apr = ((totalRewards * tokenInfo.tokenPriceFTM ) * poolWeight) / (tvl * currentTokenPrice) * 600;
            }

            if( account ){
              const [userInfo, pendingRewardsRaw] = await multicall(
                getMasterChefAbi(),
                [
                  {
                    address: pool?.masterChefAddress,
                    name: 'userInfo',
                    params: [pool.poolId, account],
                  },
                  {
                    address: pool?.masterChefAddress,
                    name: 'pendingReward',
                    params: [pool.poolId, account],
                  },
                ]
              );
              const [accountBalanceRaw, allowanceRaw] = await multicall(
                ERC20,
                [
                  {
                    address: pool?.stakingToken,
                    name: 'balanceOf',
                    params: [account],
                  },
                  {
                    address: pool?.stakingToken,
                    name: 'allowance',
                    params: [account, pool?.masterChefAddress ],
                  }
                ]
              );

              accountBalance = accountBalanceRaw[0]
              allowance = allowanceRaw[0]
              userStaked = userInfo.amount;
              pendingRewards = pendingRewardsRaw[0]
              stakeTime = userInfo.stakeTime;
            }

            const currentDate = Math.floor((new Date()).getTime() / 1000)

            if( currentDate > poolEndTime[0] ){
              apr = -1;
            }
                setStat({poolInfo, totalAllocPoint, poolWeight, totalStaked, stakingTokenSupply, rewardPerSecond : 0, aprYear, accountBalance, userStaked, stakeTime, allowance, pendingRewards, lockTime ,tvl, stakeTokenPriceFTM: currentTokenPrice, depositFee, apr, decimals});
            setLoading(false);
            }
            else if(pool && !contract && !token) {
                throw("err")
            }

          } catch (err) {
              console.error('getStakeInfo Error', err)

              if (
                  // @ts-ignore
                  err.message.includes("Invalid JSON RPC response")
              ) {
                  if (retryCount < maxRetries) {
                      setTimeout(() => {
                          setRetryCount((prevCount) => prevCount + 1);
                          getStakeInfo();
                      }, retryDelay);
                  } else {
                      console.error("Max retry attempts reached");
                      setLoading(false);
                  }
              } else {
                  setLoading(false);
              }
          }
        }

        getStakeInfo().then();
      }, [ chainId, pool, contract, token, account, counter, ftmPrice]);
      return {stat, loading};
}


export const useStake = (chainId: number, amount: string, info: any, poolInfo: any) => {
    const { account } = useWeb3React();
    const contract = useContract(getMasterChefAbi() as unknown as AbiItem, info ? info?.masterChefAddress : '');
    const dispatch = useDispatch();
    const tokenContract = useToken( info?.isLp ? info?.stakingToken0 : info?.stakingToken);
    const handleStake= useCallback(
        async (estimate: string): Promise<string | undefined> => {
    
          if (!account || !contract || !info ) return '';

          let decimals = 18;
          if( tokenContract?.address === '0x2F733095B80A04b38b0D10cC884524a3d09b836a' || tokenContract?.address === '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913')
            decimals = 6;

          const tx = await contract.stake(info.poolId, estimate); 
    
          const receipt = await tx.wait();
    
          if (receipt.status !== 1) {
            throw new Error();
          }
          return tx.txHash;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [account, contract, amount]
    );

    const handleUnStake= useCallback(
      async (estimate: string): Promise<string | undefined> => {
  
        if (!account || !contract || !info ) return '';
        
        if( estimate === "0" ) {
            const tx  = await contract.unstake(info.poolId, 0); 
            const receipt = await tx.wait();
  
            if (receipt.status !== 1) {
              throw new Error();
            }
            return tx.txHash;
        }
        else {
          let decimals = 18;
          if( tokenContract?.address === '0x2F733095B80A04b38b0D10cC884524a3d09b836a' || tokenContract?.address === '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913')
            decimals = 6;

           const tx = await contract.unstake(info.poolId, ethers.utils.parseUnits(amount,decimals)); 
           const receipt = await tx.wait();
  
           if (receipt.status !== 1) {
             throw new Error();
           }

           dispatch(fetchPresaleUserInfoAsync(chainId.toString(), account))
           return tx.txHash;

        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [account, contract, amount]
  );

    const handleApprovePurchaseToken = useCallback(async (): Promise<string | undefined> => {
        if (!account || !contract || !tokenContract ) return '';
        const tx = await tokenContract.approve(contract.address, ethers.constants.MaxUint256);
    
        dispatch(setPendingTxHash(tx.hash));
        const receipt = await tx.wait();
    
        if (receipt.status !== 1) {
          throw new Error();
        }

        return tx.txHash;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [account, dispatch, contract, tokenContract]);

    return {
        onStake: handleStake,
        onApprove: handleApprovePurchaseToken,
        onUnstake: handleUnStake
    }
}
