
const presaleInfo = [
    {
        chainId: 169,
        contractAddress: "0x05c733e5e2c2138caf25b70bb15b4f26931f8265",
        tokenAddress: "0x3a700cc65db2ce1a096c5d691bcacae56c41431d",
        saleTokenAddress: ""
    },
    {
        chainId: 250,
        contractAddress: "0xA808D494D5095a6052740b6Ee49206Ec6A418c08", // PROD
        // contractAddress: "0x3Ef2a935De17b0F1a61306F7a41da1B44CB0477E",//TEST
        tokenAddress: "",
        saleTokenAddress: ""
    }
]



const presaleInfos = [...presaleInfo];
export {presaleInfos}