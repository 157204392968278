import {Box, Checkbox, styled, Typography} from "@mui/material";
import React, {FC} from "react";

const Card = styled(Box)(({ theme }) => ({
    background: "rgba(42,49,57, 0.4)",
    [theme.breakpoints.up('xs')]: {
        flexDirection: 'column',
        gap: '20px'
    },
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        gap: '50px'
    },

}));


const FilterLabel = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '400',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '21px',
    color: 'white',
    margin: 'auto',
    userSelect: 'none',


    img: {
        width: '20px',
        marginRight: '8px',
    },

    [theme.breakpoints.up('xs')]: {
        fontSize: '12px',
        lineHeight: '16px',
        img: {
            display: 'none',
        },
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '16px',
        lineHeight: '21px',
        img: {
            display: 'block',
        },
    },
}));

interface FilterBoxProps {
    filterBoxInfo?: {
        label?: string;
        isChecked?: boolean;
        handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    }[];
}

const FilterBox: FC<FilterBoxProps> = ({filterBoxInfo}) =>
{
    return(
        <Card mt={1} justifyContent='space-around' gap='5px' display='flex'
              sx={{minHeight: '50px', padding: '10px', borderRadius: '10px', maxWidth: '900px', minWidth: '300px',}}>
        <FilterLabel sx={{cursor: 'text'}}>
            Filter by:
        </FilterLabel>

         {filterBoxInfo?.map((item, index) => {
                 return (
                     <Box display='flex' key = {index}>
                         <FilterLabel
                             onClick={() => item?.handleChange?.({ target: { checked: !item?.isChecked } } as React.ChangeEvent<HTMLInputElement>)}>
                             {item?.label}
                         </FilterLabel>
                         <Checkbox checked={item?.isChecked}
                                   onChange={item?.handleChange}/>
                     </Box>
                 );
             })
         }
    </Card>);
}

export default FilterBox;