import {FC} from 'react';

import {
    Box,
    Button,
    Grid,
    styled,
    Typography,
    DialogActions,
    Divider,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { ImageType } from 'react-images-uploading';
import TokenomicsDiagramm from "./Components/TokenomicsDiagramm";
import ModalDialog from "../../common/ModalDialog/ModalDialog";
import ContainedInfoBox from "../../common/InfoBox/ContainedInfoBox";
import {InfoLabelBegin, InfoLabelEnd} from "../../common/Typography/InfoLabel";
import {ContainedButton} from "../../common/Button";


const ToggleSection = styled(Box)(() => ({
    position: 'absolute',
    right: '0',
    top: '0',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '45px',
    height: '45px',
    zIndex: 1
}));



interface PresaleUIInfo {
    tokenName: string;
    tokenSymbol: string;
    totalSupply: string;
    tokensPresale: string;
    presaleTokenPrice: string;
    softCap: string;
    minBuy: string;
    presaleStartsIn: string;
    hardCap: string;
    maxBuy: string;
    presaleLen: string;
    amm: { address?: string; name?: string } | undefined;
    lockDuration: string;
    isBurnChecked: boolean;
    isLiquidityChecked: boolean;
    isLiquidityCreateChecked: boolean;
    launchPrice: string;
    isElock: boolean;
}

const getLockDuration = (info: PresaleUIInfo): string => {
    if (info.isElock) {
        return "ELock " + info.lockDuration + " days";
    }
    else if (info.isBurnChecked) {
        return "SCC";
    } else if (info.isLiquidityChecked) {
        return info.lockDuration + " days";
    } else {
        return "No Lock";
    }
};



const calculateTotalSupply = (totalSupply:any): string => {

    return totalSupply.toString();
}

const calculateHardCap = (data: PresaleUIInfo): string => {
    const presaleTokenCount = parseFloat(data.tokensPresale);
    const presalePrice = parseFloat(data.presaleTokenPrice)

    return (presaleTokenCount * presalePrice).toString();
}

const calculateLiquidityAtLaunch = (data: PresaleUIInfo): string => {
        return  (parseFloat(calculateHardCap(data)) * 2).toString();
}

const calculateTokensUsedForLiquidityIfHardCap = (data: PresaleUIInfo): string => {

    const hardCap = parseFloat(calculateHardCap(data))
    const presaleLaunchPrice = parseFloat(data.launchPrice)

    return (hardCap / presaleLaunchPrice).toString();
}

const calculateTokensSentToCreator = (data: PresaleUIInfo): string => {
    const totalSupply = parseFloat(data.totalSupply);
    const presaleTokenCount = parseFloat(data.tokensPresale);
    const presaleLaunchPrice = parseFloat(data.launchPrice);
    const hardCap = parseFloat(calculateHardCap(data))

    const tokensSentToCreator = totalSupply - (totalSupply/100)- presaleTokenCount - (hardCap/presaleLaunchPrice);
    return tokensSentToCreator.toString();
}

const calculateTokensSentToDrop = (data: PresaleUIInfo): string => {
    const totalSupply = parseFloat(data.totalSupply);


    const tokensSentToDrop = (totalSupply/100) * 0.5;
    return tokensSentToDrop.toString();
}

const calculateTokensSentToBasedLabs= (data: PresaleUIInfo): string => {
    const totalSupply = parseFloat(data.totalSupply);

    const tokensSentToBasedLabs = (totalSupply/100) * 0.5;
    return tokensSentToBasedLabs.toString();
}

const calculateMCapAtLaunch = (data: PresaleUIInfo): string => {

    const mcap = Number(data.totalSupply) * Number(data.launchPrice);
    return mcap.toString();
}


function formatValue(value: string): string {
    const numberValue = parseFloat(value);

    if (isNaN(numberValue) || !isFinite(numberValue)) {
        return '--.--';
    }

    const formattedValue = numberValue === Math.floor(numberValue)
        ? numberValue.toFixed(0)
        : numberValue.toFixed(4);

    return numberValue >= 1000
        ? parseFloat(formattedValue).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 4 })
        : formattedValue;
}


interface DeployPresaleModalProps {
    isDeployed: boolean;
    open: boolean;
    handleClose: () => void;
    presaleInfo: PresaleUIInfo;
    images: ImageType[];
    handleDeploy: () => void;
}


const DeployPresaleModal: FC<DeployPresaleModalProps> = ({isDeployed, open,
                                                             handleClose,
                                                             presaleInfo,
                                                             images, handleDeploy}) =>
{
    const {
        tokenName, tokenSymbol, totalSupply,
        tokensPresale, presaleTokenPrice, softCap,
        minBuy, presaleStartsIn, hardCap,
        maxBuy, presaleLen, amm, lockDuration,
        isBurnChecked, isLiquidityChecked, isLiquidityCreateChecked,
        launchPrice, isElock
    } = presaleInfo;

    const liquidityAtLaunch = calculateLiquidityAtLaunch(presaleInfo);
    const tokensUsedForLiquidityIfHardCap = calculateTokensUsedForLiquidityIfHardCap(presaleInfo);
    const tokensSentToCreator = calculateTokensSentToCreator(presaleInfo);
    const newlockDuration = getLockDuration(presaleInfo);
    const newTotalSupply = calculateTotalSupply(presaleInfo.totalSupply);

    const mCapAtLaunch = calculateMCapAtLaunch(presaleInfo);
    const tokensSentToBasedLabs = calculateTokensSentToBasedLabs(presaleInfo);
    const tokensSentToDrop= calculateTokensSentToDrop(presaleInfo);
    const hardcap = calculateHardCap(presaleInfo);

    const tokenDiagrammInfo = [
        { label: 'Tokens for Presale', value: presaleInfo.tokensPresale, color: 'green' },
        { label: 'Tokens for LP (if hardcap is reached)', value: tokensUsedForLiquidityIfHardCap, color: 'olive' },
        { label: 'Tokens held by Creator', value: tokensSentToCreator, color: 'red' },
        { label: 'Tokens sent to Airdrop Farming Pools', value: tokensSentToDrop, color: 'orange' },
        { label: 'Tokens sent to Based Labs Treasury', value: tokensSentToBasedLabs, color: 'blue' },
    ];

    const ammName = isElock
        ? 'EqualizerDex (V2)'
        : (amm?.address ? amm?.name : '--.--');



    return (
        <ModalDialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <ToggleSection onClick={handleClose}>
                <CloseIcon sx={{width: '20px', height: '20px'}}/>
            </ToggleSection>

            <Box
                mb={2}
                mt={4}
                justifyContent="center"
                sx={{
                    border: '0px solid green',
                }}
            >
                <Grid
                    container
                    gap={2}
                    justifyContent="center"
                    sx={{
                        border: '0px solid red',
                    }}
                >

                    <Grid item xs={12} md={4}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                height: '100%',
                                maxWidth: '100%',
                                minWidth: '200px',
                                borderRadius: '10px',
                                background: "rgba(6,1,1,0.3)",
                                overflow: 'hidden',
                                p: 2
                            }}
                        >
                            {images.map((image, index) => (
                                <div key={index} className="image-item">
                                    <img
                                        src={image['data_url']}
                                        alt="Logo"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: '10px',
                                            objectFit: 'contain'
                                        }}
                                        />
                                </div>
                            ))}
                        </Box>
                    </Grid>


                    <Grid item xs={12} md={6}>

                        <Box gap={2}
                             display="flex"
                             flexDirection="column"
                             justifyContent='space-between'
                             alignItems='space-between'
                             sx={{border: '0px solid red', height: '100%'}}>


                            <Box
                                flex={1}
                                textAlign="center"
                                alignItems="center"
                                justifyContent="center"
                                display="flex"
                                sx={{
                                    background: "rgba(6,1,1,0.3)",
                                    padding: '10px',
                                    minHeight: '34px',
                                    borderRadius: '10px',
                                    minWidth: '200px',
                                    maxWidth: '100%',
                                    wordWrap: 'break-all',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                <Typography variant="h1"
                                            sx={{width: '100%', wordBreak: 'break-word'}}
                                >{tokenName}</Typography>
                            </Box>



                            <Box
                                flex={1}
                                textAlign="center"
                                alignItems="center"
                                justifyContent="center"
                                display="flex"
                                sx={{
                                    background: "rgba(6,1,1,0.3)",
                                    padding: '10px',
                                    minHeight: '34px',
                                    borderRadius: '10px',
                                    minWidth: '200px',
                                    maxWidth: '100%',
                                    wordWrap: 'break-all',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                <Typography variant="h1"
                                            sx={{width: '100%', wordBreak: 'break-word'}}
                                >{tokenSymbol}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>


            <Box mb={2}>
                <Divider sx={{borderColor: 'white', my: 2}}/>
                <Typography
                    variant="h6"
                    align="center"
                    gutterBottom
                    sx={{fontSize: '2rem', fontWeight: 'bold'}}
                >
                    Tokenomics Overview
                </Typography>


                <TokenomicsDiagramm tokenDiagrammInfo={tokenDiagrammInfo} totalSupply={newTotalSupply}/>
            </Box>


                <Divider sx={{borderColor: 'white', mb: 2, mt: 2}}/>
                <Grid container display='flex' spacing={2} justifyContent='center'  sx={{border: '0px solid red'}}>
                    <Grid item xs={12} md={6} sx={{}}>
                        <Box display='flex' flexDirection='column' gap={1}>
                            <ContainedInfoBox>
                                <InfoLabelBegin> Presale Price: </InfoLabelBegin>
                                <InfoLabelEnd>{formatValue(presaleTokenPrice)}</InfoLabelEnd>
                            </ContainedInfoBox>
                            <ContainedInfoBox>
                                <InfoLabelBegin> Soft Cap (ftm): </InfoLabelBegin>
                                <InfoLabelEnd>{formatValue(softCap)}</InfoLabelEnd>
                            </ContainedInfoBox>
                            <ContainedInfoBox>
                                <InfoLabelBegin> HardCap (ftm) </InfoLabelBegin>
                                <InfoLabelEnd> {formatValue(hardcap)}</InfoLabelEnd>
                            </ContainedInfoBox>
                            <ContainedInfoBox>
                                <InfoLabelBegin> Presale Starts in </InfoLabelBegin>
                                <InfoLabelEnd>
                                    {formatValue(presaleStartsIn) != '--.--' ? presaleStartsIn + " days" : '--.--'}
                                </InfoLabelEnd>
                            </ContainedInfoBox>
                            <ContainedInfoBox>
                                <InfoLabelBegin> Presale Length </InfoLabelBegin>
                                <InfoLabelEnd>
                                    {formatValue(presaleLen) != '--.--' ? presaleLen + " days" : '--.--'}
                                </InfoLabelEnd>
                            </ContainedInfoBox>
                        </Box>
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <Box display='flex' flexDirection='column' gap={1}>
                        <ContainedInfoBox>
                            <InfoLabelBegin> Price at Launch(ftm) </InfoLabelBegin>
                            <InfoLabelEnd> {formatValue(launchPrice)} </InfoLabelEnd>
                        </ContainedInfoBox>
                        <ContainedInfoBox>
                            <InfoLabelBegin> MCap at launch (ftm): </InfoLabelBegin>
                            <InfoLabelEnd> {totalSupply ? formatValue((Number(totalSupply) * Number(launchPrice)).toFixed(4)) : 0} </InfoLabelEnd>
                        </ContainedInfoBox>
                        <ContainedInfoBox>
                            <InfoLabelBegin sx={{minWidth: '170px'}}> Liquidity at launch (ftm):</InfoLabelBegin>
                            <InfoLabelEnd> {formatValue(liquidityAtLaunch)} </InfoLabelEnd>
                        </ContainedInfoBox>
                        <ContainedInfoBox>
                            <InfoLabelBegin> Liquidity Lock </InfoLabelBegin>
                            <InfoLabelEnd> {getLockDuration(presaleInfo)} </InfoLabelEnd>
                        </ContainedInfoBox>
                        <ContainedInfoBox>
                            <InfoLabelBegin> Chosen AMM </InfoLabelBegin>
                            <InfoLabelEnd> {ammName} </InfoLabelEnd>
                        </ContainedInfoBox>
                        </Box>
                    </Grid>
                </Grid>



            <DialogActions>

                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width:'100%', border: '0px solid red'}}>

                    <ContainedButton
                        disabled={false}
                        onClick={handleDeploy}
                        sx={{width: '200px'}}
                    >
                        CREATE PRESALE
                    </ContainedButton>
                </Box>
            </DialogActions>
        </ModalDialog>
    );
};

export default DeployPresaleModal;