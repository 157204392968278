import Web3 from 'web3';
import { HttpProviderOptions } from 'web3-core-helpers';


import getRpcUrl from 'utils/getRpcUrl';
import { getItem } from 'utils/localstorage';

let chainId = getItem('selectedChainId');


const RPC_URL_BASE = getRpcUrl(Number("250"));

const httpBASEProvider = new Web3.providers.HttpProvider(RPC_URL_BASE, { timeout: 10000 } as HttpProviderOptions);

const web3NoAccountBASE = new Web3(httpBASEProvider);

const activeChainId = Number(process.env.REACT_APP_CHAIN_ID || '250');
const mainNetChainId = 250;

/**
 * Provides a web3 instance using our own private provider httpProver
 */
const getWeb3 = (): Web3 => {
  chainId = getItem('selectedChainId');
  const RPC_URL2 = getRpcUrl(Number(chainId));
  const httpProvider2 = new Web3.providers.HttpProvider(RPC_URL2, { timeout: 10000 } as HttpProviderOptions);

  const web3 = new Web3(httpProvider2);
  return web3;
};


const getWeb3NoAccount = (): Web3 => {
  chainId = getItem('selectedChainId');
  const RPC_URL2 = getRpcUrl(Number(chainId));

  const httpProvider2 = new Web3.providers.HttpProvider(RPC_URL2, { timeout: 10000 } as HttpProviderOptions);

  const web3 = new Web3(httpProvider2);
  return web3;
};

// const getWeb3NoAccount = (): Web3 => web3NoAccount;
const getWeb3NoAccountBASE = (): Web3 => web3NoAccountBASE;

const getProviderBASE = (): any => httpBASEProvider;

export { getWeb3,  getWeb3NoAccount,getWeb3NoAccountBASE,getProviderBASE, activeChainId, mainNetChainId,};
