import {Box, Button, styled, Typography} from "@mui/material";
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import React, {FC, useState} from "react";



const Container = styled(Box)<{isExpanded: boolean}>(({ theme, isExpanded }) => ({
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: isExpanded
        ? 'linear-gradient(90deg, rgba(27, 46, 92, 1))'
        : 'linear-gradient(#1b2e5c)',
    overflow: 'hidden',
    borderRadius: '16px',
    boxShadow: isExpanded
        ? '0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)'
        : 'none',
    [theme.breakpoints.up('xs')]: {
        padding: '20px 32px',
        maxWidth: '320px',
    },
    [theme.breakpoints.up('md')]: {
        padding: '20px 100px',
        minWidth: '200px',
        maxWidth: '700px',
    },
    transition: 'background 0.5s ease',
    cursor: 'pointer',

    '::before': {
        content: '""',
        position: 'absolute',
        top: '-50px',
        left: '-50px',
        width: '100px',
        height: '100px',
        background: 'radial-gradient(circle, rgba(23,121,255,1) 0%, rgba(26,45,91,0) 70%)',
        borderRadius: '50%',
        animation: 'pulse 2s infinite ease-in-out',
    },

    '::after': {
        content: '""',
        position: 'absolute',
        top: '-50px',
        right: '-50px',
        width: '100px',
        height: '100px',
        background: 'radial-gradient(circle, rgba(23,121,255,1) 0%, rgba(26,45,91,0) 70%)',
        borderRadius: '50%',
        animation: 'pulse 2s infinite ease-in-out',
        animationDelay: '0.5s',
    },

    '@keyframes pulse': {
        '0%': {
            opacity: 0.5,
            transform: 'scale(0.8)',
        },
        '50%': {
            opacity: 1,
            transform: 'scale(1)',
        },
        '100%': {
            opacity: 0.5,
            transform: 'scale(0.8)',
        },
    },
}));


const BottomLeftCircle = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: '-50px',
    left: '-50px',
    width: '100px',
    height: '100px',
    background: 'radial-gradient(circle, rgba(23,121,255,1) 0%, rgba(26,45,91,0) 70%)',
    borderRadius: '50%',
    animation: 'chaoticPulseLeft 5s infinite ease-in-out',
    animationDelay: '1s',
    '@keyframes chaoticPulseLeft': {
        '0%': {
            transform: 'scale(1) translate(0, 0)',
        },
        '25%': {
            transform: 'scale(1.2) translate(-10px, -5px)',
        },
        '50%': {
            transform: 'scale(0.8) translate(5px, 10px)',
        },
        '75%': {
            transform: 'scale(1.1) translate(-7px, 7px)',
        },
        '100%': {
            transform: 'scale(1) translate(0, 0)',
        },
    },
}));

const BottomRightCircle = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: '-50px',
    right: '-50px',
    width: '100px',
    height: '100px',
    background: 'radial-gradient(circle, rgba(23,121,255,1) 0%, rgba(26,45,91,0) 70%)',
    borderRadius: '50%',
    animation: 'chaoticPulseRight 5s infinite ease-in-out',
    animationDelay: '1.5s',
    '@keyframes chaoticPulseRight': {
        '0%': {
            transform: 'scale(1) translate(0, 0)',
        },
        '25%': {
            transform: 'scale(1.2) translate(10px, -10px)',
        },
        '50%': {
            transform: 'scale(0.8) translate(-10px, 5px)',
        },
        '75%': {
            transform: 'scale(1.1) translate(5px, -7px)',
        },
        '100%': {
            transform: 'scale(1) translate(0, 0)',
        },
    },
}));

const TopBorder = styled(Box)(({ theme }) => ({
    position: 'absolute',
    width: '100%',
    height: '2px',
    top: 0,
    left: 0,
    background:' linear-gradient(to right, rgba(26, 45, 91, 0), rgba(23, 121, 255, 1))',
    animation: 'animate1 2s linear infinite',

    '@keyframes animate1': {
        '0%': {
            transform: 'translateX(-100%)',
        },
        '100%': {
            transform: 'translateX(100%)',
        },
    },
}));


const RightBorder = styled(Box)(({ theme }) => ({
    position: 'absolute',
    width: '2px',
    height: '100%',
    top: 0,
    right: 0,
    background: 'linear-gradient(to bottom, rgba(26, 45, 91, 0), rgba(23, 121, 255, 1))',
    animation: 'animate2 2s linear infinite',
    animationDelay: '1s',

    '@keyframes animate2': {
        '0%': {
            transform: 'translateY(-100%)',
        },
        '100%': {
            transform: 'translateY(100%)',
        },
    },
}));


const BottomBorder = styled(Box)(({ theme }) => ({
    position: 'absolute',
    width: '100%',
    height: '2px',
    bottom: 0,
    left: 0,
    background: 'linear-gradient(to left, rgba(26, 45, 91, 0), rgba(23, 121, 255, 1))',
    animation: 'animate3 2s linear infinite',

    '@keyframes animate3': {
        '0%': {
            transform: 'translateX(100%)',
        },
        '100%': {
            transform: 'translateX(-100%)',
        },
    },
}));


const LeftBorder = styled(Box)(({ theme }) => ({
    position: 'absolute',
    width: '2px',
    height: '100%',
    top: 0,
    left: 0,
    background: 'linear-gradient(to top, rgba(26, 45, 91, 0), rgba(23, 121, 255, 1))',
    animation: 'animate4 2s linear infinite',
    animationDelay: '1s',

    '@keyframes animate4': {
        '0%': {
            transform: 'translateY(100%)',
        },
        '100%': {
            transform: 'translateY(-100%)',
        },
    },
}));


const GlobalInfoCardLabel = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    userSelect: 'none',
    fontStyle: 'normal',
    fontWeight: '600',
    width: '100%',
    cursor: 'pointer',
    textWrap: 'wrap',
    textAlign: 'left',
    color: 'white',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
        fontSize: '12px',
        lineHeight: '18px',
        minWidth: '200px',
        img: {
            display: 'none',
        },
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '30px',
        lineHeight: '30px',
        img: {
            display: 'block',
        },
    },
}));


interface DropDownProps {
    children: React.ReactNode;
    isExpanded?: boolean;
    setIsExpanded?: (expanded: boolean) => void;
    globalTittle?: string;
    expandedTittle?: string;
    containerSX?:any;
}

const GlobalInfoExpandedCardLabel = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '500',
    width: '100%',
    cursor: 'pointer',
    textWrap: 'wrap',
    textAlign: 'center',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
        fontSize: '12px',
        lineHeight: '18px',
        img: {
            display: 'none',
        },
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '25px',
        lineHeight: '25px',
        img: {
            display: 'block',
        },
    },
}));

const FancyDropDownBox: FC<DropDownProps> = ({children, globalTittle,expandedTittle, containerSX = {}, isExpanded: parentIsExpanded,
                                                 setIsExpanded: parentSetIsExpanded}) => {
    const [localIsExpanded, setLocalIsExpanded] = useState<boolean>(false);
    const isExpanded = parentIsExpanded !== undefined ? parentIsExpanded : localIsExpanded;
    const setExpanded = parentSetIsExpanded || setLocalIsExpanded;

    return (
        <>
            <Container sx={{position: 'relative', ...containerSX}} justifyContent='center' alignItems='center' textAlign='center' isExpanded={isExpanded}>
                <TopBorder/>
                <RightBorder/>
                <LeftBorder/>
                <BottomBorder/>
                <BottomLeftCircle/>
                <BottomRightCircle/>
                <Box>
                    <GlobalInfoCardLabel>
                        {globalTittle}
                    </GlobalInfoCardLabel>

                </Box>
                <Box sx={{position: 'absolute', top: 10, right: 10, zIndex: '1'}}>
                    <Button onClick={() => {setExpanded(!isExpanded)}}>
                        <ArrowDropDownCircleIcon sx={{transform: isExpanded  ? "rotate(180deg)" : "", width: '35px', height: '35px'}}/>
                    </Button>
                </Box>
                <Box onClick={() => {setExpanded(!isExpanded)}}
                     sx={{border: '0px solid red', position: 'absolute', width: '100%', height: '68px', top: 0, cursor: 'pointer'}}>
                </Box>
                {isExpanded &&
                    <>
                        {expandedTittle &&
                            <Box>
                                <GlobalInfoExpandedCardLabel mt={2}>
                                    {expandedTittle}
                                </GlobalInfoExpandedCardLabel>
                            </Box>
                        }
                        {children}
                    </>
                }
            </Container>
        </>
    );
}

export default FancyDropDownBox;
