import { FC, useEffect, useRef, useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useWeb3React } from '@web3-react/core';
import { useFtmPrice } from 'hooks/useFTMPrice';
import {  getRouters, } from 'utils/deployTokens';
import {  fetchStakerInfoAsync,  } from 'state/actions';
import { useGetTokensLogoPiniata2 } from 'hooks/useGetDeployedTokenImage';
import StakerPool from './StakerPool';
import useCountDownUp from 'hooks/useCountDownUp';
import LogoBox from "../common/LogoContainer/LogoBox";
import ContainedInfoBox from "../common/InfoBox/ContainedInfoBox";
import RouterLogo from "../common/LogoContainer/RouterLogo";
import {InfoLabelBegin, InfoLabelEnd, InfoLabelText} from "../common/Typography/InfoLabel";

const Container = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    marginTop: '30px',
    background: "rgba(42,49,57, 0.4)",

    display: 'flex',
    maxWidth: '900px',
    borderRadius: '10px',
    flexDirection: 'column',
    minHeight: '700px',
    minWidth: '300px',

    [theme.breakpoints.up('xs')]: {
      padding: '0px 16px',
      minWidth: '280px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '20px 32px',
    },
  }));


const PoolName = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '700',
  cursor: 'pointer',
  fontSize: '25px',
  lineHeight: '24px',
  maxWidth: '200px',
  whiteSpace: 'break-spaces',
  textAlign: 'left',

  [theme.breakpoints.up('xs')]: {
    display: 'block',
    whiteSpace: 'nowrap',
  },
  [theme.breakpoints.up('sm')]: {
    whiteSpace: 'break-spaces',
  },
  [theme.breakpoints.up('md')]: {
    display: 'block',
    whiteSpace: 'break-spaces',
  },
}));

const PoolSymbol = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '500',
    cursor: 'pointer',
    fontSize: '20px',
    lineHeight: '24px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      whiteSpace: 'break-spaces',
    },
  }));



const PoolInfoLabelHeader = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '300',
  fontSize: '14px',
  lineHeight: '16px',
  maxWidth: '200px',
  whiteSpace: 'break-spaces',
  textAlign: 'left',
  width: '100%',
  marginLeft: '10px',
  [theme.breakpoints.up('xs')]: {
    maxWidth: '200px',
    display: 'block',
    textAlign: 'left',
    whiteSpace: 'break-spaces',
  },
  [theme.breakpoints.up('sm')]: {
    whiteSpace: 'break-spaces',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '400px',
    display: 'block',
    textAlign: 'left',
    whiteSpace: 'break-spaces',
  },
}));
const PoolInfoLabelEndHeader = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '300',
  fontSize: '14px',
  lineHeight: '16px',
  maxWidth: '200px',
  marginRight: '10px',
  whiteSpace: 'break-spaces',
  textAlign: 'right',
  width: '100%',

  [theme.breakpoints.up('xs')]: {
    display: 'block',
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  [theme.breakpoints.up('sm')]: {
    whiteSpace: 'break-spaces',
  },
  [theme.breakpoints.up('md')]: {
    display: 'block',
    textAlign: 'right',
    whiteSpace: 'break-spaces',
  },
}));


// modal body
const ModalBody = styled(Box)(() => ({
  marginTop: '23px',
  marginBottom: '21px',
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  gap: '20px'
}));

interface Props {
    index?: number,
    stakerInfo?: any,
    isSepparatePage: boolean,
    onClose?: () => void
}

const StakerPage: FC<Props> = ({ index, isSepparatePage,stakerInfo, onClose }) => {
  const { selectedChainId, isWalletChecked } = useAppSelector((state) => state.chain);
  const { pendingTxHash } = useAppSelector((state) => state.modal);
  const pendingTxHashRef: { current: string | undefined } = useRef();
  pendingTxHashRef.current = pendingTxHash;
  const ftmPrice = useFtmPrice(Number(selectedChainId));
  const [tokenPrice, setTokenPrice] = useState<string>("--");
  const [amm, setAmm] = useState<string>("--");
  const [tokenImage, setTokenImage] = useState<string>("");
  const [lockedLps, setLockedLps] = useState<Number>(0);
  const [info, setInfo] = useState<any>({});
  const [stakers, setStakers] = useState<any>([]);
  const [stakerPools, setStakerPools] = useState<any>([]);
  const [currentStakerInfo, setCurrentStakerInfo] = useState<any>({});
  const [isPoolsStarted, setIsPoolsStarted] = useState<boolean>(false);
  const [isPoolsEnded, setIsPoolsEnded] = useState<boolean>(false);
  const [isShowTime, setIsShowTime] = useState<boolean>(false);
  const [poolStartTime, setPoolStartTime] = useState<Number>(0);
  const [poolEndTime, setPoolEndTime] = useState<Number>(0);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [days, hours, minutes, seconds] = useCountDownUp(isShowTime ? Number(isPoolsStarted ? poolEndTime : poolStartTime) * 1000 : new Date().getUTCSeconds() * 1000);
  const dispatch = useAppDispatch();
  const { account } = useWeb3React();
  const { deployedTokens, size, stakersInfo, stakersPools } = useAppSelector((deployedTokens) => deployedTokens.deployedTokens);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const images = useGetTokensLogoPiniata2(0)
  useEffect(() => {
    const a = document.createElement('a');
    const url = new URL(window.location.href);
    const res = url.pathname.split('/');
    if( res.length > 4 ){
        if( deployedTokens[Number(res[3])] ){
            setInfo(deployedTokens[Number(res[3])])
            setIsLoading(false)
        }
    } else if (res.length > 3){

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  useEffect(() => {
    if( index != undefined && stakersInfo ){
            setCurrentStakerInfo(stakersInfo[index])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, stakersInfo]);

  useEffect(() => {
    if( index != undefined && stakerInfo ){
            setInfo(stakerInfo)
            setIsLoading(false)
        
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, stakerInfo]);

  const onCloseModal = () => {
    setIsExpanded(false)
  };

  useEffect(() => {
    if( images && info ){
        setTokenImage(images[info?.info?.tokenAddress]?.img)
    }
}, [images, info]);

useEffect(() => {
  if( stakerPools && stakerPools?.length > 0 ){
    const currentDate = Math.floor((new Date()).getTime() / 1000)
    setPoolStartTime(stakerPools[0].poolStartTime )
    setPoolEndTime(stakerPools[0].poolEndTime )
    if( currentDate > stakerPools[0].poolStartTime ){
      setIsPoolsStarted(true)
    } 
    else {
      setIsPoolsStarted(false)
      setIsShowTime(true)
      return;
    }
    if( currentDate > stakerPools[0].poolEndTime ){
      setIsPoolsEnded(true)
    } 
    else {
      setIsPoolsEnded(false)
    }
    setIsShowTime(true)

  }
}, [stakerPools]);

useEffect(() => {
    const pools = []
    if( stakersPools && info ){
        try{
            if( stakersPools[info.info.tokenAddress]) {
                for (const pool  of Object.values(stakersPools[info.info.tokenAddress])) {
                    pools.push(pool)
                    // break
                }
                setStakerPools(pools)
            }
        }
        catch(err){
            console.log(err)
        }
    }
}, [stakersPools]);

useEffect(() => {
if( ftmPrice && info ){
    setTokenPrice((info?.tokenPriceFTM * ftmPrice).toFixed(info?.tokenPriceFTM < 0.001 ? 6 : 3))
}
}, [ftmPrice, info]);


useEffect(() => {
    if( info ) {
        dispatch(fetchStakerInfoAsync(Number(selectedChainId), account ? account : "", ftmPrice, info));
    }
}, [info, selectedChainId]);

useEffect(() => {
if( info ){ 
  const routers = getRouters(Number(selectedChainId))
  setLockedLps(Number(info?.lockLps))
  for( let i = 0; i < routers.length; i+=1 )
  {
    if( routers[i].address === info?.info?.router ){
        setAmm(routers[i].name)
        return
    }
  }
}
}, [info, selectedChainId]);



  return (
    <> <Container>
        {/* body */}
        <ModalBody sx={{position: 'relative'}}>
          { info && info?.currentRouterLogo?.length > 0 &&
              <RouterLogo imageUrl={info?.currentRouterLogo} sx={{right: {xs:10, md: 10}, top: 0 }}/>
          }
            { !isSepparatePage && 
                <>
                    <Box onClick={onClose} display='flex'  position='absolute' sx={{top: {xs: -50, md: -50}, left: {xs: -0, md: -50},
                        border: '1px solid #5E6980', borderRadius: '15px', padding: '10px', background: '#2A3139', cursor: 'pointer'}}>
                            <ArrowBackIosNewIcon sx={{width: '20px', height: '20px'}}/>
                    </Box>
                </>
            }
            <Box gap='20px' display='flex' alignItems='center' sx={{border: '0px solid red',width: '100%', flexDirection: {xs: 'column', md: 'row'}}}>
                        <Box display='flex' flexDirection='row'  alignItems='center' gap='10px' sx={{height: '100%', maxWidth: '200px', border: '0px solid red', position: 'relative'}}>
                            <LogoBox imageUrl={tokenImage} sx={{width: '150px', height: '190px',}}/>
                        </Box>
                        <Box display='flex' gap={'10px'} flexDirection='column'  justifyContent='center' alignItems={'center'} sx={{border: '0px solid blue', width: '100%', height: '100%'}}>
                            <PoolName >
                                {info?.info?.name}
                            </PoolName>
                            <PoolSymbol>
                                {info?.symbol}
                            </PoolSymbol>
                          <ContainedInfoBox  sx={{minHeight: '34px'}}>
                            <InfoLabelText>
                              Earn {info?.symbol} by staking assets below in their corresponding pools
                            </InfoLabelText>

                          </ContainedInfoBox>
                          <ContainedInfoBox  sx={{minHeight: '34px'}}>
                            <InfoLabelBegin>
                              Total Rewards distributed between pools
                            </InfoLabelBegin>
                            <InfoLabelEnd>
                              {currentStakerInfo?.poolRewards ?  Number(currentStakerInfo?.poolRewards).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ` ` + info?.symbol : '--.--'}
                            </InfoLabelEnd>
                          </ContainedInfoBox>
                          <ContainedInfoBox  sx={{minHeight: '34px',}}>

                            <PoolInfoLabelHeader>
                              Rewards {`${isPoolsStarted ? (isPoolsEnded ? 'ended' : 'ends in') : 'starts in'}`}
                            </PoolInfoLabelHeader>
                            <PoolInfoLabelEndHeader>
                              {isShowTime && !isPoolsEnded ? (
                                  <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
                                    {days > 0 && (
                                        <div style={{ margin: '0 5px', textAlign: 'center' }}>
                                          <div>{days <= 9 ? "0" + days : days} /</div>
                                          <div style={{ fontSize: '12px' }}>d</div>
                                        </div>
                                    )}
                                    <div style={{ textAlign: 'center' }}>
                                      <div>{hours <= 9 ? "0" + hours : hours}:</div>
                                      <div style={{ fontSize: '12px' }}>h</div>
                                    </div>
                                    <div style={{  textAlign: 'center' }}>
                                      <div>{minutes <= 9 ? "0" + minutes : minutes}:</div>
                                      <div style={{ fontSize: '12px' }}>m</div>
                                    </div>
                                    <div style={{  textAlign: 'center' }}>
                                      <div>{seconds <= 9 ? "0" + seconds : seconds}</div>
                                      <div style={{ fontSize: '12px' }}>s</div>
                                    </div>
                                  </div>
                              ) : ``}
                            </PoolInfoLabelEndHeader>
                          </ContainedInfoBox>

                    </Box>
            </Box>
        </ModalBody>
            <>
            <Box mt={2} gap='10px' display='flex' flexDirection='column'>
                 {stakerPools && stakerPools
                .map((row: any) => <StakerPool tokenInfo={info} poolInfo={row} />)}
            </Box>

            </>
                
        </Container>
    </>
  );
};

export default StakerPage;
