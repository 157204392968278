import { FC,  useState } from 'react';
import { Box, TextField, Typography, styled } from '@mui/material';
import {ContainedTextField} from "../../common/Input/ContainedTextField";

const InfoCardLabel = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    cursor: 'pointer',
    textWrap: 'wrap',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
      lineHeight: '18px',
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      lineHeight: '18px',
      textAlign: 'right',
    },
  }));
const ValueTitle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
        fontSize: '12px',
        lineHeight: '18px',
        img: {
            display: 'none',
        },
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '14px',
        lineHeight: '18px',
        img: {
            display: 'block',
        },
    },
}));

interface Props {
    isCheckErrors: boolean;
    hardCapS: string;
    totalSupply: string;
    isPresaleTokensError: boolean,
    isPresaleTokenPriceError: boolean,
    presaleSectionChanges: (tokensForPresale: string, presaleTokenPrice: string, softCap: string, hardCap: string, minBuy: string, maxBuy: string, presaleStartsIn: string,
         presaleLen: string
    ) => void
}

const PresaleSection: FC<Props> = ({ presaleSectionChanges, isCheckErrors, hardCapS, totalSupply, isPresaleTokensError, isPresaleTokenPriceError }) => {
    const [softCap, setSoftCap] = useState<string>('');
    const [minBuy, setMinBuy] = useState<string>('');
    const [presaleStartsIn, setPresaleStartsIn] = useState<string>('');
    const [maxBuy, setMaxBuy] = useState<string>('');
    const [presaleLen, setPresaleLen] = useState<string>('');
    const [tokensPresale, setTokensPresale] = useState<string>('');
    const [presaleTokenPrice, setPresaleTokenPrice] = useState<string>('');

    function isNumeric(n: any) {
        return !Number.isNaN(Number(n)) && Number.isFinite(Number(n));
      }

    const onChangeSoftCap = (e: any) => {
        if (!isNumeric(e.target.value)) return;
        const newAmount = e.target.value;
        if( Number(hardCapS) <= Number(newAmount) )
            return
        setSoftCap(newAmount);
        presaleSectionChanges(tokensPresale, presaleTokenPrice, newAmount, hardCapS, minBuy, maxBuy,  presaleStartsIn, presaleLen)
    };
      const onChangeMinBuy = (e: any) => {
        if (!isNumeric(e.target.value)) return;
        const newAmount = e.target.value;
        if( Number(maxBuy) <= Number(newAmount) )
            setMaxBuy(newAmount)
        setMinBuy(newAmount);
        presaleSectionChanges(tokensPresale, presaleTokenPrice, softCap, hardCapS, newAmount, maxBuy,  presaleStartsIn, presaleLen)
    };

    const onChangePresale = (e: any) => {
        if (!isNumeric(e.target.value)) return;
        const newAmount = e.target.value;
        if (Number(totalSupply) < Number(newAmount) ) return;
        setTokensPresale(newAmount);
        presaleSectionChanges(newAmount, presaleTokenPrice, softCap, hardCapS, minBuy, maxBuy,  presaleStartsIn, presaleLen)

    };

    const onChangePresalePrice = (e: any) => {
        if (!isNumeric(e.target.value)) return;
        const newAmount = e.target.value;
        setPresaleTokenPrice(newAmount);
        presaleSectionChanges(tokensPresale, newAmount, softCap, hardCapS, minBuy, maxBuy,  presaleStartsIn, presaleLen)

    };

    const onChangePresaleStartsIn = (e: any) => {
        if (!isNumeric(e.target.value) || e.target.value.includes(" ")) return;

        const newAmount = e.target.value.trim();
        setPresaleStartsIn(newAmount);
        presaleSectionChanges(tokensPresale, presaleTokenPrice, softCap, hardCapS, minBuy, maxBuy,  newAmount, presaleLen)

    };

    const onChangeMaxBuy = (e: any) => {
        if (!isNumeric(e.target.value)) return;
        const newAmount = e.target.value;
        setMaxBuy(newAmount);
        presaleSectionChanges(tokensPresale, presaleTokenPrice, softCap, hardCapS, minBuy, newAmount,  presaleStartsIn, presaleLen)
    };
    const onChangePresaleLen = (e: any) => {
        if (!isNumeric(e.target.value)) return;
        const newAmount = e.target.value;
        setPresaleLen(newAmount);
        presaleSectionChanges(tokensPresale, presaleTokenPrice, softCap, hardCapS, minBuy, maxBuy,  presaleStartsIn, newAmount)
    };
    const calculatePresaleTokensPercent = () => {
        return Number(tokensPresale) * 100 / Number(totalSupply)
    }
  return (
    <>
    <InfoCardLabel mt={2}>
            Presale Specs
    </InfoCardLabel>
    <Box mt={1} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>

        <Box display='flex' sx={{flexDirection: {xs: 'column', md: 'row'}}} >
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' sx={{border: '0px solid blue'}}>
                <Box mt={2} display='flex' sx={{
                        border: '0px solid grey',
                        padding: '10px',
                        width: '100%'
                        }}>
                        <ContainedTextField variant={'outlined'} disabled={totalSupply.length === 0} placeholder={`Tokens For Presale`} required={true}
                                   onChange={onChangePresale}
                                   autoComplete='off'
                                   error={
                                       totalSupply.length > 0
                                           ? (tokensPresale.length !== 0 && Number(totalSupply) <= Number(tokensPresale))
                                           : false
                                   }

                                   label={
                                       tokensPresale.length > 0
                                           ? (Number(totalSupply) <= Number(tokensPresale)
                                               ? 'Must Be Less Than Total Supply'
                                               : `Tokens For Presale ~${calculatePresaleTokensPercent().toFixed(2)}%`)
                                           : 'Tokens For Presale'
                                   }
                                   value={tokensPresale} />
                </Box>
                <Box display='flex' sx={{
                        border: '0px solid grey',
                        padding: '10px',
                        width: '100%'
                        }}>
                        <ContainedTextField variant={'outlined'}
                                            disabled={totalSupply.length === 0}
                                            placeholder='Presale Token Price ( ftm )'
                                            required={true}
                                            onChange={onChangePresalePrice}
                                            autoComplete='off'
                                            error={false}
                                            label={"Presale Token Price ( FTM )"}
                                            value={presaleTokenPrice} />
                </Box>
            </Box>
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' sx={{border: '0px solid blue', paddingTop: '24px', gap: '8px'}}>
                <Box display='flex' flexDirection='column' alignItems='center'>
                <Box  display='flex' flexDirection='column' alignItems='center' mb={'8px'} sx={{
                    border: '0px solid grey',
                    borderRadius: '10px',
                    padding: '10px',
                    background: "rgba(6, 1,1, 0.3)",
                    minHeight: '53.13px',
                    minWidth: '225px',
                    maxWidth: '225px',

                }}>
                    <ValueTitle> Hard Cap: </ValueTitle>
                    <ValueTitle> {hardCapS.length > 0 && Number(hardCapS) > 0 ? hardCapS + " FTM":"0 FTM"} </ValueTitle>
                </Box>
                <Box  display='flex' sx={{
                        border: '0px solid grey',
                        padding: '10px',
                        width: '100%'
                        }}>
                        <ContainedTextField variant={'outlined'} placeholder='Soft Cap (FTM)' required={true} disabled={hardCapS.length === 0} onChange={onChangeSoftCap}  autoComplete='off'
                        error={hardCapS.length > 0 ? softCap.length !== 0 && Number(hardCapS) < Number(softCap) : false}  label="Soft Cap (FTM)" value={softCap} />
                </Box>
                </Box>
            </Box>
        </Box>
        <Box display='flex' sx={{flexDirection: {xs: 'column', md: 'row'}}} >
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' sx={{border: '0px solid blue'}}>
                <Box mt={2} display='flex' sx={{
                        border: '0px solid grey',
                        padding: '10px',
                        width: '100%'
                        }}>
                    <ContainedTextField variant={'outlined'}  placeholder='Min Buy (FTM)' required={true} onChange={onChangeMinBuy}  autoComplete='off'
                                        error={ minBuy.length !== 0  ? Number(maxBuy) > 0  &&  Number(minBuy) > Number(maxBuy): false}  label="Min Buy (FTM)" value={minBuy} />
                </Box>
                <Box display='flex' sx={{
                        border: '0px solid grey',
                        padding: '10px',
                        width: '100%'
                        }}>
                         <ContainedTextField variant={'outlined'} placeholder='Max Buy (FTM)' required={true} onChange={onChangeMaxBuy}  autoComplete='off'
                                             error={false }  label="Max Buy (FTM)" value={maxBuy} />
                </Box>
            </Box>
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' sx={{border: '0px solid blue'}}>
                <Box mt={2} display='flex' sx={{
                        border: '0px solid grey',
                        padding: '10px',
                        width: '100%'
                        }}>
                    <ContainedTextField variant={'outlined'} placeholder='Presale Starts in' required={true} onChange={onChangePresaleStartsIn}  autoComplete='off'
                                        error={false}  label={"Presale Starts in (days)"} value={presaleStartsIn} />
                </Box>
                <Box mt={0} display='flex' sx={{
                        border: '0px solid grey',
                        padding: '10px',
                        width: '100%'
                        }}>
                    <ContainedTextField variant={'outlined'} placeholder='Presale length' required={true} onChange={onChangePresaleLen}  autoComplete='off'
                                        error={false}  label="Presale Length (days)" value={presaleLen} />
                </Box>
            </Box>
        </Box>
    </Box>
    </>
  );
};

export default PresaleSection;