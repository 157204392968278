import { FC,  useState } from 'react';
import { Button, Typography } from '@mui/material';
import ImageUploading, { ImageType } from 'react-images-uploading';
import {ContainedButton} from "../Button";

interface Props {
    imageUpload: (images: ImageType[]) => void
    hide: boolean,
}

const ImageUploadBox: FC<Props> = ({ imageUpload, hide }) => {

  const [images, setImages] = useState<ImageType[]>([]);
  const maxNumber = 1;

  const onChange = (imageList: any, addUpdateIndex: any) => {
    // data for submit
    setImages(imageList);
    imageUpload(imageList);
  };

  return (
    <>
   <div className="App">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        
      >
        {({
          imageList,
          onImageUpload,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div style={{marginTop: "10px", marginBottom: '10px', display: hide ? 'none' : ''}} className="upload__image-wrapper">
            <ContainedButton
              sx={{width: '220px', minHeight: '53px'}}
              style={isDragging ? { color: 'red'} : {border: images.length <= 0 ? '1px solid #4190F7' : '0px solid red'}}
              onClick={onImageUpload}
              {...dragProps}
            >
              <Typography style={{fontSize: '13px', color: "lightgray"}} color='primary' variant={'h5'} >{'Click or Drop here Your Logo'}
              </Typography>
            </ContainedButton>
            &nbsp;
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image['data_url']} alt="" width="100" style={{marginTop: '10px'}} />
                <div className="image-item__btn-wrapper" style={{justifyContent: 'center'}}>
                  <ContainedButton sx={{marginTop: '10px'}}
                    color="primary" 
                    variant="outlined"   
                    onClick={() => onImageRemove(index)}>
                        <Typography style={{fontSize: '13px', color: "lightgray"}} color='primary' variant={'h5'} >{'Remove'}
                        </Typography>
                    </ContainedButton>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </div>
    </>
  );
};

export default ImageUploadBox;
