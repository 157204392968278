import { FC } from 'react';
import { Box, styled, Typography,} from '@mui/material';


const AppFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '26px 32px',
  marginTop: '0px',
  height: '100px',
  background: 'transparent',

  [theme.breakpoints.up('xs')]: {

  },
  [theme.breakpoints.up('sm')]: {

  },
}));

const FooterContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center', //space-between Basic
  alignItems: 'center',
  gap: '16px',
  width: '100%',
  maxWidth: '851px',
  margin: 'auto',

  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
    gap: '25px',
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    gap: '16px',
  },
}));

// copyright section
const CopyRightSection = styled(Box)(() => ({}));

const CopyRightSectionText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '21px',
  color: 'rgba(255, 255, 255, 0.7)',

  [theme.breakpoints.up('xs')]: {
    fontSize: '11px',
    lineHeight: '17px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    lineHeight: '21px',
  },
}));



const Footer: FC = () => {
  return (
    <AppFooter>
      <FooterContent>
         <CopyRightSection>
            <CopyRightSectionText>{`Copyright © BasedLabs 2024`}</CopyRightSectionText>
         </CopyRightSection>
      </FooterContent>
    </AppFooter>
  );
};

export default Footer;
