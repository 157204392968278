import { FC, } from 'react';
import { Box,  styled,  } from '@mui/material';

import DeployPresale from 'components/DeployPresale/DeployPresale';
import ListedPresale from 'components/ListedPresale/ListedPresale';

// overview section
const OverviewSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  background: 'transparent',
  alignItems: 'center',
  height: '100%',
  justifyContent: 'center',
  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'column',
  },
}));



interface Props {
  isStaking: boolean
}

const PresalePage: FC<Props> = ({ isStaking }) => {

  return (
        <>
        <OverviewSection mt={5}>
          {isStaking &&
            <Box mt={2} flexDirection={'column'} display='flex' justifyContent='center' alignItems='center' textAlign='center' sx={{}}>
              <DeployPresale/>
            </Box>
          }

            <Box mt={2} flexDirection={'column'} display='flex' justifyContent='center' alignItems='center' textAlign='center' sx={{width: '100%'}}>
              <ListedPresale isStaking={isStaking}/>
            </Box>
        </OverviewSection>
        </>
  );
};

export default PresalePage;
